//  _____ _ _ _ ____  _____ 
// |   | | | | |    \|   __|
// | | | | | | |  |  |__   |
// |_|___|_____|____/|_____|
//
// NATIONWIDE DESIGN SYSTEM
// --------------------------------------------------
// Angular UI Bootstrap Datepicker Component
//
// 

.uib-daypicker { 
	background: #fff; 
}

.uib-weeks .btn-default { 
	background:none; border:none; box-shadow: none; border-radius:0; 
}

.uib-weeks .btn-default.active { 
	background:#6aa2b7; 
}

.uib-weeks .btn-default.active .text-info { 
	color:#fff; 
}