
.claims-button-container-wrap {
    padding: 0 50px;
    background: $blue-light-2;

    @media (max-width: $small) {
        padding: 0 20px;
    }

    @media (max-width: 26.25em) { // 420px
        padding: 0 10px;
    }

    .button-container {
        max-width: 900px;
        margin: 0 auto;
        padding: 20px 0;
        background: $blue-light-2;
        border-top: 1px solid #f1f7fb;
    }
}
