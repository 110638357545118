//  _____ _ _ _ ____  _____ 
// |   | | | | |    \|   __|
// | | | | | | |  |  |__   |
// |_|___|_____|____/|_____|
//
// NATIONWIDE DESIGN SYSTEM
// --------------------------------------------------
// Time Picker Pattern
//
.nw-timepicker { max-width:300px; }
.nw-timepicker .row > div { padding-right:0; }