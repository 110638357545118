//  _____ _ _ _ ____  _____
// |   | | | | |    \|   __|
// | | | | | | |  |  |__   |
// |_|___|_____|____/|_____|
//
// NATIONWIDE DESIGN SYSTEM
// --------------------------------------------------
// Nationwide Navs
//



// Centered tabs
.nw-tabs--center {

    .nav-tabs { text-align:center; }

    .nav > li {
        display: inline-block;
        float: none;
    }

}
.nw-header__skip {
    position: absolute;
    top: -100px;
    overflow: hidden;
    opacity: 0;
    left: 50%;
    margin-left: -100px;
    width: 200px;
    z-index: 15000;
    text-align: center;
    background-color: bolt-background-white;
    border: 1px solid bolt-background-mint;
    padding: 5px 0;
    -webkit-transform: translate3d(0,-10px,0);
    transform: translate3d(0,-10px,0);
    opacity: 0;
    -webkit-transition: opacity .2s ease-out,top 0s .2s,-webkit-transform .2s ease-out;
    transition: opacity .2s ease-out,top 0s .2s,-webkit-transform .2s ease-out;
    transition: transform .2s ease-out,opacity .2s ease-out,top 0s .2s;
    transition: transform .2s ease-out,opacity .2s ease-out,top 0s .2s,-webkit-transform .2s ease-out
}

.nw-header__skip:focus {
    top: 10px;
    -webkit-transform: translate3d(0,0,0);
    transform: translate3d(0,0,0);
    opacity: 1;
    -webkit-transition: opacity .2s ease-out,-webkit-transform .2s ease-out;
    transition: opacity .2s ease-out,-webkit-transform .2s ease-out;
    transition: transform .2s ease-out,opacity .2s ease-out;
    transition: transform .2s ease-out,opacity .2s ease-out,-webkit-transform .2s ease-out
}
