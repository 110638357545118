
// Track Claims Landing Page Styles
.claims-landing-page__panel {
    float: left;
    width: 49%;
    padding: 20px 10px 0;
    background: rgb(240,240,240);
    border: 1px solid #c9cac8 ;
    border-bottom: 3px solid #c9cac8;
    margin-top: 32px;

    @media (max-width: $small) {
        float: none;
        width: 100%;
    &.right {
        float: none;
    }
    &:last-of-type {
        margin-top: 5px;
    }
}

    &.right {
        float: right;
    }
    h3 {
        position: relative;
        min-height: 44px;
        font-family: 'proxima-nova', Arial, sans-serif;
        font-size: 1.19em;
        font-weight: bold;
        text-transform: uppercase;
        color: rgb(47,71,0);
        padding-left: 53px;

        @media (max-width: $small) {
            margin-bottom: 0;
            padding-left: 42px;
    }

        &:before {
            position: absolute;
            left: 0;
            height: 46px;
            width: 46px;
            line-height: 38px;
            background: rgb(122,184,0);
            border-radius: 46px;
            border: 3px solid #aec77e;
            font-family: 'icomoon';
            font-size: 1.7rem;
            font-weight: normal;
            text-align: center;
            color: rgb(255,255,255);

            @media (max-width: $small) {
                font-size: 1.2rem;
                height: 35px;
                width: 35px;
                line-height: 27px;
            }
        }
    }
    p {
        font-size: 1.125em;
    }
}
