.nav-header{
  outline: none;
}

/* MOBILE NAVIGATION */
.mobile-navigation {
  display: none;
  font-family: 'proxima-nova', Arial, sans-serif;
  font-size: 1.125em;
  font-weight: 500;
  position: absolute;
  top: 0;
  left: -265px;
  width: 265px;
  height: 100%;
  background: #D9D9D9;
  z-index: 999;
  -webkit-backface-visibility: hidden;
  transition: .2s all ease;
  -webkit-transition: .2s all ease;

}

.mobile-navigation .nw-no-underline,
.mobile-navigation .nw-no-underline:hover,
.mobile-navigation .nw-no-underline:focus{
  text-decoration: none;
  display: inline-block;
  margin-right: 5px;
}

.mobile-navigation ul, .secondary-navigation li {
  margin: 0;
  padding: 0;
}

.mobile-navigation__header {
  display: block;
  text-transform: uppercase;
  font-size: .8em;
  padding: 5px 10px;
  margin-bottom: 0;
  font-weight: 700;
  background: #b2b2b2;
  color: #333;
}

.mobile-navigation__primary {
  background-color: #FFF;
}

.mobile-navigation__primary > li {
  border-top-width: 1px;
  border-top-style: solid;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-top-color: #FFF;
  border-bottom-color: #b6b6b6;
}

.mobile-navigation__primary li:first-child { /*border-top:none;*/
}

.mobile-navigation__primary li:last-child {
  border-bottom: none;
}

.mobile-navigation__primary li a {
  display: block;
  padding: 10px 5px 10px 10px;
}

.mobile-navigation__primary li a:hover, .secondary-navigation__primary li a:active, .secondary-navigation__primary li a:focus {
  background-color: #ececec;
}

.mobile-navigation__primary li.active > a {
  display: block;
  padding: 10px 5px 10px 10px;
  font-weight: bold;
  color: #333333;
  background-color: #DAE8ED;
}

.mobile-navigation__primary li.select > a {
  display: block;
  padding: 10px 5px 10px 10px;
  font-weight: bold;
  color: #333333;
  background-color: #DAE8ED;
}

.mobile-navigation__primary li ul {
  background: #faf7f7;
}

.mobile-navigation__primary li li {
  border-bottom-width: 1px solid #dbdbdb;
}

.mobile-navigation__primary li li:first-child {
  border-top-width: 1px;
  border-top-style: solid; /*border-bottom-width:1px; border-bottom-style: solid;*/
  border-top-color: #a3a3a3; /*border-bottom-color:#b6b6b6;*/
}

.mobile-navigation__primary li li:last-child { /*border-bottom:none;*/
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: #a3a3a3;
}

.mobile-navigation__primary li li a {
  font-size: .95em;
  font-weight: normal;
  padding: 10px 10px 10px 25px;
  color: #333333;
}

.mobile-navigation__primary li li.active a {
  background: #d5e3ef;
  font-weight: bold;
  padding-left: 25px;
  color: #333333;
}

.mobile-navigation__primary li li.select a {
  font-weight: bold;
  padding-left: 25px;
  color: #333333;
  background-color: #d5e3ef;
}

.mobile-navigation__contact {
  padding: 10px 5px 10px 20px;
  color: #333; /*border-bottom:1px solid #bdbdbd; background-color: #dfdfdf;*/
}

.mobile-navigation__contact a {
  text-decoration: none;
  color: #005691;
}

.mobile-navigation__contact li {
  line-height: 1.6em;
}

.mobile-navigation-home {
  display: block;
  text-align: left;
  padding: 10px;
  //padding: 10px 10px 10px 30px;
  min-height: 41px;
  //background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA0AAAAQCAYAAADNo/U5AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSBNYWNpbnRvc2giIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6RDBDM0I3MzAyNzkzMTFFM0E0NDBCNjk1N0IyRTBGRjAiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6RDBDM0I3MzEyNzkzMTFFM0E0NDBCNjk1N0IyRTBGRjAiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDpEMEMzQjcyRTI3OTMxMUUzQTQ0MEI2OTU3QjJFMEZGMCIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDpEMEMzQjcyRjI3OTMxMUUzQTQ0MEI2OTU3QjJFMEZGMCIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PpuvMQgAAACrSURBVHjaYvz//z8DOmAMn8QJpMKBeP3/lXkf0eWZsGhgAVLlQDwfiLuBfC68moAKQPwaIK6HCsUCcRFQXARFHcx5QAlGINUAxHVoBv+B2poLdOpPuE1IGmoYMAHIuQlAXABUxwtWzxA2kRmquBqIWRlwg29AvBCIa0GmVEE1sTDgB1xQG5+AbPrPQBr4w8RAOmAhRxMDWZowPA+MC0YsqeQ/xTYNck0AAQYAjNgqLGin3ncAAAAASUVORK5CYII=) 10px 10px no-repeat #FFF;
  background: #FFF;
}


.mobile-navigation-home:focus, .mobile-navigation-home:hover {
  background-color: #ececec;
}

.mobile-navigation-home:active {
  background-color: #838383;
}

/* NAVIGATION SHOW/HIDE */
.js .show-nav .outer-container { /*-webkit-transform: translate(265px, 0); transform: translate(265px, 0);*/
  margin-left: 265px;
}

.js .show-nav .mobile-navigation { /*-webkit-transform: translate(265px, 0); transform: translate(265px, 0);*/
  left: 0;
}

.js .show-nav .mobile-nav-button {
  position: relative;
  z-index: 1000;
}

//.js.csstransforms3d .show-nav .outer-container { -webkit-transform: translate3d(265px, 0, 0); transform: translate3d(265px, 0, 0); }
//.js.csstransforms3d .show-nav .mobile-navigation { -webkit-transform: translate3d(265px, 0, 0); transform: translate3d(265px, 0, 0); }

/* LEFT NAVIGATION */
.secondary-navigation {
  font-family: 'Gotham Book', Arial, sans-serif;
  position: absolute;
  top: -1px;
  left: -265px;
  overflow: hidden;
  width: 265px;
  height: 100%;
  background: #f0f0f0;
  border-top: 1px solid #B6B6B6;
  -webkit-backface-visibility: hidden;
  transition: .3s all ease;
  -webkit-transition: .3s all ease;
}

.secondary-navigation ul, .secondary-navigation li {
  margin: 0;
  padding: 0;
}

.secondary-navigation__header {
  display: block;
  text-transform: uppercase;
  font-size: .8em;
  padding: 5px 10px;
  margin-bottom: 0;
  font-weight: 700;
  background: #ececec;
  border-top: 1px solid #a3a3a3;
  color: #333;
}

.secondary-navigation__primary {
  background-color: #FFF;
}

.secondary-navigation__primary > li {
  border-top: 1px solid #dbdbdb;
}

.secondary-navigation__primary li a {
  display: block;
  padding: 10px 5px 10px 20px;
}

.secondary-navigation__primary li a:hover, .secondary-navigation__primary li a:active, .secondary-navigation__primary li a:focus {
  background-color: #ececec;
}

.secondary-navigation__primary li.active > a {
  display: block;
  padding: 10px 5px 10px 20px;
  font-weight: bold;
  color: #333333;
  background-color: #DAE8ED;
}

.secondary-navigation__primary li.select > a {
  display: block;
  padding: 10px 5px 10px 20px;
  font-weight: bold;
  color: #333333;
  background-color: #DAE8ED;
}

.secondary-navigation__primary li li {
  border-top: 1px solid #dbdbdb;
}

.secondary-navigation__primary li li a {
  font-size: .95em;
  font-weight: normal;
  padding: 10px 10px 10px 40px;
}

.secondary-navigation__primary li li.active a {
  background: #DAE8ED;
  font-weight: bold;
  padding-left: 40px;
  color: #333333;
}

.secondary-navigation__primary li li.select a {
  background: #DAE8ED;
  font-weight: bold;
  padding-left: 40px;
  color: #333333;
}

.secondary-navigation__secondary {
  background-color: #FFF;
}

.secondary-navigation__secondary > li {
  border-top: 1px solid #dbdbdb;
}

.secondary-navigation__secondary li a {
  display: block;
  padding: 10px 5px 10px 25px;
}

.secondary-navigation__secondary li a:hover, .secondary-navigation__primary li a:active, .secondary-navigation__primary li a:focus {
  background-color: #ececec;
}

.secondary-navigation__secondary li.active > a {
  display: block;
  padding: 10px 5px 10px 10px;
  font-weight: bold;
  color: #333333;
  background-color: #DAE8ED;
}

.secondary-navigation__secondary li.select > a {
  display: block;
  padding: 10px 5px 10px 10px;
  font-weight: bold;
  color: #333333;
  background-color: #DAE8ED;
}

.secondary-navigation .mobile-navigation-home {
  font-weight: normal;
}

ul.secondary-navigation__contact {
  margin-top: 10px;
}

ul.secondary-navigation__contact li {
  padding: 5px 5px 5px 20px;
}

/* NAVIGATION SHOW/HIDE */
.js .show-nav .outer-container { /*-webkit-transform: translate(265px, 0); transform: translate(265px, 0);*/
  margin-left: 265px;
}

.js .show-nav .secondary-navigation { /*-webkit-transform: translate(265px, 0); transform: translate(265px, 0);*/
  left: 0;
}

.js .show-nav .overlay {
  @include opacity (.70);
  z-index: 998;
}

@media screen and (max-width: 62.1875em) { /* 995px */

  .secondary-navigation {
    z-index: 999;
  }
  .nav-menu-container  {
    display: block;
  }
  .mobile-navigation {
    display: block;
  }

}

@media screen and (max-width: 37.5em) { /*600px */

}

@media screen and (max-width: 29.5em) { /* 472px */

}
