
@media print {
//// General Styles
    h3 {
        font-size: 15pt;
    }
    p {
        font-size: 14pt;
        line-height: 17pt;
    }
    .page-indicator {
        font-size: 24pt;
        &[class^='claims-track-page-title--'],
        &[class*='claims-track-page-title--'] {
            font-size: 24pt;
            -webkit-print-color-adjust: exact;
        }
        &.claims-track-page-title--overview {
            font-size: 24pt;
            -webkit-print-color-adjust: exact;
        }
    }
    .claims-track-page-title__claim-number {
        font-size: 12pt;
    }
    .claims-track-claim-content h2,
    .claims-overview-content h2 {
        font-size: 14pt;
    }
    .claims-track-claim-recent-update-headline {
        font-size: 14pt;
    }
    .claims-track-claim-content__link-box,
    .claims-track-claim-content__text-box,
    .claims-track-claim-content__text-box p {
        font-size: 12pt;
        line-height: 13.5pt;
    }
    .claims-track-claim-content__link-box {
        text-decoration: underline;
    }
    .claims-track-claim-content__link-box dl dd,
    .claims-track-claim-content__text-box dl dd {
        line-height: 15pt;
    }
    .tooltip {
        font-size: 10pt;
    }
    .tooltip__toggle {
        font-size: 11pt;
    }
    .claims-track-claim-recent-update-see-details {
        text-decoration: underline;
    }
//// Accordion
    .claims-review-accordion__edit-btn {
        display: none;
    }
    .panel-body {
        ol {
            font-size: 14pt;
            li {
                p {
                    font-size: 12pt;
                }
            }
        }

        ul {
            li {
                font-size: 12pt;
            }
        }
    }
    //// Claim Loss Cause Icons
    .claims-track-claim-content__link-box.icon--multicarcollision:before,
    .claims-track-claim-content__text-box.icon--multicarcollision:before {
        font-size: 36pt;
        -webkit-print-color-adjust: exact;
    }
    .claims-track-claim-content__link-box.icon--contact-fixed-object:before,
    .claims-track-claim-content__text-box.icon--contact-fixed-object:before {
        font-size: 23pt;
        -webkit-print-color-adjust: exact;
    }
    .claims-track-claim-content__link-box.icon--pedestrian-accident:before,
    .claims-track-claim-content__text-box.icon--pedestrian-accident:before {
        font-size: 18pt;
        -webkit-print-color-adjust: exact;
    }
    .claims-track-claim-content__link-box.icon--animal:before,
    .claims-track-claim-content__text-box.icon--animal:before {
        font-size: 20pt;
        -webkit-print-color-adjust: exact;
    }
    .claims-track-claim-content__link-box.icon--contact-object:before,
    .claims-track-claim-content__text-box.icon--contact-object:before {
        font-size: 23pt;
        -webkit-print-color-adjust: exact;
    }
    .claims-track-claim-content__link-box.icon--business-auto-claim-loss-cause:before,
    .claims-track-claim-content__text-box.icon--business-auto-claim-loss-cause:before {
        font-size: 12pt;
        -webkit-print-color-adjust: exact;
    }
    .claims-track-claim-content__link-box.icon--earth-movement:before,
    .claims-track-claim-content__text-box.icon--earth-movement:before {
        font-size: 16pt;
        -webkit-print-color-adjust: exact;
    }
    .claims-track-claim-content__link-box.icon--object-hit-vehicle:before,
    .claims-track-claim-content__text-box.icon--object-hit-vehicle:before {
        font-size: 23pt;
        -webkit-print-color-adjust: exact;
    }
    .claims-track-claim-content__link-box.icon--fire:before,
    .claims-track-claim-content__text-box.icon--fire:before {
        font-size: 18pt;
        -webkit-print-color-adjust: exact;
    }
    .claims-track-claim-content__link-box.icon--glass:before,
    .claims-track-claim-content__text-box.icon--glass:before {
        font-size: 20pt;
        -webkit-print-color-adjust: exact;
    }
    .claims-track-claim-content__link-box.icon--injury:before,
    .claims-track-claim-content__text-box.icon--injury:before {
        font-size: 18pt;
        -webkit-print-color-adjust: exact;
    }
    .claims-track-claim-content__text-box.icon--roadside:before,
    .claims-track-claim-content__text-box.icon--roadside:before {
        font-size: 14pt;
        -webkit-print-color-adjust: exact;
    }
    .claims-track-claim-content__link-box.icon--theft:before,
    .claims-track-claim-content__text-box.icon--theft:before {
        font-size: 16pt;
        -webkit-print-color-adjust: exact;
    }
    .claims-track-claim-content__link-box.icon--accident:before,
    .claims-track-claim-content__text-box.icon--accident:before {
        font-size: 23pt;
        -webkit-print-color-adjust: exact;
    }
    .claims-track-claim-content__link-box.icon--vandalism:before,
    .claims-track-claim-content__text-box.icon--vandalism:before {
        font-size: 16pt;
        -webkit-print-color-adjust: exact;
    }
    .claims-track-claim-content__link-box.icon--weather:before,
    .claims-track-claim-content__text-box.icon--weather:before {
        font-size: 18pt;
        -webkit-print-color-adjust: exact;
    }
    .claims-track-claim-content__link-box.icon--non-accidental-damage:before,
    .claims-track-claim-content__text-box.icon--non-accidental-damage:before {
        font-size: 18pt;
        -webkit-print-color-adjust: exact;
    }
//// Auto Claims Landing Page Icons
    .claims-track-claim-content__link-box.icon--glass:before,
    .claims-track-claim-content__text-box.icon--glass:before {
        font-size: 20pt;
        -webkit-print-color-adjust: exact;
    }
    .claims-track-claim-content__link-box.icon--roadside:before,
    .claims-track-claim-content__text-box.icon--roadside:before {
        font-size: 14pt;
        -webkit-print-color-adjust: exact;
    }
    .claims-faq-link.icon--questionmark {
        text-decoration: underline;
        &:before {
            font-size: 18pt;
            -webkit-print-color-adjust: exact;
        }
    }
//// Claim Overview Page Icons
    .claims-track-claim-content__link-box.icon--claim-update:before,
    .claims-track-claim-content__text-box.icon--claim-update:before {
        font-size: 18pt;
        -webkit-print-color-adjust: exact;
    }
    .claims-track-claim-content__link-box.icon--contact:before,
    .claims-track-claim-content__text-box.icon--contact:before {
        font-size: 24pt;
        -webkit-print-color-adjust: exact;
    }
    .claims-track-claim-content__link-box.icon--claim:before,
    .claims-track-claim-content__text-box.icon--claim:before {
        font-size: 18pt;
        -webkit-print-color-adjust: exact;
    }
    .claims-track-claim-content__link-box.icon--payments:before,
    .claims-track-claim-content__text-box.icon--payments:before {
        font-size: 24pt;
        -webkit-print-color-adjust: exact;
    }
    .claims-track-claim-content__link-box.icon--wallet:before,
    .claims-track-claim-content__text-box.icon--wallet:before {
        font-size: 24pt;
        -webkit-print-color-adjust: exact;
    }
    .claims-track-claim-content__link-box.icon--exclamationmark:before,
    .claims-track-claim-content__text-box.icon--exclamationmark:before {
        font-size: 24pt;
        -webkit-print-color-adjust: exact;
    }
    .claims-track-claim-content__link-box.icon--repair:before,
    .claims-track-claim-content__text-box.icon--repair:before {
        font-size: 24pt;
        -webkit-print-color-adjust: exact;
    }
    .claims-track-claim-content__link-box.icon--auto:before,
    .claims-track-claim-content__text-box.icon--auto:before {
        font-size: 27pt;
        -webkit-print-color-adjust: exact;
    }
    .claims-track-claim-content__link-box.icon--claim-repair-estimate-report:before,
    .claims-track-claim-content__text-box.icon--claim-repair-estimate-report:before {
        font-size: 18pt;
        -webkit-print-color-adjust: exact;
    }
    .claims-track-claim-repair-shop-title {
        font-size: 15pt;
            &:before {
                font-size: 25pt;
                -webkit-print-color-adjust: exact;
            }
    }
    // Details Menu
    [class^='claims-info-menu__link--'],
    [class*='claims-info-menu__link--'] {
        text-decoration: underline;
    }
    .claims-info-menu__link--details:before {
        font-size: 18pt;
        -webkit-print-color-adjust: exact;
    }
    .claims-info-menu__link--details.icon--coverages-snapshot:before {
        font-size: 16pt;
        -webkit-print-color-adjust: exact;
    }
    .claims-info-menu__link--contacts:before {
        font-size: 24pt;
        -webkit-print-color-adjust: exact;
    }
    .claims-info-menu__link--estimates:before {
        font-size: 24pt;
        -webkit-print-color-adjust: exact;
    }
    .claims-info-menu__link--payments:before {
        font-size: 18pt;
        -webkit-print-color-adjust: exact;
    }
    .claims-info-menu__link--faq:before {
        font-size: 18pt;
        -webkit-print-color-adjust: exact;
    }
//// Uploaded File Link
    [class^='claims-track-claim-uploaded-file-link--'],
    [class*='claims-track-claim-uploaded-file-link--'] {
        font-size: 12pt;
    }
    .claims-track-claim-uploaded-file-link--doc.icon--claim-upload-doc:before {
        font-size: 18pt;
        -webkit-print-color-adjust: exact;
    }
    .claims-track-claim-details-uploads__upload-doc-title.icon--checkmark:before {
        font-size: 10pt;
        -webkit-print-color-adjust: exact;
    }
//// Disclaimer Box
    .claims-disclaimer {
        -webkit-print-color-adjust: exact;
    }
    .claims-disclaimer__txt {
        font-size: 12pt;
    }
    .claims-disclaimer__txt:before {
        font-size: 13pt;
        -webkit-print-color-adjust: exact;
    }
}
