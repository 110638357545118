.nw-progress {
    background-color: #e7e7e7;
    border-bottom: 1px solid #c3c3c3;
    font-size: 0.875em;
    margin-bottom: 20px;
    padding: 20px;
}

.nw-progress span {
    display: block;
    margin-bottom: 10px;
}
.progress {
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3) inset;
    height: 27px;
    margin-bottom: 0;
}
@media screen and (max-width: 35.5em) {
    .nw-progress {
        padding: 10px;
    }
}