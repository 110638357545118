//  _____ _ _ _ ____  _____ 
// |   | | | | |    \|   __|
// | | | | | | |  |  |__   |
// |_|___|_____|____/|_____|
//
// NATIONWIDE DESIGN SYSTEM
// --------------------------------------------------
// Forms and Inputs
//



// Radio toggle button group
.nw-button-toggle {
    display:block;
}

.nw-button-toggle .btn-default.active {
    background-color: $brand-primary;
    font-weight: 700; 
    color:#fff;
}
.nw-button-toggle .btn-default.active:hover { color:#fff; }




// Field errors
.nw-field-error {
    display:block;
    padding:3px 10px; 
    margin-top:6px;
    font-size:16px;
    
    &.alert {
        margin-bottom:0;
    }
}

.help-block + .nw-field-error { margin-top:3px; }

// Remove number spinner from type="number" from Webkit browsers and Firefox
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
	-webkit-appearance: none;
	margin: 0; 
}

// Remove number spinner from type="number" from Firefox
input[type=number] {
	-moz-appearance: textfield;
}

@media (min-width: $screen-sm) {
    .nw-field-error { 
        display:inline-block;
    }
}



// Form control width add-on
// Use these to apply limited width on input elements
.nw-input--short { width: 25%; }
.nw-input--medium { width: 50%; }
.nw-input--long { width: 75%; }


@media (min-width: $screen-sm) {
    .nw-input-sm--short { width: 25%; }
    .nw-input-sm--medium { width: 50%; }
    .nw-input-sm--long { width: 75%; }
    .nw-input-sm--full { width: 100%; }
}

@media (min-width: $screen-md) {
    .nw-input-md--short { width: 25%; }
    .nw-input-md--medium { width: 50%; }
    .nw-input-md--long { width: 75%; }
    .nw-input-md--full { width: 100%; }
}

@media (min-width: $screen-lg) {
    .nw-input-lg--short { width: 25%; }
    .nw-input-lg--medium { width: 50%; }
    .nw-input-lg--long { width: 75%; }
    .nw-input-lg--full { width: 100%; }
}
    




@media (max-width: $screen-xs) {

}

