
.claims-track-claim-content,
.claims-overview-content {
    position: relative;
    margin-bottom: 32px;
    h2 {
        font-size: 1.13em;
        font-weight: bold;
        text-transform: uppercase;
        margin: 15px 0 3px;

        @media (max-width: $small) {
            margin: 15px 60px 3px 0;
        }
    }
    .cta-txt-link {
        position: absolute;
        top: 0;
        right: 2px;
         text-decoration: none;
         &:hover {
             text-decoration: underline;
         }
         &.link-arrow-orange:after {
             position: relative;
         }
    }
}
