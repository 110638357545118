
#goBtn{
    margin-left: 5px;
    line-height: 1.38;
}

#pakLevelDocs hr,
#policyLevelDocs hr {
    border-top: 1px dotted #aaa;
}

.alignLeft{
    padding-left: 0;
}


.docHeader {
    border-bottom: 1px solid #cccccc;
    padding-left: 15px;
    padding-bottom: 10px;
}
.docFooter{
    border-bottom: 1px solid #cccccc;
    padding-left: 15px;
    padding-bottom: 10px;
    padding-top: 10px;
}
.docTitle {
    padding-top: 5px;
    margin-top: 0px;
}

.docPolicyDetails {
    padding-left: 8px;
}

.docPakDetails {
    padding-left: 45px;
}

.docAddress{
    display: inline-block;
}
.tableTab{
    margin: 0 40px 20px;
}
.doc-content {
    display: inline-block;
    background: #fff none repeat scroll 0 0;
    margin: 0;
    width: 100%;
}

.docPolicy{
    display: inline-block;
}

.noPadding{
    padding: 0;
}

.fd-content {
    display:inline-block;
    background: #fff none repeat scroll 0 0;
    margin: 0;
    width: 100%;
}

.fd-content.title {
    margin-bottom: 20px;
}

.docH1 {
    color: #666;
    font-family: "proxima-nova",Arial,sans-serif;
    font-size: 2.2em;
    font-weight: 300;
    margin: 0 10px;
    text-transform: capitalize;
}
.docH1.title{
    margin-bottom: 20px;
}

.docPad {
    margin-top: 5px;
}
.docBox{
    margin: 0;
    padding: 5px;
    border-bottom: 1px solid gray;
    border-left: 1px solid gray;
    border-right: 1px solid gray;
}
.docBox:first-of-type{
    margin: 0;
    padding: 5px;
    border: 1px solid gray;
}
.buttontile {
  display: inline-block;
  border: 1px solid gray;
  background-color: #fff;
  margin: 10px;
  padding: 20px;
  width: 100%;
}
.buttontile h3 {
  color: #0071bf;
}
.buttontile small {
  display: block;
  color: #000;
}
.buttontile .mediabox {
  min-height: 80px;
}
.buttontile .amount {
  color: #e65100;
}

.mediabox {
  display: table;
  width: 100%;
}

.mediabox__body {
  width: 100%;
  vertical-align: top;
}

.mediabox__image {
  display: table-cell;
  vertical-align: middle;
  position: relative;
}

.mediabox__image.top {
    vertical-align: top;
}

.mediabox__body.top {
    vertical-align: top;
}

.mediabox__body.paddingLeft {
    padding-left: 10px;
}

.cardTop {
    display: inline-block;
}

.center {
    text-align: center;
}

.moreDocumentsButton{
    margin-top: 20px;
    margin-bottom: 20px;
}

.disclaimerText{
    margin-left: 15px;
    width: 80%;
    margin-bottom: 20px;
}

.featuredDocument{
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 20px;
}

.smallDocumentIcon{
    padding: 10px;
    width: 40px;
    height: 50px;
}

.doc-container{
    max-height: 0px;
    width: 100%;
    overflow: hidden;
    transition-duration: 0.3s;
    transition-timing-function: linear;
}

.doc-container.open{
    max-height: 500px;
}
