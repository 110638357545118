/* 
    ///////////////////////

    THIS STYLESHEET IS FOR LOCAL DEVELOPMENT ONLY

    YOU SHOULD NOT INCLUDE THIS STYLESHEET IN PRODUCTION-DESTINE BUILDS OR BUNDLES.

    USE VENDOR SERVICES FOR ALL PRODUCTION-LEVEL FONTS

    ///////////////////////
*/

// PROXIMA NOVA / GOTHAM
@font-face {
	font-family:'Proxima-Nova';
	src: url(#{$font-path}/ProximaNova-Regular_gdi.eot);
	src: url(#{$font-path}/ProximaNova-Regular_gdi.eot?#iefix) format('embedded-opentype'),
		url(#{$font-path}/ProximaNova-Regular_gdi.woff) format('woff'),
		url(#{$font-path}/ProximaNova-Regular_gdi.ttf) format('truetype'),
		url(#{$font-path}/ProximaNova-Regular_gdi.svg#ProximaNova-Regular) format('svg');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family:'Proxima-Nova';
	src: url(#{$font-path}/ProximaNova-Light_gdi.eot);
	src: url(#{$font-path}/ProximaNova-Light_gdi.eot?#iefix) format('embedded-opentype'),
		url(#{$font-path}/ProximaNova-Light_gdi.woff) format('woff'),
		url(#{$font-path}/ProximaNova-Light_gdi.ttf) format('truetype'),
		url(#{$font-path}/ProximaNova-Light_gdi.svg#ProximaNova-Light) format('svg');
	font-weight: 200;
	font-style: normal;
}

@font-face {
	font-family:'Proxima-Nova';
	src: url(#{$font-path}/ProximaNova-Bold_gdi.eot);
	src: url(#{$font-path}/ProximaNova-Bold_gdi.eot?#iefix) format('embedded-opentype'),
		url(#{$font-path}/ProximaNova-Bold_gdi.woff) format('woff'),
		url(#{$font-path}/ProximaNova-Bold_gdi.ttf) format('truetype'),
		url(#{$font-path}/ProximaNova-Bold_gdi.svg#ProximaNova-Bold) format('svg');
	font-weight: 700;
	font-style: normal;
}




// CELESTE

@font-face {
	font-family: 'Chronicle';
	src: url(#{$font-path}/ChronicleTextG1-Bold.eot);
	src: url(#{$font-path}/ChronicleTextG1-Bold.eot?#iefix) format('embedded-opentype'),
		url(#{$font-path}/ChronicleTextG1-Bold.woff) format('woff'),
		url(#{$font-path}/ChronicleTextG1-Bold.ttf) format('truetype');
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: 'Chronicle';
	src: url(#{$font-path}/ChronicleTextG1-Italic.eot);
	src: url(#{$font-path}/ChronicleTextG1-Italic.eot?#iefix) format('embedded-opentype'),
		url(#{$font-path}/ChronicleTextG1-Italic.woff) format('woff'),
		url(#{$font-path}/ChronicleTextG1-Italic.ttf) format('truetype');
	font-weight: 400;
	font-style: italic;
}

@font-face {
	font-family: 'Chronicle';
	src: url(#{$font-path}/ChronicleTextG1-Roman.eot);
	src: url(#{$font-path}/ChronicleTextG1-Roman.eot?#iefix) format('embedded-opentype'),
		url(#{$font-path}/ChronicleTextG1-Roman.woff) format('woff'),
		url(#{$font-path}/ChronicleTextG1-Roman.ttf) format('truetype');
	font-weight: 400;
	font-style: normal;
}