#login{
  position: relative;
  float: right;
  width: 100%; }

#login #loginButton {
  font-weight: 300;
  height: 40px;
  line-height: 40px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  cursor: pointer;
  display: inline-block;
  position: relative;
  text-align: center;
  box-shadow: 0 1px 3px 0 rgba(0,0,0,0.75); }

#login>.button.green {
  padding: 0 8px 0 10px;
  margin: 25px 0 0;
  position: relative;
  text-decoration: none;
  width: 100%;
  text-align: left; }

.button.green {
  background: #276400;
  border-color: #444;
  padding: 4px 0 3px 75px;
  color: #FFF; }

#login .button.green:hover, #login .button.green:focus {
  background: none repeat scroll 0 0 #393; }

#login #loginButton, #login>.button.green {
  float: right;
  margin: 25px 10px 0 0;
  @media screen and (max-width: 995px) {
    margin-top: 10px;
    margin-bottom: 12px;
  }
}

@media screen and (min-width: 50em) {
  #login {
    margin-right: 10px;
    width: 280px; }
}

@media screen and (max-width: 50em) {
  #login #loginButton, #login > .button.green {
    position: relative;
    padding: 0 6px 0 12px;
    text-decoration: none;
    margin: 58px 0 0;
    width: 100%;
    text-align: center; }

  #login #loginButton{
    border-radius: 0; }
}