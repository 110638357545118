@media screen and (max-width: 37.5em) {
    h1 {
        font-size: 1.7em;
    }
    h2 {
        font-size:1.2em;
        margin-bottom: unset;
    }
    .section-header {
        padding: 15px 10px 10px;
    }
    .section-header--long-content h2 {
        margin-bottom: 5px;
        width: 100%;
    }
    .section-header--long-content .section-header__nonaction {
        display: block;
        float: none;
        margin-left: 0;
    }
}
@media screen and (max-width: 28.125em) {
    h1 {
        font-size: 1.5em;
        margin-bottom: 10px;
    }
    h2 {
        margin-bottom: 10px;
    }
    .section-header__nonaction {
        padding-top: 2px;
    }
    .section-header--long-content h2 {
        margin-bottom: 5px;
        max-width: none;
        width: 100%;
    }
}

@media screen and (max-width: 28.125em) {
    .nw-inline-help__content {
        font-size: 1.125em;
    }
}
@media screen and (min-width: 568px) {
    .nw-inline-help__content {
        font-size: 15px;
    }
}
.form-grouping::after {
    clear: both;
}
.nw-input--x-short {
    width: 16.5%;
}
.nw-input--md-short {
    width: 33.333%;
}
.nw-input--md-long {
    width: 66.666%;
}
@media (max-width: 450px) {
    .nw-input-xxs--x-short {
        width: 16.5%;
    }
    .nw-input-xxs--md-short {
        width: 33.333%;
    }
    .nw-input-xxs--md-long {
        width: 66.666%;
    }
}
@media (min-width: 450px) {
    .nw-input-xs--x-short {
        width: 16.5%;
    }
    .nw-input-xs--md-short {
        width: 33.333%;
    }
    .nw-input-xs--md-long {
        width: 66.666%;
    }
}
@media (min-width: 550px) {
    .nw-input-sm--x-short {
        width: 16.5%;
    }
    .nw-input-sm--md-short {
        width: 33.333%;
    }
    .nw-input-sm--md-long {
        width: 66.666%;
    }
}
@media (min-width: 750px) {
    .nw-input-md--x-short {
        width: 16.5%;
    }
    .nw-input-md--md-short {
        width: 33.333%;
    }
    .nw-input-md--md-long {
        width: 66.666%;
    }
}
@media (min-width: 960px) {
    .nw-input-lg--x-short {
        width: 16.5%;
    }
    .nw-input-lg--md-short {
        width: 33.333%;
    }
    .nw-input-lg--md-long {
        width: 66.666%;
    }
}
@media (min-width: 1100px) {
    .nw-input-xl--x-short {
        width: 16.5%;
    }
    .nw-input-xl--md-short {
        width: 33.333%;
    }
    .nw-input-xl--md-long {
        width: 66.666%;
    }
}
select.form-control {
    max-width: 100%;
    width: auto;
}
.radio-inline input[type="radio"] {
    height: 100%;
    margin-top: 1px;
}
.radio-check-container {
    padding-bottom: 20px;
}
.form-separator {
    border-top: 1px dotted #d1d1d1;
    margin: 0 0 20px;
}
.field-spacing {
    margin-bottom: 20px;
}
.breadcrumb--top {
    border-bottom: 1px solid #cccccc;
}

@media screen and (max-width: 28.125em) {
    .breadcrumb--top {
        margin-bottom: 10px;
        display: none;
    }
}

@media screen and (max-width: 35.5em) {
    .nw-progress {
        padding: 10px;
    }
}

@media screen and (max-width: 37.5em) {
    .review-submit__item {
        margin-bottom: 10px;
    }
    .review-submit__data-label {
        float: none;
        max-width: none;
        padding-right: 0;
        width: 100%;
    }
    .review-submit__data-value {
        float: none;
        width: 100%;
    }
}

@media print, screen and (max-width: 45.5em) {
    .document-list {
        border: none;
        padding: 0;
        margin: 0 0 20px 0;
    }

    .document-list .mediabox {
        padding: 20px;
    }

    .document-list--amounts .right {
        float: none;
        display: block;
    }

    .document-list .secondary-link {
        position: relative;
        left: 0;
        padding-top: 6px;
        display: block;
    }
}

@media (max-width: 549px){
    .section-header__action{
        float: left;
    }

}

@media (min-width: 768px){
    a[href^="tel:"] {
        color: #333333;
        text-decoration: none;
        pointer-events: none;
    }
}
@media (max-width: 767px){
    a[href^="tel:"]:before {
        content: "\260e";
        margin-right: 0.5em;
    }
}
