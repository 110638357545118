//  _____ _ _ _ ____  _____ 
// |   | | | | |    \|   __|
// | | | | | | |  |  |__   |
// |_|___|_____|____/|_____|
//
// NATIONWIDE DESIGN SYSTEM
// --------------------------------------------------
// Typography + Links + Lists
//


// Font styles
.nw-font-serif {
    font-family: $font-family-serif;
}

.nw-font-light { 
    font-weight:$font-weight-light; 
}

.nw-hero {
    font-size: $font-size_large;
}



// Link helpers
.nw-no-underline:hover,
.nw-no-underline:focus
{
    text-decoration: none;
}



// Single element light-on-dark
.nw-link-inverse {
  color: #fff;
  text-decoration: none;

  &:hover,
  &:focus {
    color: darken(#fff, 10%);
    text-decoration: $link-hover-decoration;
  }

  &:focus {
    @include tab-focus;
  }
}

// Changes all inner links to light-on-dark
.nw-bg-dark a {
    @extend .nw-link-inverse;
}
