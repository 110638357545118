//$gray-light-1: rgb(218,221,221);
.s-capitalize{
  text-transform:capitalize;
}
.s-spinner-lg{

}
.s-spinner-sm{
  width:30px;
  height: auto;
}

//[Must be included] Kill the scroll on the body for iPhone/iPad/iPod
.modal-open-qs {
  overflow: hidden;
  position: fixed;
}
.modal-open-qs .modal {
  overflow: scroll;
}

// styles needed for property coverages
hr.normal__separator {
  border-top: 1px solid #CCC;
  clear: both;
}
.file-claim-title {
  font-size: 0.85em;
}
.feedback-fix {
  float:right;
  margin-top:15px
}
.checkbox-other-manual-input {
  margin-top: -20px;
  margin-left: 10px;
}

.claim-dashboard {
    track-claim-content h2 {
        font-size: 1.13em;
        font-weight: 700;
        text-transform: uppercase;
        margin: 15px 0 3px;
    }
    .nw-button-container.nw-bg-light-blue {
        margin-bottom: 0px;
        margin-right: -60px;
        margin-left: -60px;
    }
    .page-indicator {

        border-bottom: none;
        position: relative;
        padding: 20px 50px 0;
        background: #fff;
        border-top: #F2F2F2;
        @media (max-width: 48.13em) {
            padding: 0 20px 0;
        }
    }
    // White Background
    .content-container-white {
        font-family: 'proxima-nova', Arial, sans-serif;
        display: inline-block;
        width: 100%;
        background-color: #fff;
        padding: 0 50px;

        @media (max-width: 48.13em) {
            padding: 20px 20px 0;
        }
        @media (max-width: 26.25em) { // 420px
            padding: 20px 0 0;
        }
    }
    .content-container-white__inner {
        display: block;
        max-width: 900px;
        margin: 0 auto;
        padding: 16px 0 32px;
        background-color: #fff;

        @media (max-width: 26.25em) { // 420px
            padding: 0 10px 30px;
        }
        h2 {
            font-size: 1.25em;
            font-weight: 200;
            text-transform: uppercase;
            margin: 15px 0 3px;
        }
    }
    .h1Color{
        color: #5e9cae;
        font-family: 'proxima-nova', Arial, sans-serif;
        font-size: 30px;
            font-weight: 200;
    }
    // Light Blue Background
    .content-container-light-blue {
        font-family: 'proxima-nova', Arial, sans-serif;
        display: inline-block;
        width: 100%;
        background-color: #DAE8ED;
        padding: 0 50px;

        @media (max-width: 48.13em) {
            padding: 20px 20px 0;
        }
        @media (max-width: 26.25em) { // 420px
            padding: 20px 0 0;
        }
    }
    .s-spinner-sm{
        width:30px;
        height: auto;
      }
     .claim-dashboard-page-title {
        position: relative;
        max-width: 900px;
        margin: 0 auto;
        padding: 20px 220px 10px 0;
        @media (max-width: 48.13em) {
            padding: 10px 0 40px;
        }
        h1 {
            color: rgb(94, 156, 174);
            font-family: 'proxima-nova', Arial, sans-serif;
            font-size: 0.88em;
            font-weight: 200;
            text-transform: none;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            margin: 0;
            padding: 0;
        }
        h2 {
            color:#4d4f53;
        }
        small {
            font-size: 0.4em;
            color: #333333;
        }
    }
    .claim-dashboard-page-container {
        padding-top:0px;
    }
    .claims-payment-page-title__claim-number {
        position: absolute;
        right: 0;
        top: 26px;
        font-family: 'proxima-nova', Arial, sans-serif;
        font-size: 1.125em;
        color: #333333;

            @media (max-width: 48.13em) {
                position: relative;
                top: 10px;
                display: block;
            }

            em {
                font-weight: bold;
                font-style: normal;
            }
            span {
                display: block;
                text-align: right;

                @media (max-width: 48.13em) {
                    text-align: left;
                }
            }
    }
    .content-container-light-blue__inner {
        display: block;
        max-width: 900px;
        margin: 0 auto;
        padding: 16px 0 32px;

        @media (max-width: 26.25em) { // 420px
            padding: 0 10px 30px;
        }
        .w75 {
            width: 75%;
        }

        .mL20 {
            margin-left: 20px
        }

        .pT30{
                padding-top: 30px;
        }
        .img-container {
            margin-top: 50px;


            p{
                margin-bottom: 0;
            }
            img{
                width:100%;
            }
            @media (max-width: 48.13em) { // 420px
                margin-top: 0;
                img{
                    margin-bottom: 20px;
                }
            }
          }
        .payment-form {
            display:flex;
            @media (max-width: 48.13em) { // 420px
                    flex-direction:column-reverse;
            }
            label{
                display: inline-block;
                max-width: 100%;
                margin-bottom: 5px;
                font-weight: normal;
            }
        }
        .bank-name{
            max-width: 100%;
            margin-bottom: 5px;
            font-weight: bold;
        }
    }
    .claims-payment-claim-content__text-box {
        background-color:#fff;
        padding: 15px;
        border: 1px solid #e1e1e1;
        border-bottom: 2px solid #e1e1e1;
    }

}
