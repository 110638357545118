@use "@nationwide-bolt/css-core/dist/scss/tokens" as Bolt;
@import "@nationwide-bolt/css-core/dist/css/bolt-core.css";

.claims-container{
    // font-family: $bolt-font-family-sans-serif;
    margin-bottom: Bolt.$space-2xl;
    max-width: 1200px;
    #pageLevelErrorNotification{
        margin-bottom: 1rem;
    }
    // h1 {
    //     margin: 0 0 Bolt.$space-xl 0;
    // }
    // h2{
    //     margin: 0 0 Bolt.$space-xl 0;
    //     text-transform: none;
    // }

    // h3{
    //     font-weight: bold;
    //     text-transform: none;
    //     margin: 0 0 Bolt.$space-xl 0;
    // }

    // h2.sub-heading{
    //     font-weight: bold;
    //     text-transform: none;
    //     margin: 0 0 Bolt.$space-md 0;
    // }
    .help-block{
        // line-height: $bolt-font-line-height-sm;
        // font-size: $bolt-font-size-sm;
        margin-bottom: Bolt.$space-xl;
    }
    // p {
    //     font-size: $bolt-font-size-md;
    //     margin-bottom: Bolt.$space-2xl;
    // }
    .progress{
        margin: Bolt.$space-xl 0 Bolt.$space-xl 0;
    }
    .bolt-textfield-wc--instructionaltext,
    .bolt-select-wc--input-instructionaltext {
        padding: Bolt.$space-md 0;
        // line-height: $bolt-font-line-height-sm;
    }
    ul.nw-claims-li {
        list-style-type: disc;
        padding:0;
        margin: Bolt.$space-xl
        li{
            margin-bottom: Bolt.$space-xl;
        }
    }
    .nw-p-normal {
        margin: 0;
        padding-bottom: Bolt.$space-xs;
    }
    .p-sm {
        // font-size: $bolt-font-size-sm;
        margin-bottom: Bolt.$space-2xl;
    }
    .nw-form-control {
        padding-bottom: Bolt.$space-xl;
    }

    .nw-contact-form {
        margin-bottom: Bolt.$space-xs;
    }
    .mg-top {
        margin-top: Bolt.$space-xs;
    }
    .nw-date-picker {
        .mat-form-field-flex, .mat-form-field-infix {
            padding: 0;
            margin: 0;
        }
        .mat-form-field-label-wrapper {
            display: none;
        }
        .mat-input-element {
            margin-top: 0;
            float: left;
            padding-left: 0.7em;
        }
        bolt-error-field{
            margin-top: -17px;
        }
    }
    .horizontal svg.bolt-radio {
        display: none;
    }
    .horizontal {
        .option {
            display: block;
        }
    }

    .claims_process {
        display: flex;
        justify-content: center;

        &--icon {
          float: left;
          width: 180px;
          margin-right: 10px;
          text-align: center;

          &.arrow {
            position: relative;
            margin-right: 10px;
            top: 20px;
            width: 40px;
          }

          &:last-child {
            margin-right: 0;
          }
        }
      }
}

@media screen and (max-width: 37.5em) {
    // h2 {
    //     margin: Bolt.$space-2xl 0 Bolt.$space-xl 0;
    //     text-transform: none;
    // }
}
// .claims-main-container {
//     margin-top: $bolt-spacing-xxl;
// }
