// Find a Drive In / Find Repair Facility
.claims-tcr-content__search-results {
    position: relative;
    &.well {
        padding: 10px;
        background: rgb(241, 241, 241);
        margin-bottom: 20px;
        border: none;
        border-radius: 0;
        -webkit-box-shadow: inset 0 0 0 transparent;
        box-shadow: inset 0 0 0 transparent;
    }
    .claims-tcr-content__search-results--num {
        position: absolute;
        top: 10px;
        left: 10px;
        height: 65px;
        width: 51px;
        font-family: 'proxima nova', sans-serif;
        font-size: 1.5em;
        font-weight: bold;
        text-align: center;
        line-height: 56px;
        color: rgb(255,255,255);
        margin: 0;
        background: url(/assets/images/claims/tcr-search-pin.png) no-repeat 0 0 transparent;
    }
    .claims-tcr-content__search-results--title {
        position: relative;
        display: block;
        font-family: 'proxima nova', sans-serif;
        font-size: 1.5em;
        text-transform: none;
        color: rgb(51, 51, 51);
        margin: 0;
        padding: 0 80px 46px 58px;
        border-bottom: 1px solid #333;
        @media (max-width: 25em) { // 400px
            padding: 0 68px 46px 58px;
        }

        &.icon-cat:after {
            content: ' ';
            background: url(/assets/images/claims/CAT-hail.svg) no-repeat 0 0 transparent;
            display: inline-block;
            height: 21px;
            width: 22px;
            margin-left: 5px;
            margin-bottom: -3px;
            border-radius: 2px;
        }

        small {
            position: absolute;
            top: 0;
            right: 0;
            display: block;
            font-size: calc(1.125em / 1.5);
        }
    }
    .claims-tcr-content__search-results--pnum {
        font-family: 'proxima nova', sans-serif;
        font-size: 1.25em;
        margin: 0;
        padding-left: 58px;
        padding-bottom: 17px;
        border-bottom: 1px solid #333;
    }
    .claims-tcr-content__search-results--details {
        float: left;
        max-width: 200px;
        margin-right: 1em;
        @media (max-width: 52.19em) { // 835px
            float: none;
            max-width: 100%;
            margin-right: 0;
        }

        dd {
            margin-left: 0;
        }
    }

    .claims-tcr-content__search-results--drive-in-times {
        float: right;
        @media (max-width: 52.19em) { // 835px
            float: none;
        }

        ul {
            @media (max-width: 52.19em) { // 835px
                display: -webkit-box;
                display: -moz-box;
                display: -ms-flexbox;
                display: -webkit-flex;
                display: flex;

                -moz-flex-flow: row nowrap;
                -webkit-flex-flow: row nowrap;
                flex-flow: row nowrap;
                -moz-justify-content: space-between;
                -webkit-justify-content: space-between;
                justify-content: space-between;
            }
            li {
                display: inline-block;
                vertical-align: top;
                margin-left: 20px;
                &:first-of-type {
                    margin-left: 0;
                }
                @media (max-width: 56.25em) { // 900px
                    margin-left: 10px;
                }
                @media (max-width: 52.19em) { // 835px
                    -moz-flex-grow: 1;
                    -webkit-flex-grow: 1;
                    flex-grow: 1;

                    -moz-flex-basis: 0;
                    -webkit-flex-basis: 0;
                    flex-basis: 0;
                }
                @media (max-width: 34.38em) { // 550px
                    word-spacing: 100px;
                }

                a {
                    width: 95px;
                    display: inline-block;
                    text-decoration: none;
                    font-size: 1.125em;
                    font-weight: 400;
                    text-align: center;
                    background: rgb(242, 242, 242);
                    border: 1px solid #ccc;
                    color: rgb(51, 51, 51);
                    padding: 10px;
                    border-radius: 4px;
                    &:hover,
                    &:active,
                    &.selected {
                        color: white;
                        background: #335d96;
                        border: 1px solid #2c5182;
                    }
                    &.not-available,
                    &.not-available:hover,
                    &.not-available:active {
                        color: rgb(102, 102, 102);
                        background: rgb(204, 204, 204);
                        border: 1px solid #ccc;
                        cursor: not-allowed;
                    }

                    @media (max-width: 52.19em) { // 835px
                        width: 100%;
                    }
                    @media (max-width: 28.13em) { // 450px
                        padding: 5px;
                    }
                }
            }
        }
    }

    // Find Repair Facility Only
    &.find-repair-shop {
        .claims-tcr-content__search-results--details {
            max-width: 65%;
            margin-right: 1em;
            @media (max-width: 60em) { // 960px
                max-width: 100%;
                margin-right: 0;
            }
            @media (max-width: $small) { // 767px
                max-width: 75%;
                margin-right: 1em;
            }
            @media (max-width: 38.75em) { // 620px
                max-width: 65%;
            }
            @media (max-width: 23.38em) { // 374px
                max-width: 100%;
                margin-right: 0;
            }
        }
        .claims-tcr-content__search-results--title {
            padding: 0 92px 27px 58px;
            border-bottom: 1px solid #333;
            @media (max-width: 25em) { // 400px
                padding: 0 0 46px 58px;
            }

            small {
                position: relative;
            }
        }
        .claims-tcr-content__search-results--pnum {
            position: absolute;
            right: 10px;
            text-align: right;
            border-bottom: none;
            margin-top: 10px;
            padding-left: 0;
            padding-bottom: 0;
            @media (max-width: 60em) { // 960px
                position: relative;
                right: auto;
                text-align: left;
            }
            @media (max-width: $small) { // 767px
                position: absolute;
                right: 10px;
                text-align: right;
            }
            @media (max-width: 23.38em) { // 374px
                position: relative;
                right: auto;
                text-align: left;
            }
        }
        .claims-tcr-content__search-results--select-btn {
            position: absolute;
            top: 10px;
            right: 10px;
            @media (max-width: 47.94em) { // 767px
                position: relative;
                top: 0;
                right: 0;
                margin-top: 20px;
            }
        }
        .claims-qtcr-content__search-results--select-btn {
            clear: both;
            display: block;
            max-width: 120px;
            @media (max-width: 47.94em) { // 767px
                margin-top: 20px;
                max-width: 100%;
            }

            &.selected {
                text-indent: 16px;
                background-image: url(/assets/images/claims/icon--checkmark-white.svg);
                background-size: 18px;
                background-repeat: no-repeat;
                background-position: 10px 10px;
                background-color: rgb(58, 145, 63);
                border-color: #3a913f;
                @media (max-width: 47.94em) { // 767px
                    text-indent: 0;
                    background-size: 18px;
                    background-position: 42% 10px;
                }
                @media (max-width: 40.63em) { // 650px
                    background-position: 40% 10px;
                }
                @media (max-width: 32.50em) { // 520px
                    background-position: 37% 10px;
                }
                @media (max-width: 23.75em) { // 380px
                    background-position: 34% 10px;
                }
            }
        }
    }
}

// Day Picker for Find a Drive In
.claims-tcr-day-list-nav {
    margin: 70px 0 40px;

    .claims-tcr-day-list-nav__today {
        position: relative;

        &:before {
            position: absolute;
            top: -30px;
            display: block;
            content: 'Today';
            height: 30px;
            width: 100%;
            background: rgb(53, 97, 148);
            color: rgb(255,255,255);
            padding: 6px;
            text-align: center;
        }
    }

    .claims-tcr-day-list-nav__day-list {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;

        -moz-flex-direction: row;
        -webkit-flex-direction: row;
        flex-direction: row;

        -moz-flex-wrap: nowrap;
        -webkit-flex-wrap: nowrap;
        flex-wrap: nowrap;

        -moz-justify-content: space-between;
        -webkit-justify-content: space-between;
        justify-content: space-between;

        height: auto;
        max-width: 100%;
        margin: 30px auto 0;
        padding: 0;
        border: none;

        li {
            -moz-flex-grow: 1;
            -webkit-flex-grow: 1;
            flex-grow: 1;

            -moz-flex-basis: 0;
            -webkit-flex-basis: 0;
            flex-basis: 0;

            text-align: center;
            border: 1px solid #666;
            border-left: none;

            &:last-of-type,
            &:first-of-type {
                -moz-flex-grow: 0;
                -webkit-flex-grow: 0;
                flex-grow: 0;

                -moz-flex-basis: 50px;
                -webkit-flex-basis: 50px;
                flex-basis: 50px;
                border: none;

                @media (max-width: 600px) {
                    -moz-flex-basis: 34px;
                    -webkit-flex-basis: 34px;
                    flex-basis: 34px;
                }
                @media (max-width: 360px) {
                    -moz-flex-basis: 30px;
                    -webkit-flex-basis: 30px;
                    flex-basis: 30px;
                }

                a,
                a:hover,
                a:focus,
                a:active {
                    padding-top: 10px;
                    background: transparent;
                }
                a:focus,
                a:active {
                    color: rgb(51, 51, 51);
                    outline: thin dotted;
                }
            }
            &:first-of-type {
                border-right: 1px solid #666;
                text-align: left;

                a.disabled {
                    cursor: not-allowed;
                    .chevron--left {
                        &:before {
                            border-color: transparent #ccc transparent transparent;
                        }
                    }
                }
            }
            &:last-of-type {
                text-align: right;

                a.disabled {
                    cursor: not-allowed;
                    .chevron {
                        &:before {
                            border-color: transparent transparent transparent #ccc;
                        }
                    }
                }
            }

            a {
                float: none;
                display: block;
                height: 60px;
                font-size: 1.25em;
                line-height: inherit;
                text-transform: uppercase;
                color: rgb(51,51,51);
                padding: 4px 0 0;
                background: rgb(231, 231, 231);
                margin-left: 0;
                transition: .25s all ease;
                @media (max-width: 560px) {
                    font-size: 1.125em;
                    padding: 10px 0 0;
                }
                &:hover,
                &:focus {
                    color: rgb(255,255,255);
                    background: rgb(53, 97, 148);
                    text-decoration: none;
                }
                &.selected {
                    font-weight: 400;
                    color: rgb(255,255,255);
                    background: rgb(53, 97, 148);
                    text-decoration: none;
                }
            }
        }

        .chevron {
            display: inline-block;
            width: 10px;
            height: 8px;
            position: relative;
            margin: 0 5px;

            &:before {
                right: 0;
                border-width: 8px 0 8px 8px;
                border-color: transparent transparent transparent #333;
            }
            &:after {
                left: 0;
                border-width: 8px 0 8px 8px;
                border-color: transparent transparent transparent #FFF;
            }
        }
        .chevron--left {
            &:before {
                left: 0;
                border-width: 8px 8px 8px 0;
                border-color: transparent #333 transparent transparent;
            }
            &:after {
                right: 0;
                left: auto;
                border-width: 8px 8px 8px 0;
                border-color: transparent #FFF transparent transparent;
            }
        }

    }
}

// Class for search result map tooltip
.tcr-search-map .esriPopup {
    font-family: 'proxima-nova', Arial, sans-serif;
    font-size: 10px;
    color: rgb(51,51,51);
    border-radius: 0;
}
.tcr-search-map .esriPopup .sizer {
    max-width: 180px;
    min-width: 150px;
    width: auto !important;
}
.tcr-search-map .esriPopup .esriPopupWrapper {
    background: rgb(241,241,241);
    border: 1px solid #356194;
    box-shadow: 6px 6px 20px rgba(0,0,0,0.19);
    border-radius: 0;
}
.tcr-search-map .esriPopup .titlePane {
    background-color: rgb(241,241,241);
    line-height: 12px;
    color: rgb(51,51,51);
    padding: 6px 10px 0 10px;
    border-radius: 0;
    cursor: default;
}
.tcr-search-map .esriPopup .titlePane .title {
    font-family: 'proxima-nova', Arial, sans-serif;
    font-size: 12px;
    font-weight: bold;
    color: rgb(51,51,51);
    padding-right: 20px;
}
.tcr-search-map .esriPopup .titleButton.maximize {
    display: none;
}
.tcr-search-map .esriPopup .titleButton.close {
    top: 6px;
    right: 10px;
    height: auto;
    width: auto;
    background: none;
}
.tcr-search-map .esriPopup .titleButton.close:before {
    display: block;
    content: 'X';
    font-family: 'proxima-nova', Arial, sans-serif;
    font-size: 14px;
    font-weight: 500;
    color: rgb(51,51,51);
}
.tcr-search-map .esriPopup .contentPane {
    background-color: rgb(241,241,241);
    color: rgb(51,51,51);
    padding: 10px 10px 6px 10px;
    overflow-wrap: break-word;
}
.tcr-search-map .esriPopup .contentPane a {
    font-family: 'proxima-nova', Arial, sans-serif;
    font-size: 12px;
    color: rgb(0,86,145);
    text-decoration: none;
}
.tcr-search-map .esriPopup .contentPane a:hover,
.tcr-search-map .esriPopup .contentPane a:active,
.tcr-search-map .esriPopup .contentPane a:focus {
    text-decoration: underline;
}
.tcr-search-map .esriPopup .contentPane dl {
    margin: 0;
}
.tcr-search-map .esriPopup .contentPane dl dd {
    margin-left: 0;
}
.tcr-search-map .esriPopup .contentPane .phone {
    display: block;
    font-family: 'proxima-nova', Arial, sans-serif;
    font-size: 12px;
    color: rgb(0,86,145);
    margin: 3px 0 8px;
}
.tcr-search-map .esriPopup .actionsPane {
    background-color: rgb(241,241,241);
    color: rgb(51,51,51);
    padding: 2px 10px 16px 10px;
}
.tcr-search-map .esriPopup .actionsPane a {
    font-family: 'proxima-nova', Arial, sans-serif;
    font-size: 12px;
    color: rgb(0,86,145);
    text-decoration: none;
}
.tcr-search-map .esriPopup .actionsPane a:hover,
.tcr-search-map .esriPopup .actionsPane a:active,
.tcr-search-map .esriPopup .actionsPane a:focus {
    text-decoration: underline;
}
.tcr-search-map .esriPopup .pointer.bottom {
    background: rgb(241,241,241);
    border: 1px solid #356194;
    box-shadow: 6px 6px 20px rgba(0,0,0,0.19);
}
.tcr-search-map .esriPopup .pointer.bottomLeft {
    background: rgb(241,241,241);
    border: 1px solid #356194;
    box-shadow: 6px 6px 20px rgba(0,0,0,0.19);
}
.tcr-search-map .esriPopup .pointer.bottomRight {
    background: rgb(241,241,241);
    border: 1px solid #356194;
    box-shadow: 6px 6px 20px rgba(0,0,0,0.19);
}
.tcr-search-map .esriPopup .pointer.top {
    background: rgb(241,241,241);
    border: 1px solid #356194;
    box-shadow: 6px 6px 20px rgba(0,0,0,0.19);
}
.tcr-search-map .esriPopup .pointer.topLeft {
    background: rgb(241,241,241);
    border: 1px solid #356194;
    box-shadow: 6px 6px 20px rgba(0,0,0,0.19);
}
.tcr-search-map .esriPopup .pointer.topRight {
    background: rgb(241,241,241);
    border: 1px solid #356194;
    box-shadow: 6px 6px 20px rgba(0,0,0,0.19);
}
.tcr-search-map .esriPopup .outerPointer.right {
    background: rgb(241,241,241);
    border: 1px solid #356194;
    box-shadow: 6px 6px 20px rgba(0,0,0,0.19);
}
.tcr-search-map .esriPopup .outerPointer.left {
    background: rgb(241,241,241);
    border: 1px solid #356194;
    box-shadow: 6px 6px 20px rgba(0,0,0,0.19);
}

.claims-tcr-content__OYS-block {
    position: relative;
    padding: 15px 26px 35px 59px;
    @media (max-width: 48.13em) { // 770px
            padding: 15px 26px 15px 44px;
    }

    &.icon--OYS:before {
        position: absolute;
        left: 0;
        content: '';
        display: block;
        height: 50px;
        width: 50px;
        background: url(/assets/images/claims/icon--repair.svg) no-repeat 0 center $blue-icon;
        background-size: 83%;
        border-radius: 999px;

        @media (max-width: 48.13em) { // 770px
            height: 35px;
            width: 35px;
        }
    }
}
.claims-tcr-content__drive-in-block {
    position: relative;
    padding: 15px 26px 35px 59px;
    @media (max-width: 48.13em) { // 770px
        padding: 15px 26px 15px 44px;
    }

    &.icon--nw-drive-in:before {
        position: absolute;
        left: 0;
        content: '';
        display: block;
        height: 50px;
        width: 50px;
        background: url(/assets/images/claims/icon--nw-drive-in.svg) no-repeat center center $blue-icon;
        background-size: 60%;
        border-radius: 999px;

        @media (max-width: 48.13em) { // 770px
            height: 35px;
            width: 35px;
        }
    }
}
.claims-tcr-content__other-options-block {
    position: relative;
    padding: 15px 26px 35px 59px;
    @media (max-width: 48.13em) { // 770px
        padding: 15px 26px 15px 44px;
    }

    &.icon--OOP:before {
        position: absolute;
        left: 0;
        content: '';
        display: block;
        height: 50px;
        width: 50px;
        background: url(/assets/images/claims/icon--OOP.svg) no-repeat center center $blue-icon;
        background-size: 50%;
        border-radius: 999px;

        @media (max-width: 48.13em) { // 770px
            height: 35px;
            width: 35px;
        }
    }
    dl {
        dd {
            font-weight: 700;
            line-height: 1.25em;
            margin: 0;
            padding-bottom: 5px;
        }
    }
}

.claims-tcr-content__highlight-box-title {
    font-family: 'proxima-nova', Arial, sans-serif;
    color: #4d4f53;
    font-size: 1.13em;
    font-weight: bold;
    text-transform: uppercase;
    margin: 15px 0 3px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.claims-tcr-content__highlight-box {
    margin-bottom: 20px;
    padding: 20px;

    hr {
        height: 2px;
        background: rgb(102, 102, 102);
    }
}

.claims-tcr-details-phone,
.claims-tcr-details-app,
p.claims-tcr-details-phone,
p.claims-tcr-details-app {
    font-size: 1.25em;
}

.claims-tcr-content__sucess-box {
    position: relative;
    padding-left: 76px;
    @media (max-width: 48.13em) { // 770px
        padding-left: 44px;
    }

    &:before {
        position: absolute;
        left: 0;
        height: 54px;
        width: 54px;
        content: '';
        background: url(/assets/images/claims/icon--checkmark.svg) no-repeat 0 6px transparent;
        background-size: 98%;

        @media (max-width: 48.13em) { // 770px
            background-size: 67%;
        }
    }
}

.claims-tcr-drive-in-legend {
    [class*="claims-tcr-drive-in-legend__"],
    [class^="claims-tcr-drive-in-legend__"] {
        display: inline-block;
        height: 21px;
        margin: 0 0 0 20px;
        @media (max-width: 25em) { // 400px
            margin: 0 0 0 10px;
        }
        &:before {
            display: inline-block;
            content: '';
            height: 21px;
            width: 22px;
            margin-right: 10px;
            border-radius: 2px;
            @media (max-width: 25em) { // 400px
                margin-right: 5px;
            }
        }
    }
    .claims-tcr-drive-in-legend__avail {
        margin: 0;
        &:before {
            background: rgb(242, 242, 242);
            border: 1px solid #ccc;
        }
    }
    .claims-tcr-drive-in-legend__unavail {
        &:before {
            background: rgb(204, 204, 204);
            border-radius: 2px;
        }
    }
    .claims-tcr-drive-in-legend__selected {
        &:before {
            background: rgb(51, 93, 150);
            border-radius: 2px;
        }
    }
    .claims-tcr-drive-in-legend__cat {

        @media (max-width: 35em) { // 560px
            display: block;
            margin: 10px 0 0;
        }

        &:before {
            content: ' ';
            background: url(/assets/images/claims/CAT-hail.svg) no-repeat 0 0 transparent;
            margin-right: 6px;
            margin-bottom: -2px;
        }
    }
}

// Overrides
.tab-content .form-group { // Bolt/Bootstrap override to match visual design
    margin-bottom: 0;

    @media (max-width: 767px) {
        margin-bottom: 26px;
    }
}

.claims-tcr-content__search-results {
    .form-group .tooltip {
        width: auto;
    }
}

// Small Mobile View Fixes for Tabs Component
.nav>li>a {
    @media (max-width: 22.5em) { // 360px - Android fix
        padding: 10px 10px;
    }
}
@media (max-width: 20em) { // 320px - iPhone 5 fix
    .nav-tabs {
        padding-left: 4px;
    }
    .nav-tabs>li {
        margin-right: 2px;
    }
    .nav-tabs>li>a {
        margin-right: 0;
    }
    .nav>li>a {
        padding: 10px 6px;
    }
}

input[type="file"] {
    display: none;
}

.claims-track-claim-content__text-box .button-container {
    &.tcr-review-btn-block {
        padding: 0;
        background: none;
        border-top: none;
        margin-bottom: 0;
    }
}

.js .show-wait .button-container {
    &.tcr-review-btn-block {
        background: rgb(255,255,255);
    }
}
