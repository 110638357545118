/* Nationwide Bootstrap Colors
*/
@include color_family($color-nwblue,"nwblue",true);

@include color_family($color-blue,"blue");
@include color_family($color-blue-muted,"blue-muted");
@include color_family($color-teal,"teal");
@include color_family($color-teal-muted,"teal-muted");
@include color_family($color-green,"green");
@include color_family($color-yellow,"yellow");
@include color_family($color-orange,"orange");
@include color_family($color-red,"red");
@include color_family($color-fuchsia,"fuchsia");
@include color_family($color-purple,"purple");

@include color_family($color-blue-dark,"blue-dark");
@include color_family($color-blue-muted-dark,"blue-muted-dark");
@include color_family($color-teal-dark,"teal-dark");
@include color_family($color-teal-muted-dark,"teal-muted-dark");
@include color_family($color-green-dark,"green-dark");
@include color_family($color-yellow-dark,"yellow-dark");
@include color_family($color-orange-dark,"orange-dark");
@include color_family($color-red-dark,"red-dark");
@include color_family($color-fuchsia-dark,"fuchsia-dark");
@include color_family($color-purple-dark,"purple-dark");

@include color_family($color-blue-accent,"blue-accent");
@include color_family($color-blue-muted-accent,"blue-muted-accent");
@include color_family($color-teal-accent,"teal-accent");
@include color_family($color-teal-muted-accent,"teal-muted-accent");
@include color_family($color-green-accent,"green-accent");
@include color_family($color-yellow-accent,"yellow-accent");
@include color_family($color-orange-accent,"orange-accent");
@include color_family($color-red-accent,"red-accent");
@include color_family($color-fuchsia-accent,"fuchsia-accent");
@include color_family($color-purple-accent,"purple-accent");

@include color_family($color-black,"black");
//@include color_family($color-neutral-cool,"neutral-cool");
//@include color_family($color-neutral-warm,"neutral-warm");


@include color_family($color-neutral-cool1,"neutral-cool1");
@include color_family($color-neutral-cool2,"neutral-cool2");
@include color_family($color-neutral-cool3,"neutral-cool3");
@include color_family($color-neutral-cool4,"neutral-cool4");

@include color_family($color-neutral-warm1,"neutral-warm1");
@include color_family($color-neutral-warm2,"neutral-warm2");
@include color_family($color-neutral-warm3,"neutral-warm3");
@include color_family($color-neutral-warm4,"neutral-warm4");



//
// Backgrounds
//

.nw-bg-white { background:#fff; }
.nw-bg-black { background:#000; color:#fff; }

// Primary
.nw-bg-primary {
	background:$brand-primary;
	color:#fff;
}

.nw-bg-secondary {
	background:$brand-secondary;
	color:#333;
}

.nw-bg-accent {
	background:$brand-accent;
	color:#fff;
}




//
// Foregrounds
//

.nw-fg-white { color:#fff; }
.nw-fg-black { color:#333; }
.nw-fg-link { color: $link-color; }

// Primary
.nw-fg-primary { color:$brand-primary; }
.nw-fg-secondary { color:$brand-secondary; }
.nw-fg-accent { color:$brand-accent; }




