//  _____ _ _ _ ____  _____ 
// |   | | | | |    \|   __|
// | | | | | | |  |  |__   |
// |_|___|_____|____/|_____|
//
// NATIONWIDE DESIGN SYSTEM
// --------------------------------------------------
// Responsive Utilities + Helpers
//

@media (max-width: $screen-sm) {
    .nw-stack--sm { 
        display: block !important;
        float: none !important;
        width: 100% !important;
    }
}

@media (max-width: $screen-md) {
    .nw-stack--md { 
        display: block !important;
        float: none !important;
        width: 100% !important;
    }
}

@media (max-width: $screen-lg) {
    .nw-stack--lg { 
        display: block !important;
        float: none !important;
        width: 100% !important;
    }
}