
.tooltip__toggle {
    line-height: 0.8em;
    font-size: 0.8em;
    vertical-align: middle;
    border: 1px solid #adafaf;
    background-color: transparent;
    color: rgb(52, 96, 148);
    height: 20px;
    width: 20px;
    margin-left: 0;
    margin-bottom: 4px;
    border: 1px solid #346094;
    cursor: pointer;
    &:hover, &:focus {
        color: rgb(255,255,255);
    }
}

.tooltip__toggle--open {
    color: rgb(255,255,255);
}
.tooltip__toggle--open,
.tooltip__toggle--closed {
    margin-left: 0;
    margin-right: 0;
    line-height: 16px;
}

.tooltip__toggle--active {
    background-color: rgb(52, 96, 148);
    color: rgb(255,255,255);
}

.tooltip__toggle--active .tooltip__toggle--open {
    display: inline;
    font-size: 0.88em;
}

.tooltip {
    display: block; // Nationwide base style override
    font-size: 0.88em;
    margin: 10px 0;
    background: rgb(255,255,255);
    border: none;
}
.tooltip.blue-bg {
    background: $blue-light-2;
}

.tooltip__caret:before {
    border-bottom-color: rgb(255,255,255);
}
.tooltip__caret:after {
    border-bottom-color: rgb(255,255,255);
}
.tooltip__caret.blue:before {
    border-bottom-color: $blue-light-2;
}
.tooltip__caret.blue:after {
    border-bottom-color: $blue-light-2;
}
