.sprite_dollar:before, .sprite_autoPolicy:before, .sprite_homePolicy:before{
    content:"";
    display:block;
    float:left;
    left:0;
    position:absolute;
    top:0
}
.sprite_dollar:before{
    background-position:3px -286px;
    height:30px;
    width:25px
}
.header-icon{
    padding-left: 35px;
    position:relative;
}
.header-icon img{
    position: absolute;
    left: 0;
    top: 0;
}