//  _____ _ _ _ ____  _____ 
// |   | | | | |    \|   __|
// | | | | | | |  |  |__   |
// |_|___|_____|____/|_____|
//
// NATIONWIDE DESIGN SYSTEM
// --------------------------------------------------
// Bootstrap Glyphicons
//



.glyphicon {
    line-height: inherit;
    top:0;
}


// Overwrites for default glyphicons to use Nationwide utility font
// References the Bolt icons for only a limited set, otherwise default Bootstrap icons are used


// Calendar
.glyphicon-calendar {
    font-family: "Bolticons";
    &:before { content: "\e912"; } 
}

.glyphicon-ok {
    font-family: "Bolticons";
    &:before { content: "\e901"; }     
}

.glyphicon-home {
    font-family: "Bolticons";
    &:before { content: "\e906"; }     
}

.glyphicon-lock {
    font-family: "Bolticons";
    &:before { content: "\e908"; }     
}

.glyphicon-menu-hamburger {
    font-family: "Bolticons";
    &:before { content: "\e909"; }     
}

.glyphicon-print {
    font-family: "Bolticons";
    &:before { content: "\e90a"; }     
}


.glyphicon-search {
    font-family: "Bolticons";
    &:before { content: "\e90c"; }      
}


.glyphicon-envelope {
    font-family: "Bolticons";
    &:before { content: "\e913"; }      
}


.glyphicon-resize-full {
    font-family: "Bolticons";
    &:before { content: "\e914"; }      
}


.glyphicon-exclamation-sign {
    font-family: "Bolticons";
    &:before { content: "\e904"; }     
}


.glyphicon-info-sign {
    font-family: "Bolticons";
    &:before { content: "\e90f"; }     
}


.glyphicon-alert {
    font-family: "Bolticons";
    &:before { content: "\e911"; }     
}


.glyphicon-cog {
    font-family: "Bolticons";
    &:before { content: "\e90d"; }     
}
