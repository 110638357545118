@use "@nationwide-bolt/css-core/dist/scss/tokens" as Bolt;

.global-container {
    background: #fff none repeat scroll 0 0;
    font-size: 1.125em !important;
    height: 100%;
    margin: 0 auto;
    overflow: hidden;
    width: 100%;
}
footer is-icon {
    height: 24px;
    width: 24px;
}
.footer-container {
    background-color: #222222;
    overflow: hidden;
    padding: .75rem;
}

.bolt-footer-container {
    color: #fff;
    display: block;
    background: #222;
    padding: 3.25rem 5.25rem;
    font-size: .875rem;
}

.inner-container {
    height: 100%;
    // max-width: 1168px;
    margin: 0 auto;
    margin-top: 10px;
    overflow: visible;
    max-width: 1200px;
    // @media screen and (min-width: 1200px) {
    //     max-width: 100%;
    // }
}
.outer-container {
    height: 100%;
    transition: all 0.2s ease 0s;
    width: 100%;
}

.doc-info-container {
    display: block;
    padding-top: 10px;
    background-color: #fff
}
.panel-container{
    background-color: #fff;
    border-radius: 15px;
    border: 1px solid #ddd;
}
.slim-link-list{
    list-style: none;
    padding:10px 0;
    margin:0;
    font-size:0px;
    text-align: center;
}
.slim-link-list li{
    display: inline-block;
    padding: 0 5px;
    border-right: solid 1px $color-blue-accent;
    font-size:$font-size-small;
}
.slim-link-list li:last-of-type{
    border-right: none;
}
.slim-panel{
    padding: 10px 15px;
}
.short-panel p, .short-panel h2, .slim-panel p{
    margin-bottom:0;
}
.short-panel .btn{
    font-size: $font-size-small;
}
.short-panel{
    padding: 15px;
}
.short-panel > div{
    margin-bottom: 15px;
}
.short-panel > div:last-of-type{
    margin-bottom:0;
}

.header-container__description{
    font-weight: 400;
}
.header-container__heading {
    text-align: left;
    width: unset;
    padding-left: 40px;
}
.panel-container .form-separator{
    margin:0;
}

.panel-collapse{
    height: 0;
    overflow: hidden;
}
.panel-collapse.panel-open{
    height: auto;
}
.pak-accordion .panel-body{
    background-color: #ededed;
    padding: 10px 5px 5px;
}

.panel-container{
    margin-bottom: 7px;
}
@media (max-width: 549px){
    .short-panel .text-right{
        text-align: left;
    }
    .short-panel .btn{
        margin-bottom:10px;
        display:block;
        width: 280px;
        max-width:100%;
    }
    .short-panel .btn:last-of-type{
        margin-bottom: 0;
        margin-right: auto;
    }
}

.panel-element{
    padding-bottom: 10px;
}

// Trustarc banner overrides
#truste-consent-track { //banner container
    direction: ltr !important;
    background-color: rgb(255, 255, 255)!important;
    box-shadow: rgb(0 0 0) 5px 5px 50px !important;
    position: fixed !important;
    width: 100% !important;
    bottom: 15% !important;
    z-index: 999996 !important;
    opacity: 92% !important;
}

#truste-consent-content { //banner txt container
    overflow: hidden !important;
    padding: 15px 15px 5px !important;
    position: relative !important;
    border-color: rgb(255, 255, 255) !important;
    border-style: solid !important;
}

#truste-consent-text { //banner txt
    color: #222 !important;
    font-size: 14px !important;
    margin: 2px 0px 10px 0px !important;
    line-height: 95% !important;
    padding: 0.23rem 2rem 0.23rem 0.1rem !important;
}

.truste-buttonsColumn { //btn container
    font-size: 15px !important;
    font-family: "MuseoSans300",sans-serif !important;
    float: right !important;
    margin: -0.4rem 2rem 0 0 !important;
}

#truste-show-consent { //manage settings btn
    background-color: #0047bb !important;
    color: #fff !important;
    border: 1px solid #0047bb !important;
    border-radius: 4px !important;
    cursor: pointer !important;
    font-family: "MuseoSans300",sans-serif !important;
    margin: 0px !important;
}

#truste-consent-button, #truste-consent-required { //accept and decline btns
    display: inline !important;
    width: fit-content !important;
    line-height: 110% !important;
    padding: 0.5rem 0.2rem !important
}

.truste-icon-box { //close btn container
    cursor: pointer !important;
    position: absolute !important;
    right: 10px !important;
    top: 10px !important;
    border-style: solid !important;
    // border-color: rgb(255,0,0) !important;
    border-color: rgb(255, 255, 255)!important;
    border-radius: 5px !important;
    margin: -5px !important;
    padding: 1px !important;
}

.truste-icon-box img { //close btn image
    width: 25px !important;
    margin: -15px 1px -14px 1px !important;
}

@media screen and (min-width: 330px) and (max-width: 767px){ //placing banner above app footer btns
    #truste-consent-track {
        bottom: 26% !important;
    }
}

@media screen and (min-width: 295px) and (max-width: 430px){ //placing accept and decline btns on same line
    #truste-consent-button, #truste-consent-required {
        width: calc(24% - 2px) !important;
    }
}

@media screen and (min-width: 1px) and (max-width: 430px){ //placing manage btn on same line, same size
    #truste-show-consent {
        display: inline-block !important;
        width:50% !important;
        height: 2.05rem !important;
        margin: 0px auto 0px auto !important;
    }
}

@media screen and (min-width: 1px) and (max-width: 294px){ //placing accept and decline btns on same line
    #truste-consent-button, #truste-consent-required {
        width: calc(50% - 0.2rem) !important;
    }

    #truste-show-consent {
        width:100% !important;
    }
}

@media screen and (min-width: 1px) and (max-width: 330px){ //placing manage btn on same line, same size
    #truste-consent-track {
        overflow: scroll !important;
        bottom: 10% !important;
        top: 10%;
    }
}
