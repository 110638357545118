
/* Icon message boxes with buttons
*/

.message-container { overflow: auto; width:100%; border:2px solid; margin-bottom:20px; padding:20px; position: relative; background-color: #FFFFFF; z-index:998; }
.message-container .before-sprite { padding-left: 50px; float:left; margin-bottom:10px; }

.lt-ie8 .message-container { width:auto; }

.message-container p { margin-bottom:0; }

.message-container ul { margin:0; padding:0; list-style:none; }
.message-container li { margin:0 0 .75em 0; }
.message-container li:last-child { margin:0; }

.message-container--error { border-color: #d8070f; color:#d8070f;}
.message-container--warning { border-color: #e4a91f; }
.message-container--information { border-color: #0c72b9; }
.message-container--success { border-color: #377806; }
.message-container--closeable { display:none; } // Used when message can be toggled
.message-container--fixed { left: 50%; max-width: 1200px; position: fixed; top: 50%; transform:translate(-50%, -50%); -webkit-transform:translate(-50%, -50%); -ms-transform:translate(-50%, -50%); }
.message-container--error--visible { display:block; }


// Common rules for icon messages
.message-container--error .before-sprite:before,
.message-container--warning .before-sprite:before,
.message-container--information .before-sprite:before,
.message-container--success .before-sprite:before { content:" "; display:block; float:left; position:absolute; top: 20px; left:15px; }


// Icon positioning within sprite
.message-container--error .before-sprite:before { background-position: -72px -468px; height:32px; width:32px; }
.message-container--warning .before-sprite:before { background-position: -107px -468px; height:32px; width:40px; }
.message-container--information .before-sprite:before { background-position: -1px -468px; height:32px; width:32px; }
.message-container--success .before-sprite:before { background-position: -35px -468px; height:32px; width:33px; }


// Message button rules, modifiers
.message-container__buttons { padding: 0 3px 3px 3px; overflow: auto; width: 100%; float: left; } // padding allows for button shadow

// One button
.message-container--onebutton .before-sprite { width:80%; }
.message-container--onebutton .message-container__buttons { width:20%; }

// Two buttons
.message-container--twobutton .before-sprite { width:75%; }
.message-container--twobutton .message-container__buttons { width:25%; }

// Three buttons
.message-container--threebutton .before-sprite { width:65%; }
.message-container--threebutton .message-container__buttons { width:35%; }



/* Exception cases */

// Timeout warning
#timeoutWarning.message-container--twobutton .before-sprite { width:65%; }
#timeoutWarning.message-container--twobutton .message-container__buttons { width:35%; }



/* Query resizing */
// 1100px
@media screen and (max-width:68.750em) {

	// When 3+ buttons, make on block soon
	.message-container--threebutton .before-sprite,
	.message-container--threebutton .message-container__buttons,
	#timeoutWarning.message-container--twobutton .before-sprite,
	#timeoutWarning.message-container--twobutton .message-container__buttons
		{ width:100%; float:none; }


	// Two buttons, tighten up
	.message-container--twobutton .before-sprite { width:68%; }
	.message-container--twobutton .message-container__buttons { width:32%; }
}


//740px
@media screen and (max-width:48em) {

	// After tablet, make all cases block levels
	.message-container--onebutton .message-container__buttons,
	.message-container--onebutton .before-sprite,
	.message-container--twobutton .message-container__buttons,
	.message-container--twobutton .before-sprite,
	.message-container__buttons
		{ float:none; width:100%; }
}








/*
// Prev as of 4/29 ~BV
.message-container { width:100%; border:2px solid; margin-bottom:20px; position: relative; background-color: #FFFFFF; z-index: 999; }
.message-container--error { border-color: #d8070f;padding:20px 20px 20px 60px; }
.message-container--warning { border-color: #e4a91f;padding:20px 20px 20px 68px; }
.message-container--information { border-color: #0c72b9;padding:20px 20px 20px 60px; }
.message-container--success { border-color: #377806;padding:20px 20px 20px 61px; }

.lt-ie8 .message-container { width:auto; }

.error {color:#d8070f;}

.error:before { background-position: -72px -468px; height:32px; width:32px; }
.warning:before { background-position: -107px -468px; height:32px; width:40px; }
.information:before { background-position: -1px -468px; height:32px; width:32px; }
.success:before { background-position: -35px -468px; height:32px; width:33px; }
.error:before, .warning:before, .information:before, .success:before { content:""; display:block; float:left; position:absolute; top: 11px; left:15px; }

.message-container--closeable { display:none; }

.message-container--closeable .error { width:85%; padding-right:20px; float:left; }
.message-container--closeable .warning { width:85%; padding-right:20px; float:left; }
.message-container--closeable .information { width:85%; padding-right:20px; float:left; }

.message-close { display:block; float:right; border:1px solid #7e7f7f; color:#7e7f7f; font-size:1.125em; padding:3px 6px 1px; }
.message-close:hover, .message-close:focus, .message-close:active { text-decoration: none; background-color:#EBEBEB; }

.message-container--timeout { display:none; }
.message-container--timeout p { float:left; width:60%; padding-right:20px; margin-bottom:0; }
.lt-ie8 .message-container--timeout p { float:none; width:auto; padding-right:0; }

.message-container .before-sprite { margin-bottom: 10px; }






// 900px
@media screen and (max-width:56.25em) {
	.message-container--timeout p { width:50%; }
}

//740px
@media screen and (max-width:46.25em) {
	.message-container--timeout p { float:none; margin-bottom:20px; width:100%; padding-right:0; }
}

//*500px
@media screen and (max-width:31.25em) {
	.message-container--error { padding:10px 10px 10px 60px; }
	.message-container--warning { padding:10px 10px 10px 68px; }
	.message-container--information { padding:10px 10px 10px 60px; }
	.message-container--success { padding:10px 10px 10px 61px; }
}

//410px
@media screen and (max-width:25.625em) {
	.message-container--timeout { padding-left:20px; }
	.message-container--timeout p { margin-left:48px; width:auto; }
}
*/
