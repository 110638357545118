
.claims-coverages-summary {
    display: table;
    margin-top: 15px;
}

.claims-coverages-summary__row {
    display: table-row;
    div {
        display: table-cell;
        padding-bottom: 15px;
        em {
            font-weight: bold;
        }
        &+ div {
            padding-left: 40px;

            @media (max-width: $small) {
                padding-left: 20px;
            }
        }
    }
}
