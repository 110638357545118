$font-path: "./fonts" !default;

@font-face {
    font-family: 'NWPersonalIcons';
    src:    url(#{$font-path}/nw-personal-icons.eot?y7smo9);
    src:    url(#{$font-path}/nw-personal-icons.eot?y7smo9#iefix) format('embedded-opentype'),
        url(#{$font-path}/nw-personal-icons.ttf?y7smo9) format('truetype'),
        url(#{$font-path}/nw-personal-icons.woff?y7smo9) format('woff'),
        url(#{$font-path}/nw-personal-icons.svg?y7smo9#icomoon) format('svg');
    font-weight: normal;
    font-style: normal;
}


[class^="nw-personal-icon-"], [class*=" nw-personal-icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'NWPersonalIcons' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.nw-personal-icon-billing-icon:before {
    content: "\e656";
}
.nw-personal-icon-banking-icon:before {
    content: "\e654";
}
.nw-personal-icon-banking-icon-blue:before {
    content: "\e654";
    color: #005691;
}