//  _____ _ _ _ ____  _____
// |   | | | | |    \|   __|
// | | | | | | |  |  |__   |
// |_|___|_____|____/|_____|
//
// NATIONWIDE DESIGN SYSTEM
// --------------------------------------------------
// Accordion Component
//
// Augments accordion code example from Bootstrap
//

.nw-accordion {
    margin-bottom: 30px;
}

.nw-accordion .panel-group .panel-heading {
    padding:0;
    text-align: none;
}

.nw-accordion .panel-default > .panel-heading {
    a:hover,a:active,a:focus { text-decoration: none; }
}

.nw-accordion .panel-title { margin:0; padding:0; align-content: center }

.nw-accordion .panel-title button {
    width:100%;
    position: relative;
    padding: 10px 15px;
    color: $color-blue;
    font-size: $font-size-base;
    border: none;
    background: inherit;
    &:hover, &:focus {
        background-color:darken($panel-default-heading-bg,7%);
    }

}

.nw-accordion .panel-title .lead{
    position: relative;
    margin: 0 20px;
    display:inline-block;
}


$arrow-size: 7px;
.nw-accordion .panel-title .lead:before { // Down arrow
    position:absolute;
    right: -20px;
    align-content: center;
    content: "";
    display: inline-block;
    top: 20px;
    margin-top:-$arrow-size/2;
    border-top:$arrow-size solid;
    border-bottom-width:0;
    border-bottom-style:none;
    border-left:$arrow-size solid transparent;
    border-right:$arrow-size solid transparent;
}

.nw-accordion .panel-open .panel-title .lead:before { // Up arrow
    margin-top:-($arrow-size+3);
    border-bottom-width:$arrow-size;
    border-bottom-style:solid;
    border-left:$arrow-size solid transparent;
    border-right:$arrow-size solid transparent;
    border-top:$arrow-size solid transparent;
}




@media (max-width: 549px){
    .nw-accordion .panel-title a:before {
        position:absolute;
        left: 25px;
    }
}


