
// Track Claim Icons
.claims-track-claim-content__link-box.icon--claim:before,
.claims-track-claim-content__text-box.icon--claim:before {
    content: '';
    background: url(/assets/images/claims/icon--claim.svg) no-repeat center center $blue-icon;
    background-size: 66%;
}
.claims-track-claim-content__link-box.icon--contact:before,
.claims-track-claim-content__text-box.icon--contact:before {
    content: '';
    background: url(/assets/images/claims/icon--contact.svg) no-repeat center 7px $blue-icon;
    background-size: 89%;
}
.claims-track-claim-content__link-box.icon--payments:before,
.claims-track-claim-content__text-box.icon--payments:before {
    content: '';
    background: url(/assets/images/claims/icon--payments.svg) no-repeat center center $blue-icon;
    background-size: 60%;
}
.claims-track-claim-content__link-box.icon--repair:before,
.claims-track-claim-content__text-box.icon--repair:before {
    content: '';
    background: url(/assets/images/claims/icon--repair.svg) no-repeat 0 center $blue-icon;
    background-size: 83%;
}
.claims-track-claim-content__link-box.icon--property-repair:before,
.claims-track-claim-content__text-box.icon--property-repair:before {
    content: '';
    background: url(/assets/images/claims/icon--prop-repair.svg) no-repeat center center $blue-icon;
    background-size: 64%;
}
.claims-track-claim-content__link-box.icon--estimate-appointment:before,
.claims-track-claim-content__text-box.icon--estimate-appointment:before {
    content: '';
    background: url(/assets/images/claims/icon--calendar.svg) no-repeat center center $orange-light;
    background-size: 61%;
}
.claims-track-claim-content__link-box.icon--find-next-car:before,
.claims-track-claim-content__text-box.icon--find-next-car:before {
    content: '';
    background: url(/assets/images/claims/icon--find-next-car.svg) no-repeat center 8px $blue-icon;
    background-size: 72%;

    @media (max-width: $small) {
        background: url(/assets/images/claims/icon--find-next-car.svg) no-repeat center 6px $blue-icon;
        background-size: 72%;
    }
}
.claims-track-claim-content__link-box.icon--auto:before,
.claims-track-claim-content__text-box.icon--auto:before {
    content: '';
    background: url(/assets/images/claims/icon--auto.svg) no-repeat center center $blue-icon;
    background-size: 72%;
}
.claims-track-claim-content__link-box.icon--claim-update:before,
.claims-track-claim-content__text-box.icon--claim-update:before {
    content: '';
    background: url(/assets/images/claims/icon--claims-update.svg) no-repeat center center $orange-light;
    background-size: 44%;
}
.claims-track-claim-content__link-box.icon--claim-repair-estimate-report:before,
.claims-track-claim-content__text-box.icon--claim-repair-estimate-report:before {
    content: '';
    background: url(/assets/images/claims/icon--claims-repair-estimate-report.svg) no-repeat center center $blue-icon;
    background-size: 44%;
}

// Claim Loss Causes Icons
// Auto
.claims-track-claim-content__link-box.icon--multicarcollision:before,
.claims-track-claim-content__text-box.icon--multicarcollision:before {
    content: '';
    background: url(/assets/images/claims/icon--multicarcollision.svg) no-repeat center center $blue-icon;
    background-size: 73%;
}
.claims-track-claim-content__link-box.icon--contact-fixed-object:before,
.claims-track-claim-content__text-box.icon--contact-fixed-object:before {
    content: '';
    background: url(/assets/images/claims/icon--contact-fixed-object.svg) no-repeat center 10px $blue-icon;
    background-size: 73%;
}
.claims-track-claim-content__link-box.icon--pedestrian-accident:before,
.claims-track-claim-content__text-box.icon--pedestrian-accident:before {
    content: '';
    background: url(/assets/images/claims/icon--pedestrian-accident.svg) no-repeat 7px center $blue-icon;
    background-size: 60%;
}
.claims-track-claim-content__link-box.icon--animal:before,
.claims-track-claim-content__text-box.icon--animal:before {
    content: '';
    background: url(/assets/images/claims/icon--animal.svg) no-repeat 7px center $blue-icon;
    background-size: 73%;
}
.claims-track-claim-content__link-box.icon--contact-object:before,
.claims-track-claim-content__text-box.icon--contact-object:before {
    content: '';
    background: url(/assets/images/claims/icon--contact-object.svg) no-repeat center center $blue-icon;
    background-size: 73%;
}
.claims-track-claim-content__link-box.icon--business-auto-claim-loss-cause:before,
.claims-track-claim-content__text-box.icon--business-auto-claim-loss-cause:before {
    content: '';
    background: url(/assets/images/claims/icon--business-auto-claim-loss-cause.svg) no-repeat center center $blue-icon;
    background-size: 73%;
}
.claims-track-claim-content__link-box.icon--earth-movement:before,
.claims-track-claim-content__text-box.icon--earth-movement:before {
    content: '';
    background: url(/assets/images/claims/icon--earth-movement.svg) no-repeat center center $blue-icon;
    background-size: 51%;
}
.claims-track-claim-content__link-box.icon--object-hit-vehicle:before,
.claims-track-claim-content__text-box.icon--object-hit-vehicle:before {
    content: '';
    background: url(/assets/images/claims/icon--object-hit-vehicle.svg) no-repeat center center $blue-icon;
    background-size: 62%;
}
.claims-track-claim-content__link-box.icon--fire:before,
.claims-track-claim-content__text-box.icon--fire:before {
    content: '';
    background: url(/assets/images/claims/icon--fire.svg) no-repeat center center $blue-icon;
    background-size: 66%;
}
.claims-track-claim-content__link-box.icon--glass:before,
.claims-track-claim-content__text-box.icon--glass:before {
    content: '';
    background: url(/assets/images/claims/icon--glass.svg) no-repeat center center $blue-icon;
    background-size: 71%;
}
.claims-track-claim-content__link-box.icon--injury:before,
.claims-track-claim-content__text-box.icon--injury:before {
    content: '';
    background: url(/assets/images/claims/icon--injury.svg) no-repeat 7px center $blue-icon;
    background-size: 60%;
}
.claims-track-claim-content__link-box.icon--roadside:before,
.claims-track-claim-content__text-box.icon--roadside:before {
    content: '';
    background: url(/assets/images/claims/icon--roadside.svg) no-repeat center center $blue-icon;
    background-size: 90%;
}
.claims-track-claim-content__link-box.icon--theft:before,
.claims-track-claim-content__text-box.icon--theft:before {
    content: '';
    background: url(/assets/images/claims/icon--theft.svg) no-repeat center center $blue-icon;
    background-size: 62%;
}
.claims-track-claim-content__link-box.icon--accident:before,
.claims-track-claim-content__text-box.icon--accident:before {
    content: '';
    background: url(/assets/images/claims/icon--accident.svg) no-repeat center center $blue-icon;
    background-size: 65%;
}
.claims-track-claim-content__link-box.icon--vandalism:before,
.claims-track-claim-content__text-box.icon--vandalism:before {
    content: '';
    background: url(/assets/images/claims/icon--vandalism.svg) no-repeat 8px center $blue-icon;
    background-size: 75%;
}
.claims-track-claim-content__link-box.icon--weather:before,
.claims-track-claim-content__text-box.icon--weather:before {
    content: '';
    background: url(/assets/images/claims/icon--weather.svg) no-repeat center center $blue-icon;
    background-size: 69%;
}
.claims-track-claim-content__link-box.icon--non-accidental-damage:before,
.claims-track-claim-content__text-box.icon--non-accidental-damage:before {
    content: '';
    background: url(/assets/images/claims/icon--non-accidental-damage.svg) no-repeat center center $blue-icon;
    background-size: 50%;
}
.claims-track-claim-content__link-box.icon--singlecarcollision:before,
.claims-track-claim-content__text-box.icon--singlecarcollision:before {
    content: '';
    background: url(/assets/images/claims/icon--singlecarcollision.svg) no-repeat center center $blue-icon;
    background-size: 79%;
}
.claims-track-claim-content__link-box.icon--hail:before,
.claims-track-claim-content__text-box.icon--hail:before {
    content: '';
    background: url(/assets/images/claims/icon--hail.svg) no-repeat center center $blue-icon;
    background-size: 72%;
}
.claims-track-claim-content__link-box.icon--flood:before,
.claims-track-claim-content__text-box.icon--flood:before {
    content: '';
    background: url(/assets/images/claims/icon--flood.svg) no-repeat center center $blue-icon;
    background-size: 80%;
}
.claims-track-claim-content__link-box.icon--partial-theft:before,
.claims-track-claim-content__text-box.icon--partial-theft:before {
    content: '';
    background: url(/assets/images/claims/icon--partial-theft.svg) no-repeat center center $blue-icon;
    background-size: 64%;
}
.claims-track-claim-content__link-box.icon--bicycle:before,
.claims-track-claim-content__text-box.icon--bicycle:before {
    content: '';
    background: url(/assets/images/claims/icon--bicycle.svg) no-repeat center center $blue-icon;
    background-size: 80%;
}
.claims-track-claim-content__link-box.icon--collision-all-other:before,
.claims-track-claim-content__text-box.icon--collision-all-other:before {
    content: '';
    background: url(/assets/images/claims/icon--collision-all-other.svg) no-repeat center center $blue-icon;
    background-size: 82%;
}
.claims-track-claim-content__link-box.icon--comprehensive-all-other:before,
.claims-track-claim-content__text-box.icon--comprehensive-all-other:before {
    content: '';
    background: url(/assets/images/claims/icon--comprehensive-all-other.svg) no-repeat center center $blue-icon;
    background-size: 77%;
}
.claims-track-claim-content__link-box.icon--injury-around-vehicle:before,
.claims-track-claim-content__text-box.icon--injury-around-vehicle:before {
    content: '';
    background: url(/assets/images/claims/icon--injury-around-vehicle.svg) no-repeat center center $blue-icon;
    background-size: 70%;
}
// Property
.claims-track-claim-content__link-box.icon--hail-property:before,
.claims-track-claim-content__text-box.icon--hail-property:before {
    content: '';
    background: url(/assets/images/claims/icon--hail-property.svg) no-repeat center center $blue-icon;
    background-size:72%;
}
.claims-track-claim-content__link-box.icon--wind-damage:before,
.claims-track-claim-content__text-box.icon--wind-damage:before {
    content: '';
    background: url(/assets/images/claims/icon--wind-damage.svg) no-repeat center center $blue-icon;
    background-size: 80%;
}
.claims-track-claim-content__link-box.icon--water-notweather:before,
.claims-track-claim-content__text-box.icon--water-notweather:before {
    content: '';
    background: url(/assets/images/claims/icon--water-notweather.svg) no-repeat center center $blue-icon;
    background-size: 60%;
}
.claims-track-claim-content__link-box.icon--water-weather:before,
.claims-track-claim-content__text-box.icon--water-weather:before {
    content: '';
    background: url(/assets/images/claims/icon--water-weather.svg) no-repeat center center $blue-icon;
    background-size: 62%;
}
.claims-track-claim-content__link-box.icon--snow-weight:before,
.claims-track-claim-content__text-box.icon--snow-weight:before {
    content: '';
    background: url(/assets/images/claims/icon--snow-weight.svg) no-repeat center center $blue-icon;
    background-size: 66%;
}
.claims-track-claim-content__link-box.icon--freezing:before,
.claims-track-claim-content__text-box.icon--freezing:before {
    content: '';
    background: url(/assets/images/claims/icon--freezing.svg) no-repeat center center $blue-icon;
    background-size: 70%;
}
.claims-track-claim-content__link-box.icon--theft-home:before,
.claims-track-claim-content__text-box.icon--theft-home:before {
    content: '';
    background: url(/assets/images/claims/icon--theft-items-home.svg) no-repeat center center $blue-icon;
    background-size: 82%;
}
.claims-track-claim-content__link-box.icon--theft-vehicle:before,
.claims-track-claim-content__text-box.icon--theft-vehicle:before {
    content: '';
    background: url(/assets/images/claims/icon--theft-items-vehicle.svg) no-repeat center center $blue-icon;
    background-size: 72%;
}
.claims-track-claim-content__link-box.icon--lost-item:before,
.claims-track-claim-content__text-box.icon--lost-item:before {
    content: '';
    background: url(/assets/images/claims/icon--lost-item.svg) no-repeat center center $blue-icon;
    background-size: 68%;
}
.claims-track-claim-content__link-box.icon--id-theft:before,
.claims-track-claim-content__text-box.icon--id-theft:before {
    content: '';
    background: url(/assets/images/claims/icon--id-theft.svg) no-repeat center center $blue-icon;
    background-size: 72%;
}
.claims-track-claim-content__link-box.icon--vandalism-mischief:before,
.claims-track-claim-content__text-box.icon--vandalism-mischief:before {
    content: '';
    background: url(/assets/images/claims/icon--vandalism-mischief.svg) no-repeat center center $blue-icon;
    background-size: 60%;
}
.claims-track-claim-content__link-box.icon--lightning:before,
.claims-track-claim-content__text-box.icon--lightning:before {
    content: '';
    background: url(/assets/images/claims/icon--lightning.svg) no-repeat center center $blue-icon;
    background-size: 68%;
}
.claims-track-claim-content__link-box.icon--fire-property:before,
.claims-track-claim-content__text-box.icon--fire-property:before {
    content: '';
    background: url(/assets/images/claims/icon--fire-property.svg) no-repeat center center $blue-icon;
    background-size: 75%;
}
.claims-track-claim-content__link-box.icon--smoke:before,
.claims-track-claim-content__text-box.icon--smoke:before {
    content: '';
    background: url(/assets/images/claims/icon--smoke.svg) no-repeat center center $blue-icon;
    background-size: 68%;
}
.claims-track-claim-content__link-box.icon--lib-med-payments:before,
.claims-track-claim-content__text-box.icon--lib-med-payments:before {
    content: '';
    background: url(/assets/images/claims/icon--lib-med-payments.svg) no-repeat center center $blue-icon;
    background-size: 68%;
}
.claims-track-claim-content__link-box.icon--damage-vwa:before,
.claims-track-claim-content__text-box.icon--damage-vwa:before {
    content: '';
    background: url(/assets/images/claims/icon--damage-vwa.svg) no-repeat center center $blue-icon;
    background-size: 60%;
}
.claims-track-claim-content__link-box.icon--equipment:before,
.claims-track-claim-content__text-box.icon--equipment:before {
    content: '';
    background: url(/assets/images/claims/icon--equipment.svg) no-repeat center center $blue-icon;
    background-size: 64%;
}
.claims-track-claim-content__link-box.icon--prop-breakage:before,
.claims-track-claim-content__text-box.icon--prop-breakage:before {
  content: '';
  background: url(/assets/images/claims/icon--prop-breakage.svg) no-repeat center center $blue-icon;
  background-size: 64%;
}
.claims-track-claim-content__link-box.icon--earthquake:before,
.claims-track-claim-content__text-box.icon--earthquake:before {
    content: '';
    background: url(/assets/images/claims/icon--earthquake.svg) no-repeat center center $blue-icon;
    background-size: 60%;
}
.claims-track-claim-content__link-box.icon--other-phys-damage:before,
.claims-track-claim-content__text-box.icon--other-phys-damage:before {
    content: '';
    background: url(/assets/images/claims/icon--other-phys-damage.svg) no-repeat center center $blue-icon;
    background-size: 73%;
}
.claims-track-claim-content__link-box.icon--prop-collapse:before,
.claims-track-claim-content__text-box.icon--prop-collapse:before {
    content: '';
    background: url(/assets/images/claims/icon--prop-collapse.svg) no-repeat center center $blue-icon;
    background-size: 68%;
}
.claims-track-claim-content__link-box.icon--prop-death:before,
.claims-track-claim-content__text-box.icon--prop-death:before {
    content: '';
    background: url(/assets/images/claims/icon--prop-death.svg) no-repeat center center $blue-icon;
    background-size: 60%;
}
.claims-track-claim-content__link-box.icon--prop-explosion:before,
.claims-track-claim-content__text-box.icon--prop-explosion:before {
    content: '';
    background: url(/assets/images/claims/icon--prop-explosion.svg) no-repeat center center $blue-icon;
    background-size: 78%;
}
.claims-track-claim-content__link-box.icon--prop-liability:before,
.claims-track-claim-content__text-box.icon--prop-liability:before {
  content: '';
  background: url(/assets/images/claims/icon--prop-liability.svg) no-repeat center center $blue-icon;
  background-size: 68%;
}
.claims-track-claim-content__link-box.icon--foundation:before,
.claims-track-claim-content__text-box.icon--foundation:before {
    content: '';
    background: url(/assets/images/claims/icon--foundation.svg) no-repeat center center $blue-icon;
    background-size: 68%;
}
.claims-track-claim-content__link-box.icon--home-daycare:before,
.claims-track-claim-content__text-box.icon--home-daycare:before {
    content: '';
    background: url(/assets/images/claims/icon--homedaycare.svg) no-repeat center center $blue-icon;
    background-size: 68%;
}
.claims-track-claim-content__link-box.icon--prop-lead:before,
.claims-track-claim-content__text-box.icon--prop-lead:before {
    content: '';
    background: url(/assets/images/claims/icon--prop-lead.svg) no-repeat center center $blue-icon;
    background-size: 46%;
}
.claims-track-claim-content__link-box.icon--riot:before,
.claims-track-claim-content__text-box.icon--riot:before {
    content: '';
    background: url(/assets/images/claims/icon--riot.svg) no-repeat center center $blue-icon;
    background-size: 66%;
}
.claims-track-claim-content__link-box.icon--wallet:before,
.claims-track-claim-content__text-box.icon--wallet:before {
    content: '';
    background: url(/assets/images/claims/icon--wallet.svg) no-repeat center center $blue-icon;
    background-size: 60%;
}

// Recent Update Section
.claims-track-claim-recent-update-headline {
    display: inline-block;
    font-size: 1.13em;
    font-weight: bold;
    margin-bottom: 16px;

    @media (max-width: $small) {
        margin-bottom: 5px;
    }
}

.claims-track-claim-recent-update-see-details {
    display: inline-block;
    margin-left: 5px;

    @media (max-width: $small) {
        display: block;
        margin-left: 0;
        margin-bottom: 16px;
    }
}

// Claim Representative Section
.claims-track-claim-rep {
    .claims-track-claim-rep__left-panel,
    .claims-track-claim-rep__right-panel {
        display: inline-block;
        vertical-align: top;

        @media (max-width: 35em) {
            display: block;
        }

        p {
            margin-top: 0;
        }
        a {
            text-decoration: none;
        }
    }
    .claims-track-claim-rep__left-panel {
        margin-right: 20px;
    }
}

// My Claim Icons
.claims-info-menu__link--details {
    &.icon--photo:before {
        content: '';
        background: url(/assets/images/claims/icon--photo.svg) no-repeat center center $blue-icon;
        background-size: 56%;
    }
    &.icon--coverages-snapshot:before {
        content: '';
        background: url(/assets/images/claims/icon--coverages-snapshot.svg) no-repeat center center $blue-icon;
        background-size: 45%;
    }
}
.claims-info-menu__link--contacts {
    &.icon--contact:before {
        content: '';
        background: url(/assets/images/claims/icon--contact.svg) no-repeat center 7px $blue-icon;
        background-size: 89%;
    }
}
.claims-info-menu__link--estimates {
    &.icon--repair:before {
        content: '';
        background: url(/assets/images/claims/icon--repair.svg) no-repeat 0 center $blue-icon;
        background-size: 83%;
    }
}
.claims-info-menu__link--payments {
    &.icon--payments:before {
        content: '';
        background: url(/assets/images/claims/icon--payments.svg) no-repeat center center $blue-icon;
        background-size: 61%;
    }
}
.claims-info-menu__link--wallet {
    &.icon--wallet:before {
        content: '';
        background: url(/assets/images/claims/icon--wallet.svg) no-repeat center center $blue-icon;
        background-size: 61%;
    }
}
.claims-info-menu__link--faq {
    &.icon--questionmark:before {
        content: '';
        background: url(/assets/images/claims/icon--questionmark.svg) no-repeat center center $blue-icon;
        background-size: 60%;
    }
}

// General
.claims-track-claim-content__link-box,
.claims-track-claim-content__text-box {
    &[class^="icon--"]:before,
    &[class*="icon--"]:before {
//        background: $blue-icon;
    }
}
.claims-track-claim-content__link-box.link-arrow-gray:after,
.claims-status-text-box.link-arrow-gray:after,
.claims-track-claim-content__link-box.link-arrow-orange:after,
.claims-status-text-box.link-arrow-orange:after{
    position: absolute;
    top: 0;
    bottom: 0;
    right: 10px;
    height: 0;
    margin: auto 0;
}

.claims-track-claim-content__link-box,
.claims-track-claim-content__text-box {
    position: relative;
    display: block;
    min-height: 80px;
    width: 100%;
    max-width: 900px;
    margin-bottom: 5px;
    padding: 15px 26px 15px 15px;
    font-family: 'proxima-nova', Arial, sans-serif;
    font-size: 1.125em;
    line-height: 18px;
    color: $gray-dark-1;
    background: rgb(255,255,255);
    border: 1px solid #e1e1e1;
    border-bottom: 2px solid #e1e1e1;
    &[class^="icon--"],
    &[class*="icon--"] {
        padding: 15px 26px 15px 74px;

        @media (max-width: $small) {
            padding: 15px 26px 15px 59px;
        }
    }
    .button-link {
        text-decoration: none;
    }
    h2 {
        font-size: 1.13em;
        font-weight: bold;
        text-transform: none;
        color: $gray-dark-1;
    }
    h3 {
        font-weight: bold;
    }
    p {
        font-size: 1.125em;
        line-height: 1.4em;
    }
    a {
        text-decoration: none;
        &:hover {
            text-decoration: underline;
        }
    }
    em {
        font-weight: bold;
        font-style: normal;
    }
    ol, ul {
        padding: 0 0 0 22px;
        li {
            padding: 8px 0;
        }
    }
    dl {
        margin: 0 0 20px;
        dt {
            padding-bottom: 5px;
        }
        dd {
            font-weight: 400;
            line-height: 1.25em;
            margin: 0;
            padding-bottom: 5px;
        }
    }
}
.claims-track-claim-content__text-box {
    padding: 15px;
}
.claims-track-claim-content__link-box {
    text-decoration: none;
    &:hover {
        text-decoration: underline;
    }
    &:hover::before { // IE Override
        text-decoration: underline;
    }
    &:hover::before { // IE Override
        text-decoration: none;
    }
}
.claims-track-claim-content__link-box:before,
.claims-track-claim-content__text-box:before {
    position: absolute;
    top: 16px;
    left: 12px;
    margin: auto 0;
    display: block;
    height: 50px;
    width: 50px;
    border-radius: 999px;

    @media (max-width: $small) {
        height: 35px;
        width: 35px;
    }
}
.claims-track-claim-content__link-box.link-arrow-gray:after,
.claims-track-claim-content__text-box.link-arrow-gray:after,
.claims-track-claim-content__link-box.link-arrow-orange:after,
.claims-track-claim-content__text-box.link-arrow-orange:after {
    top: 0;
    bottom: 0;
    right: 10px;
    height: 0;
    margin: auto 0;
}

// My Claim
.claims-info-menu {
    margin: 0;
    padding: 0;
}

.claims-info-menu__btn {
    display: block;
}
[class^="claims-info-menu__link--"],
[class*="claims-info-menu__link--"] {
    position: relative;
    display: block;
    line-height: 80px;
    width: 100%;
    max-width: 900px;
    margin-bottom: 5px;
    padding: 0 26px 0 74px;
    font-family: 'proxima-nova', Arial, sans-serif;
    font-size: 1.13em;
    text-decoration: none;
    color: $gray-dark-1;
    background: rgb(255,255,255);
    border: 1px solid #e1e1e1;

    &:hover::before { // IE Override
        text-decoration: underline;
    }
    &:hover::before { // IE Override
        text-decoration: none;
    }

    @media (max-width: $small) {
        padding: 0 26px 0 59px;
    }
}
[class^="claims-info-menu__link--"]:before,
[class*="claims-info-menu__link--"]:before {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 10px;
    margin: auto 0;
    display: block;
    height: 50px;
    width: 50px;
    border-radius: 999px;

    @media (max-width: $small) {
        height: 35px;
        width: 35px;
    }
}
[class^="claims-info-menu__link--"].link-arrow-gray:after,
[class*="claims-info-menu__link--"].link-arrow-gray:after,
[class^="claims-info-menu__link--"].link-arrow-orange:after,
[class*="claims-info-menu__link--"].link-arrow-orange:after {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 10px;
    height: 0;
    margin: auto 0;
}

// FAQ Link
.claims-faq-link {
    position: relative;
    display: block;
    min-height: 80px;
    width: 100%;
    max-width: 900px;
    margin-bottom: 5px;
    padding: 0 26px 0 74px;
    font-family: 'proxima-nova', Arial, sans-serif;
    font-size: 1.17em;
    font-weight: bold;
    line-height: 80px;
    text-decoration: none;
    color: rgb(51, 51, 51);
    background: rgb(255,255,255);
    border: 1px solid #e1e1e1;
    border-bottom: 2px solid #e1e1e1;
    &:before {
        position: absolute;
        top: 16px;
        left: 12px;
        margin: auto 0;
        display: block;
        height: 50px;
        width: 50px;
        border-radius: 999px;

        @media (max-width: $small) {
            top: 0;
            bottom: 0;
            height: 35px;
            width: 35px;
            line-height: 35px;
        }
    }

    &:hover::before { // IE Override
        text-decoration: underline;
    }
    &:hover::before { // IE Override
        text-decoration: none;
    }

    @media (max-width: $small) {
        padding: 0 26px 0 59px;
    }

    &.icon--questionmark:before, {
        content: '';
        background: url(/assets/images/claims/icon--questionmark.svg) no-repeat center center $blue-icon;
        background-size: 60%;
    }

    &.link-arrow-orange:after {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 10px;
        height: 0;
        margin: auto 0;
    }
}

// Misc
.claims-track-claim-content__link-box.icon--questionmark:before,
.claims-track-claim-content__text-box.icon--questionmark:before, {
    content: '';
    background: url(/assets/images/claims/icon--questionmark.svg) no-repeat center center $blue-icon;
    background-size: 60%;
}
.claims-track-claim-content__link-box.icon--wind:before,
.claims-track-claim-content__text-box.icon--wind:before, {
    content: '';
    background: url(/assets/images/claims/icon--wind.svg) no-repeat center center $blue-icon;
    background-size: 68%;
}
.claims-track-claim-content__link-box.icon--waterbackup:before,
.claims-track-claim-content__text-box.icon--waterbackup:before, {
    font-size: 2.25rem;

    content: '';
    background: url(/assets/images/claims/icon--waterbackup.svg) no-repeat center center $blue-icon;
    background-size: 68%;

    @media (max-width: $small) {
        font-size: 1.6rem;
    }
}


