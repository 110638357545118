
[class^="claims-file-claim-prop-damage__label-group--"],
[class*="claims-file-claim-prop-damage__label-group--"] {
    label {
        position: relative;
        display: flex;
        align-items: center;
        height: 60px;
        padding-left: 70px;
        border: 1px dotted #fff;
        &:before {
            content: '';
            display: block;
            position: absolute;
            top: -1px;
            left: 0;
            height: 60px;
            width: 60px;
            border-radius: 999px;
        }
    }
    input[type="checkbox"] {
        opacity: 0;
        margin-left: 0;
        &:focus + label {
            outline: thin dotted;
            outline: 5px auto -webkit-focus-ring-color;
            outline-offset: -2px;
        }
    }
}

.claims-file-claim-prop-damage__label-group--home {
    label:before {
        background: url(/assets/images/claims/icon--prop-home-damage.svg) no-repeat center center rgb(255,255,255);
    }
    input[type="checkbox"]:checked + label:before {
        background: url(/assets/images/claims/icon--prop-home-damage-selected.svg) no-repeat center center rgb(255,255,255);
    }
}
.claims-file-claim-prop-damage__label-group--other-structure {
    label:before {
        background: url(/assets/images/claims/icon--prop-other-structure-damage.svg) no-repeat center center rgb(255,255,255);
    }
    input[type="checkbox"]:checked + label:before {
        background: url(/assets/images/claims/icon--prop-other-structure-damage-selected.svg) no-repeat center center rgb(255,255,255);
    }
}
.claims-file-claim-prop-damage__label-group--personal-property {
    label:before {
        background: url(/assets/images/claims/icon--prop-personal-property.svg) no-repeat center center rgb(255,255,255);
    }
    input[type="checkbox"]:checked + label:before {
        background: url(/assets/images/claims/icon--prop-personal-property-selected.svg) no-repeat center center rgb(255,255,255);
    }
}

.claims-file-claim-prop-damage__label-group--id-theft {
    label:before {
        background: url(/assets/images/claims/icon--prop-id-theft.svg) no-repeat center center rgb(255,255,255);
    }
    input[type="checkbox"]:checked + label:before {
        background: url(/assets/images/claims/icon--prop-id-theft-selected.svg) no-repeat center center rgb(255,255,255);
    }
}

.claims-file-claim-prop-damage__label-group--veh-boat {
   label:before {
        background: url(/assets/images/claims/icon--prop-veh-boat.svg) no-repeat center center rgb(255,255,255);
    }
    input[type="checkbox"]:checked + label:before {
        background: url(/assets/images/claims/icon--prop-veh-boat-selected.svg) no-repeat center center rgb(255,255,255);
    }
}

.claims-file-claim-prop-damage__label-group--food-spoil {
    label:before {
        background: url(/assets/images/claims/icon--prop-food-spoil-damage.svg) no-repeat center center rgb(255,255,255);
    }
    input[type="checkbox"]:checked + label:before {
        background: url(/assets/images/claims/icon--prop-food-spoil-damage-selected.svg) no-repeat center center rgb(255,255,255);
    }
}

.claims-file-claim-prop-damage__label-group--any-other-damage {
     label:before {
        background: url(/assets/images/claims/icon--prop-any-other-damage.svg) no-repeat center center rgb(255,255,255);
    }
    input[type="checkbox"]:checked + label:before {
        background: url(/assets/images/claims/icon--prop-any-other-damage-selected.svg) no-repeat center center rgb(255,255,255);
    }
}


//// Titles

// Override for global h2
.row [class^="claims-file-claim-prop-damage__title--"],
.row [class*="claims-file-claim-prop-damage__title--"] {
    margin: 15px 15px 10px;
}
// End Override for global h2

[class^="claims-file-claim-prop-damage__title--"],
[class*="claims-file-claim-prop-damage__title--"] {
    position: relative;
    display: flex;
    height: 65px;
    margin: 15px 15px 10px;
    padding-left: 75px;
    align-items: center;
    &:before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        height: 65px;
        width: 65px;
    }
}

.claims-file-claim-prop-damage__title--home:before {
    background: url(/assets/images/claims/icon--prop-home-damage.svg) no-repeat center center transparent;
}
.claims-file-claim-prop-damage__title--other:before {
    background: url(/assets/images/claims/icon--prop-other-structure-damage.svg) no-repeat center center transparent;
}
.claims-file-claim-prop-damage__title--personal-property:before {
    background: url(/assets/images/claims/icon--prop-personal-property.svg) no-repeat center center transparent;
}
.claims-file-claim-prop-damage__title--id-theft:before {
    background: url(/assets/images/claims/icon--prop-id-theft.svg) no-repeat center center transparent;
}
.claims-file-claim-prop-damage__title--veh-boat:before {
    background: url(/assets/images/claims/icon--prop-veh-boat.svg) no-repeat center center transparent;
}
.claims-file-claim-prop-damage__title--food-spoil:before {
    background: url(/assets/images/claims/icon--prop-food-spoil-damage.svg) no-repeat center center transparent;
}
.claims-file-claim-prop-damage__title--any-other-damage:before {
    background: url(/assets/images/claims/icon--prop-any-other-damage.svg) no-repeat center center transparent;
}
