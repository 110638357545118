iframe-component{
    display: none;
}

.utility-container-unauth {
    border: 1px solid #cccccc;
    border-radius: 4px;
    float: right;
    font-size: 0.85em;
    margin: 18px 20px 15px;
}
.utility-link-unauth {
    background-color: #f6f6f6;
    border-color: #cccccc;
    display: block;
    height: 44px;
    line-height: 44px;
    min-width: 96px;
    padding: 0 10px;
}
.utility-link-unauth:hover, .utility-link-unauth:focus, .utility-link-unauth:active {
    text-decoration: none;
}

@media screen and (max-width: 32.5em) {
    .utility-container-unauth {
        margin: 10px 5px 0 0;
        font-size: .7em;
    }
    .utility-link-unauth {
        height: 34px;
        line-height: 34px;
        min-width: 0;
    }
    .bolt-button-wc--size-md.bolt-button {
        font-size: 1.125rem;
        padding: .75rem .2rem;
        line-height: 1.125rem;
    }
}
