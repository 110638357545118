@include screensize($screen-sm) {
    .nw-button-container {
        .btn.pull-left {
            display:inline;
            width: auto;
            float:right;
            margin: 0 10px 0 0;
        }
    }    
}
