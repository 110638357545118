//  _____ _ _ _ ____  _____ 
// |   | | | | |    \|   __|
// | | | | | | |  |  |__   |
// |_|___|_____|____/|_____|
//
// NATIONWIDE DESIGN SYSTEM
// --------------------------------------------------
// Bootstrap Tables
//



.table > thead th {
    background-color: #666;
    color:#fff;
}

.table > thead a {
    color:#fff; 
    text-decoration: underline;
    
    &:hover {
        color: darken(#fff, 15%);   
    }
}

