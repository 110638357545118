@media print {
    a[href]::after {
        content: "" !important;
    }
    .utility-container-unauth {
        display: none;
    }
    .breadcrumb {
        display: none;
    }
    .progress {
        display: none;
    }
    .nw-progress {
        display: none;
    }
    .nw-breadcrumbs {
        display: none;
    }
    .main-footer__contact {
        width: 100%;
    }
    .button-container {
        display: none !important;
    }
    .nw-button-container {
        display: none !important;
    }
    .btn {
        display: none !important;
    }
    .alert-danger {
        background-color: unset !important;
        border-color: #ebccd1 !important;
        box-shadow: 0 0 0 1000px #f2dede inset !important;
        color: #a94442 !important;
        display: block;
    }
    .tabs{
        display: none;
    }

    .main-header {
        .utility-container{
            display: none;
        }
    }
    .global-container {
        width: 900px !important;
    }

    @page {
        size: 220mm 290mm;
    }
}
