//  _____ _ _ _ ____  _____
// |   | | | | |    \|   __|
// | | | | | | |  |  |__   |
// |_|___|_____|____/|_____|
//
// NATIONWIDE DESIGN SYSTEM
// --------------------------------------------------
// Buttons
//

.nw-btn-inline {
    color: $btn-inline-color;
    background: $btn-inline-bg;
    border-color: $btn-inline-border;
    font-weight:200;

    &:hover,
    &:focus {
        color: darken($btn-inline-color,25%);
        border-color: darken($btn-inline-border, 25%);
        background-color:rgba(0,0,0,.12);
    }

    &:active {
        background: lighten($btn-inline-border,55%);
    }

    &:disabled {
        background:none;
        border-color: $btn-inline-border;
        color: $btn-inline-color;
    }
}

.nw-btn-inline-inverse {
    color: $btn-inline-light-color;
    background: $btn-inline-light-bg;
    border-color: $btn-inline-light-border;
    font-weight:200;

    &:hover,
    &:focus {
        color: lighten($btn-inline-light-color,100%);
        border-color: lighten($btn-inline-light-border, 100%);
        background-color:rgba(0,0,0,.12);
    }

    &:disabled {
        background:none;
        border-color: $btn-inline-light-border;
        color: $btn-inline-light-color;
    }
}


// Radio toggle button group
.nw-button-toggle {
    display:block;
}

.nw-button-toggle .btn-default {
    background:#fff;

    &:hover {
        background: $btn-default-bg;
    }
}

.nw-button-toggle .btn-default.active {
    background-color: $brand-primary;
    font-weight: 700;
    color:#fff;
}

.nw-button-toggle .btn-default.active:hover { color:#fff; }



// Button container with built-in stacking
.nw-button-container {
    @include clearfix();
    padding:20px 120px;

    .btn {
        width:100%;
        display:block;
        margin-top: 12px;
    }
}

@include screensize($screen-sm) {
    .nw-button-container {
        .btn {
            display:inline;
            width: auto;
            float:right;
            margin: 0 0 0 10px;
        }
    }
}



/*---SUPPORTING STYLES---*/

.button-container {
    width: 100%;
    border-top: 1px solid #d6d6d6;
    padding: 20px;
    background-color: #cce1ef;
    margin-bottom: 20px
}

.ie7 .button-container {
    padding: 20px 0
}

.action-buttons-container {
    clear: left
}

.button-instructions {
    float: right
}

.button {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    border: 0;
    -webkit-box-shadow: 0 1px 1px 0 #000333;
    -moz-box-shadow: 0 1px 1px 0 #000333;
    box-shadow: 0 1px 1px 0 #000333;
    cursor: pointer;
    font-size: 100%;
    min-width: 70px;
    padding: 10px 25px;
    text-decoration: none;
    text-align: center;
    overflow: visible;
    font-family: 'proxima-nova', Arial, sans-serif;
    font-size: 1.125em;
    font-weight: bold
}

.lt-ie9 .button {
    padding-top: 8px;
    padding-bottom: 9px;
    border: 1px solid #5e5e5e
}

.button:active {
    -webkit-box-shadow: 0 -1px -1px 0 #000333;
    -moz-box-shadow: 0 -1px -1px 0 #000333;
    box-shadow: 0 -1px -1px 0 #000333;
    transform: translate(1px, 1px);
    -webkit-transform: translate(1px, 1px)
}

.button-link {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    -webkit-box-shadow: 0 1px 1px 0 #000333;
    -moz-box-shadow: 0 1px 1px 0 #000333;
    box-shadow: 0 1px 1px 0 #000333;
    cursor: pointer;
    min-width: 70px;
    padding: 10px 25px;
    text-align: center;
    display: inline-block;
    text-decoration: none;
    font-size: 1.125em;
    font-weight: bold
}

.lt-ie9 .button-link {
    padding-top: 10px;
    padding-bottom: 10px;
    border: 1px solid #5e5e5e
}

.button-link:hover,
.button-link:focus {
    text-decoration: none
}

.button-link:active {
    -webkit-box-shadow: 0 -1px -1px 0 #000333;
    -moz-box-shadow: 0 -1px -1px 0 #000333;
    box-shadow: 0 -1px -1px 0 #000333;
    transform: translate(1px, 1px);
    -webkit-transform: translate(1px, 1px)
}

.button-link--text-secondary {
    display: inline-block;
    margin-top: 10px;
    margin-right: 20px
}

.preferred {
    background: #0071BF;
    border: none;
    color: #FFF
}

.preferred:hover,
.preferred:focus {
    background: #005691
}

.preferred--alt {
    background: #e65100;
    border: none;
    color: #FFF
}

.preferred--alt:hover,
.preferred--alt:focus {
    background: #b84000
}

.non-preferred {
    background: #F5F5F5;
    color: #0071BF
}

.non-preferred:hover,
.non-preferred:active,
.non-preferred:focus {
    background: #E2E2E2
}

.button--disabled {
    zoom: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=$opacityWhole)";
    filter: alpha(opacity=25);
    -moz-opacity: .25;
    -khtml-opacity: .25;
    opacity: .25;
    cursor: default;
    background: transparent;
    border: 1px solid #000;
    -webkit-box-shadow: 0 0 0 0 #000;
    -moz-box-shadow: 0 0 0 0 #000;
    box-shadow: 0 0 0 0 #000;
    color: #000
}

.preferred.button--disabled:hover,
.preferred.button--disabled:focus {
    background: transparent
}

.non-preferred.button--disabled:hover,
.non-preferred.button--disabled:focus {
    background: transparent
}

.button--disabled:active {
    -webkit-box-shadow: 0 0 0 0 #000;
    -moz-box-shadow: 0 0 0 0 #000;
    box-shadow: 0 0 0 0 #000;
    transform: translate(0, 0);
    -webkit-transform: translate(0, 0)
}

a.disabled:hover,
a.disabled:active,
a.disabled:focus {
    text-decoration: none
}

.button-margin {
    margin-right: 15px
}

.button-right {
    float: right
}

.button-left {
    float: left
}

.action-buttons-container--nobuttons .button {
    display: none
}

.button--small {
    padding: 3px;
    font-size: 1.125em;
    min-width: 50px
}

@media screen and (max-width: 51.875em) {
    .action-buttons-container--4button .button,
    .action-buttons-container--4button .button-link {
        margin: 0 2% 15px 0;
        width: 48%
    }
    .action-buttons-container--4button .button:nth-child(2n),
    .action-buttons-container--4button .button-link:nth-child(2n) {
        margin-right: 0
    }
    .action-buttons-container--4button .button-right {
        float: left
    }
}

@media screen and (max-width: 45em) {
    .action-buttons-container--3button .button,
    .action-buttons-container--3button .button-link {
        margin: 0 2% 15px 0;
        width: 48%
    }
    .action-buttons-container--3button .button:nth-child(2n),
    .action-buttons-container--3button .button-link:nth-child(2n) {
        margin-right: 0
    }
    .action-buttons-container--3button .button-right {
        float: left
    }
}

@media screen and (max-width: 40.625em) {
    .button,
    .button-link {
        padding: 8px 10px
    }
    .button--small {
        padding: 3px
    }
}

@media screen and (max-width: 31.25em) {
    .button-link--text-secondary {
        width: 100%;
        float: none;
        text-align: center
    }
    .button-centered {
        margin-left: auto;
        margin-right: auto;
        display: block;
        float: none
    }
    .button--hide-small {
        display: none
    }
}

@media screen and (max-width: 25.625em) {
    .button-container {
        padding-top: 20px
    }
    .equal-width {
        width: 100%;
        margin-right: 0
    }
    .button {
        margin-bottom: 10px
    }
    .button-link {
        margin-bottom: 10px
    }
    .button-margin {
        margin-right: 0
    }
    .button-instructions {
        float: none
    }
    .action-buttons-container--4button .equal-width,
    .action-buttons-container--4button .equal-width {
        margin: 0 0 10px 0;
        width: 100%
    }
    .action-buttons-container--3button .equal-width,
    .action-buttons-container--3button .equal-width {
        margin: 0 0 10px 0;
        width: 100%
    }
}
