@media screen and (max-width: 550px){
    .inputWidth {
        width: 100%;
    }
}
@media screen and (min-width: 550px){
    .inputWidth {
        width: 50%;
    }
}
@media screen and (max-width: 550px){
    .numWidth {
        width: 100%;
    }
}
@media screen and (min-width: 550px){
    .numWidth {
        width: 40%;
    }
}

@media screen and (min-width: 750px){
    .numWidth {
        width: 30%;
    }
}
