//  _____ _ _ _ ____  _____ 
// |   | | | | |    \|   __|
// | | | | | | |  |  |__   |
// |_|___|_____|____/|_____|
//
// NATIONWIDE DESIGN SYSTEM
// --------------------------------------------------
// Bootstrap overrides for input groups for $ and %
//


.input-group-addon {
    background-color: #fff;
    border: 1px solid #8a8a8a;
}

.input-group-addon.disabled {
    color: #767676;
    border: 1px solid #d8d8d8;
    border-right: none;
}
