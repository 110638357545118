
// White Background
.content-container-white {
    font-family: 'proxima-nova', Arial, sans-serif;
    display: inline-block;
    width: 100%;
    background-color: rgb(255,255,255);
    padding: 0 50px;

    @media (max-width: $small) {
        padding: 20px 20px 0;
    }
    @media (max-width: 26.25em) { // 420px
        padding: 20px 0 0;
    }
}
.content-container-white__inner {
    display: block;
    max-width: 900px;
    margin: 0 auto;
    padding: 16px 0 32px;

    @media (max-width: 26.25em) { // 420px
        padding: 0 10px 30px;
    }

    // h2 {
    //     font-size: 1.25em;
    //     font-weight: 200;
    //     text-transform: uppercase;
    //     margin: 15px 0 3px;
    // }
}

// Light Blue Background
.content-container-light-blue {
    font-family: 'proxima-nova', Arial, sans-serif;
    display: inline-block;
    width: 100%;
    background-color: $blue-light-2;
    padding: 0 50px;

    @media (max-width: $small) {
        padding: 20px 20px 0;
    }
    @media (max-width: 26.25em) { // 420px
        padding: 20px 0 0;
    }
}
.content-container-light-blue__inner {
    display: block;
    max-width: 900px;
    margin: 0 auto;
    padding: 16px 0 32px;

    @media (max-width: 26.25em) { // 420px
        padding: 0 10px 30px;
    }
}
