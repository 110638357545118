.required span:first-child::after {
    content: " *";
}

.nw-fieldlabel-optional::after{
    content: "\00a0(optional)";
    font-weight: 300;
    font-style: italic;
    color: #777;
 }

.text-field-label.required {
    white-space: nowrap;
}

p {
    line-height: 1.4em;
}

hr {
    background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
    border: medium none;
    height: 1px;
    margin: 0 0 20px;
    width: 100%;
}

input[type="radio"], input[type="checkbox"] {
    margin-right: 5px;
}

div, nav, footer, section, form, fieldset, legend, input, select, ul, li, a, h1, h2, h3, h4, span {
    box-sizing: border-box;
}
li {
    margin-bottom: 0;
}
body {
    background-color: #5e6269;
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAFCAIAAAACDbGyAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAChJREFUeNpifPz48ffv3xlggAWZw8nJyYTMAcoxIXOADCZkDhAABBgAIdERmsaWPpsAAAAASUVORK5CYII=");
    color: #333;
    height: 100%;
    line-height: inherit;
}
.nw-bg-lightest-blue{
    background-color: #DAE8ED;
}
.icon-before:before {
    height: 34px;
    width: 34px;
    display: inline-block;
    font-size: 34px;
    color: #666;
}
.nw-bg-lightest-blue{
    background-color: #DAE8ED;
}
 .nw-bg-light-blue {
     background-color: #cce1ef;
 }
.nw-bg-grey{
    background-color:#ededed;
    color: #346094;
}
.nw-bg-grey{
    background-color:#ededed;
    color: #346094;
}

.float-align{
    float:left;
    margin-right:10px;
}

.emphasized-installment {
    color: #e65100;
}
