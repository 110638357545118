//  _____ _ _ _ ____  _____
// |   | | | | |    \|   __|
// | | | | | | |  |  |__   |
// |_|___|_____|____/|_____|
//
// NATIONWIDE DESIGN SYSTEM
// --------------------------------------------------
// Bootstrap Typography + Text + Lists
//

// Headers
// h1,.h1,
// h2,.h2,
// h3,.h3,
// h4,.h4,
h5,.h5
    { margin-top:4px; }

// h1,.h1 {
//     font-size: 47px;
//     text-transform: none;
// }

h2,.h2 {
    font-weight:200;
    font-size:29px;
}

// h3,.h3 {
//     font-size:23px;
//     font-weight:700;
// }

// h4,.h4 {
//     font-size:20px;
//     font-weight:400;
// }


// Lead copy
.lead {
  font-size: 23px;
  font-weight: 200;
  line-height:1.4;
}

.amount {
  font-size: 1.5em;
  font-weight: normal;
  line-height:1.4;
}


.text-bold {
  font-weight: bold;
}

// Small copy
small,.small {
    font-size: $font-size-small;
}


// Lists
.list-inline > li {
    padding-left:10px;
    padding-right:10px;
}

li { margin-bottom:.5em; }
