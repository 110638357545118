.main-header__logo-link {
    padding-top:8px;
    padding-left: 40px;
    padding-right: 10px;
    padding-bottom: 14px;
}

.before-sprite:before {
  background-image: url('../images/icon_sprite.png');
  background-repeat: no-repeat;
}

.alert.alert-danger a {
    color: #346094;

    &:hover{
        color: #333;
    }
}

#c2chat {
    bottom: 85px;
    @media (max-width: 767px) {
        display: none;
    }
}
