//  _____ _ _ _ ____  _____
// |   | | | | |    \|   __|
// | | | | | | |  |  |__   |
// |_|___|_____|____/|_____|
//
// NATIONWIDE DESIGN SYSTEM
// --------------------------------------------------
// Bootstrap Alerts and Messaging
//

.alert {
    margin:0;
    margin-bottom: $spacing;
}

.alert.media [class^="nw-icon-"] {
    font-size:24px;
}

.alert a {
    color:inherit;
    text-decoration: underline;

    &:hover {
        //color: darken(inherit,5%);
        color: #333;
    }
}

.alerts-tab{
    font-size:1.125em;
}
