//  _____ _ _ _ ____  _____
// |   | | | | |    \|   __|
// | | | | | | |  |  |__   |
// |_|___|_____|____/|_____|
//
// NATIONWIDE DESIGN SYSTEM
// --------------------------------------------------
// Varibles
//
// Copy/paste any Bootstrap variables you want to overwrite and give a new value. Remove the !default in your copy
// Only bring over Bootstrap variables when you need to overwrite them.
// Make sure you comment any new, non-Bootstrap variables.
//



//
// Breakpoints
// ---------------
// Media queries are mobile-first, so read each as "when screen is greater than [size]"
//
// These only apply to the grid by default. You can use them in components but they may
// required their own breakpoints as well.
//

$screen-max:    100%; // Max width

$screen-sm-int: 550; // Small, first column point NW.com - 650
$screen-sm:     $screen-sm-int+px;

$screen-md-int: 750; // Medium Nw.com - 800
$screen-md:     $screen-md-int+px;

$screen-lg-int: 960; // Large Nw.com - 1024 (max)
$screen-lg:     $screen-lg-int+px;

$screen-sm-min:	$screen-sm;


//
// Colors
// ---------------
//
$color-nwblue:     #1c57a5;

$color-blue:       #346094;
$color-blue-muted: #6aa2b7;
$color-teal:       #007a89;
$color-teal-muted: #1e988a;
$color-green:      #368f3f;
$color-yellow:     #ce9f52;
$color-orange:     #a95521;
$color-red:        #9e2a2b;
$color-fuchsia:    #a83d72;
$color-purple:     #615091;

$color-blue-dark:       #0b3a5d;
$color-blue-muted-dark: #263645;
$color-teal-dark:       #00464f;
$color-teal-muted-dark: #3f5c58;
$color-green-dark:      #37563c;
$color-yellow-dark:     #5c4520;
$color-orange-dark:     #7d4930;
$color-red-dark:        #5d2a2c;
$color-fuchsia-dark:    #672146;
$color-purple-dark:     #403a60;

$color-blue-accent:       #005691;
$color-blue-muted-accent: #57c0e9;
$color-teal-accent:       #3fc1cb;
$color-teal-muted-accent: #59c3b5;
$color-green-accent:      #6dc14a;
$color-yellow-accent:     #ffc556;
$color-orange-accent:     #ff661b;
$color-red-accent:        #f32837;
$color-fuchsia-accent:    #d630ae;
$color-purple-accent:     #9160b5;

$color-neutral-cool: #d7d8da;
$color-neutral-warm: #d8d1c9;
$color-black:        #000;

$color-neutral-cool1: #e0e1dd;
$color-neutral-cool2: #bcbdbc;
$color-neutral-cool3: #87898b;
$color-neutral-cool4: #4d4f53;

$color-neutral-warm1: #e0ded8;
$color-neutral-warm2: #b7b1a9;
$color-neutral-warm3: #8b8178;
$color-neutral-warm4: #544b44;




$brand-primary:         $color-blue; // blue
$brand-secondary:       $color-teal; // teal
$brand-accent:          $color-blue-dark; // navy




//
// Typography + Links
// ---------------
// Line height calc: http://www.pearsonified.com/typography/
//

$text-color:              #333;

$font-size-base:          18px;
$font-size-small:         16px;
$font-size-medium:        23px;
$font-size-large:         29px;
$font-size_xlarge:        47px;

$line-height-base:        1.44; // Line height will change based on breakpoint. See nw-theme.scss
$line-height-large:				1.3333333;
$line-height-small:				1.5;
$line-height-computed:		floor(($font-size-base * $line-height-base));
$font-path:               "./fonts" !default;
$font-family-sans-serif:  "Proxima-Nova","Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
$font-family-serif:       "Chronicle",Georgia,"Times New Roman","Roboto Slab",serif;
$font-weight-light:       200;

$headings-font-weight:    400; // Weight for headers


$link-color:            $color-blue-accent;
$link-hover-color:      darken($link-color, 8%);
$link-hover-decoration: underline;

//
// Icon Fonts
//
$icon-font-path: "./fonts/" !default;

//
// Margin and Padding
// ---------------
// Common spacing should be standard for both margins and padding.
//

$spacing:    20px;
$spacing-sm: 12px; // Nw.com - 10
$spacing-lg: 35px; // Nw.com - 40
$spacing-xl: 48px; // Nw.com - 60

$grid-gutter-width: 32px;

$container-edge-padding: 5px; // 16px; // Should be near half of gutter width

//
// Input Controls and Buttons
// ---------------
//

$input-border:                   #8a8a8a;
$input-color-placeholder:        #b7b7b7;
$input-bg-disabled:              #fff;

$btn-default-color:              $color-blue;
$btn-default-bg:                 #f2f2f2;
$btn-default-border:             darken($btn-default-bg,15%);

$btn-inline-color:               $brand-primary;
$btn-inline-bg:                  none;
$btn-inline-border:              $brand-primary;

$btn-inline-light-color:         #e7e7e7;
$btn-inline-light-bg:            none;
$btn-inline-light-border:        #e7e7e7;

//
// Panels / Accordions
// ---------------
//

$panel-heading-padding:       10px 15px 10px 40px;


//** Border color for elements within panels
$panel-inner-border:          #ddd;
$panel-footer-bg:             #f5f5f5;

$panel-default-text:          $text-color;
$panel-default-border:        #ddd;
$panel-default-heading-bg:    #ededed;



//
// Tabs
// ---------------
//
$nav-tabs-border-color:                     #d8d8d8;
$nav-tabs-active-link-hover-border-color:   #d8d8d8;
$nav-tabs-bg-color:                         #ededed;

$nav-tabs-text:    $font-size-small;
$nav-tabs-text-lg: $font-size-base;


//
// Modals
// ---------------
//


$modal-inner-padding:         20px;
$modal-title-padding:         20px;
$modal-content-bg:            #fff;


//
// Tables
// ---------------
//

$table-bg-accent:             #f1f1f1;


//
// Progress bars
// ---------------
//

$progress-bg:                 #f5f5f5;
$progress-bar-color:          #fff;
$progress-bar-bg:             $brand-secondary;
