//  _____ _ _ _ ____  _____ 
// |   | | | | |    \|   __|
// | | | | | | |  |  |__   |
// |_|___|_____|____/|_____|
//
// NATIONWIDE DESIGN SYSTEM
// --------------------------------------------------
// Bootstrap Pagination
//


.pagination,
.pagination>li>a,
.pagination>li>span 
    {
        border:none;
    }

.pagination>.active>a, 
.pagination>.active>a:hover, 
.pagination>.active>a:focus, 
.pagination>.active>span, 
.pagination>.active>span:hover, 
.pagination>.active>span:focus 
    {
        z-index: 2;
        color: #333;
        background: none;
        border: none;
        cursor: default;
        font-weight: 700;
    }


// Chevrons blue
.pagination a .chevron:before {
    border-color: transparent transparent transparent $color-blue-accent;
}

.pagination a .chevron--left:before {
    border-color: transparent $color-blue-accent transparent transparent;
}


// Disabled grey
.pagination .inactive a .chevron:before {
    border-color: transparent transparent transparent #999;
}

.pagination .inactive a .chevron--left:before {
    border-color: transparent #999 transparent transparent;
}



// Chevron hover is same grey as link hover
.pagination a:hover .chevron:after,
.pagination a:focus .chevron:after {
    border-color: transparent transparent transparent $gray-lighter;
}

.pagination a:hover .chevron--left:after,
.pagination a:focus .chevron--left:after {
    border-color: transparent $gray-lighter transparent transparent;
}


li.pagination-last a,
li.pagination-first a,
li.pagination-next a,
li.pagination-prev a
{     
    line-height: 1.2em;
}
