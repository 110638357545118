
.claims-datepicker-btn {
    position: relative;
    margin-left: -45px;
    height: 34px;
    width: 45px;
    line-height: 100px;
    overflow: hidden;
    display: inline-block;
    vertical-align: top;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border: none;
    background: whitesmoke;
    color: #005691;
    box-shadow: 0 1px 1px 0 #000333;
    border-top: 1px solid #bfc0c3;
}
.claims-datepicker-btn:before {
    position: absolute;
    top: 3px;
    left: 0;
    right: 0;
    font-size: 1.4em;
}
/*  Calendar  */
.claims-datepicker-wrap .dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 160px;
    padding: 5px 0;
    margin: 2px 0 0;
    font-size: 14px;
    text-align: left;
    list-style: none;
    background-color: #fff;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    border: 1px solid #ccc;
    border: 1px solid rgba(0,0,0,.15);
    border-radius: 4px;
    -webkit-box-shadow: 0 6px 12px rgba(0,0,0,.175);
    box-shadow: 0 6px 12px rgba(0,0,0,.175);
}
.claims-datepicker-wrap .dropdown-menu {
    display: inline-block;
    width: 300px;
    font-family: 'proxima-nova', Arial, sans-serif;
    /*        font-size: 1.6em;*/
    padding: 9px 2px 3px;
    background: rgb(255,255,255);
    border: 1px solid #333;
    border-radius: 0;
}
.claims-datepicker-wrap .dropdown-menu table {
    width: 294px;
    text-align: left;
    table-layout: fixed;
}
/* Prev / Next Buttons */
.claims-datepicker-wrap .dropdown-menu table button,
.claims-datepicker-wrap .dropdown-menu table button {
    background: transparent;
    border: none;
}
.claims-datepicker-wrap .dropdown-menu table thead th:first-of-type {
    text-align: right;
}
.claims-datepicker-wrap .dropdown-menu table thead th:first-of-type button {
    text-align: right;
    padding: 0;
    &:hover,
    &:focus {
        text-decoration: underline;
    }
}
.claims-datepicker-wrap .dropdown-menu table thead th:last-of-type button {
    text-align: left;
    padding: 0;
    &:hover,
    &:focus {
        text-decoration: underline;
    }
}
.claims-datepicker-wrap .dropdown-menu table thead th:first-of-type button:before {
    position: relative;
    display: inline-block;
    content: '';
    margin-right: 5px;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-right: 5px solid #e65100;
    margin-top: 3px;
}
.claims-datepicker-wrap .dropdown-menu table thead th:last-of-type button:after {
    position: relative;
    display: inline-block;
    content: '';
    margin-left: 5px;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-left: 5px solid #e65100;
    margin-top: 5px;
}
//.claims-datepicker-wrap .dropdown-menu table thead th:first-of-type button:after {
//    display: inline-block;
//    content: 'Prev';
//    font-family: 'proxima-nova', Arial, sans-serif;
//    color: rgb(0, 86, 145);
//}
//.claims-datepicker-wrap .dropdown-menu table thead th:last-of-type button:before {
//    display: inline-block;
//    content: 'Next';
//    font-family: 'proxima-nova', Arial, sans-serif;
//    color: rgb(0, 86, 145);
//}
.claims-datepicker-wrap .dropdown-menu table thead th:first-of-type button:hover:after,
.claims-datepicker-wrap .dropdown-menu table thead th:last-of-type button:hover:before {
    text-decoration: underline;
}
/* Datepicker Title */
.claims-datepicker-wrap .dropdown-menu table thead th:nth-child(2) button {
    font-family: 'proxima-nova', Arial, sans-serif;
    font-size: 1.23em;
    font-weight: bold;
    color: rgb(51,51,51);
}
/* Day Titles */
.claims-datepicker-wrap .dropdown-menu table thead tr:last-of-type th {
    text-align: center;
    padding: 5px 0;
    border-collapse: collapse;
}
.claims-datepicker-wrap .dropdown-menu table thead tr:last-of-type th small {
    font-size: 1.125em;
}
/* Days */
.claims-datepicker-wrap .dropdown-menu table tbody td {
    width: 42px;
    text-align: center;
    border: 1px solid #fff;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.claims-datepicker-wrap .dropdown-menu table tbody td button {
    height: 33px;
    color: rgb(0, 86, 145);
    .text-muted {
        color: rgb(194,194,194);
    }
}
.claims-datepicker-wrap .dropdown-menu table tbody td button[disabled] {
    color: rgb(194,194,194);
}
.claims-datepicker-wrap .dropdown-menu table tbody td button.active {
    font-weight: bold;
    text-decoration: none;
    color: #000;
    border: 1px solid #adafaf;
}
.claims-datepicker-wrap .dropdown-menu table tbody td .btn-default.active {
    background: $gray-light-3;
}
.claims-datepicker-wrap .dropdown-menu table tbody td .btn-info.active {
    background: rgb(202, 227, 249);
}
