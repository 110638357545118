//  _____ _ _ _ ____  _____ 
// |   | | | | |    \|   __|
// | | | | | | |  |  |__   |
// |_|___|_____|____/|_____|
//
// NATIONWIDE DESIGN SYSTEM
// --------------------------------------------------
// Icons / SVGs + Fonts
//


//
// Wait spinner, progress indicator
//
.nw-spinner {
    display:inline-block;
    text-align: center;
    font-size: 6px;
    margin: 0 auto;
    /*text-indent: -9999em;*/
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background: #ffffff;
    background: -moz-linear-gradient(left, $color-blue 10%, rgba(52,96,148, 0) 42%);
    background: -webkit-linear-gradient(left, $color-blue 10%, rgba(52,96,148, .2) 42%);
    background: -o-linear-gradient(left, $color-blue 10%, rgba(52,96,148, .2) 42%);
    background: -ms-linear-gradient(left, $color-blue 10%, rgba(52,96,148, .2) 42%);
    background: linear-gradient(to right, $color-blue 10%, rgba(52,96,148, .2) 42%);
    position: relative;
    -webkit-animation: spinner-ani 1s infinite linear;
    animation: spinner-ani 1s infinite linear;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    content:""
}
.nw-spinner:before {
    width: 50%;
    height: 50%;
    background: $color-blue;
    border-radius: 100% 0 0 0;
    position: absolute;
    top: 0;
    left: 0;
    content: '';
}
.nw-spinner:after {
    background: #fff;
    width: 75%;
    height: 75%;
    border-radius: 50%;
    content: '';
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}
@-webkit-keyframes spinner-ani {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spinner-ani {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

    
.nw-spinner--light {
  background: #ffffff;
  background: -moz-linear-gradient(left, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
  background: -webkit-linear-gradient(left, #ffffff 10%, rgba(255, 255, 255, .2) 42%);
  background: -o-linear-gradient(left, #ffffff 10%, rgba(255, 255, 255, .2) 42%);
  background: -ms-linear-gradient(left, #ffffff 10%, rgba(255, 255, 255, .2) 42%);
  background: linear-gradient(to right, #ffffff 10%, rgba(255, 255, 255, .2) 42%);   
}
    
.nw-spinner--light:after {
    background: $color-blue;
}
    
.nw-spinner--light:before {
    background: #fff;
}
    
    
.nw-spinner--small {
	width:20px;
	height:20px        
}


//
// Social Media Icons
//

.nw-social-icon {
    padding:2px;
    border-radius: 33%;
    display: inline-block;
}

a .nw-social-icon:hover {
    background:#fff;
}

.nw-social-icon {
    width:38px; 
}

.nw-social-icon--lg {
    width:64px; 
}



//
// Triangle Arrows
// ---------------
// All-CSS triangles. In addition to Bootstrap's .caret
//

$arrow-size: 6px;

.nw-arrow:before {
    width:0;
    height:0;
    content: "";
    display:inline-block;
    border-color: inherit; // Base color
    margin: 0 ($arrow-size + 2);
}

.nw-arrow--right:before {
    border-left-width:$arrow-size;
    border-left-style:solid;
    border-top:$arrow-size solid transparent;
    border-bottom:$arrow-size solid transparent;
}

.nw-arrow--left:before {
    border-right-width:$arrow-size;
    border-right-style:solid;
    border-top:$arrow-size solid transparent;
    border-bottom:$arrow-size solid transparent;
}

.nw-arrow--up:before {
    border-bottom-width:$arrow-size;
    border-bottom-style:solid;
    border-left:$arrow-size solid transparent;
    border-right:$arrow-size solid transparent;
    border-top:$arrow-size solid transparent;
}

.nw-arrow--down:before {
    border-top-width:$arrow-size;
    border-top-style:solid;
    border-left:$arrow-size solid transparent;
    border-right:$arrow-size solid transparent;
}



// Toggle arrow for dropdowns and menus
.nw-arrow-toggle .nw-arrow:before {
    border-bottom-width:$arrow-size;
    border-bottom-style:solid;
    border-left:$arrow-size solid transparent;
    border-right:$arrow-size solid transparent;
    border-top:$arrow-size solid transparent;
}

.nw-arrow-toggle.collapsed .nw-arrow:before { // Down arrow
    border-top:$arrow-size solid;
    border-bottom-width:0;
    border-bottom-style:none;
    border-left:$arrow-size solid transparent;
    border-right:$arrow-size solid transparent;
}



//
// Chevron separator
//

.chevron { 
    display:inline-block;
    width:7px;
    height:.7em;
    position:relative;
    margin:0 5px;
}

.chevron:before,
.chevron:after
    {
        display:block;
        content:'';
        width:0;
        height:0;
        border-style:solid;
        position:absolute;
    }
        
.chevron:before{
    right:0;
    border-width:6px 0 6px 6px;
    border-color:transparent transparent transparent #333;
}

.chevron:after{
    left:0;
    border-width:6px 0 6px 6px;
    border-color:transparent transparent transparent #FFF;
} 


.chevron--left:before{
    left:0;
    border-width:6px 6px 6px 0;
    border-color:transparent #333 transparent transparent;
}

.chevron--left:after{
    right:0;
    left: auto;
    border-width:6px 6px 6px 0;
    border-color:transparent #FFF transparent transparent;
}



//
// Web font icons
// ---------------
// Used icomoon.io to create the font from official SVGs.
//

@font-face {
    font-family: 'Bolticons';
    src:    url(#{$font-path}/bolt-icons.eot?y7smo9);
    src:    url(#{$font-path}/bolt-icons.eot?y7smo9#iefix) format('embedded-opentype'),
        url(#{$font-path}/bolt-icons.ttf?y7smo9) format('truetype'),
        url(#{$font-path}/bolt-icons.woff?y7smo9) format('woff'),
        url(#{$font-path}/bolt-icons.svg?y7smo9#icomoon) format('svg');
    font-weight: normal;
    font-style: normal;
}


[class^="nw-icon-"], [class*=" nw-icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'Bolticons' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.nw-icon-inline-help:before {
    content: "\e915";
}
.nw-icon-inline-close:before {
    content: "\e916";
}
.nw-icon-calendar:before {
    content: "\e912";
}
.nw-icon-mail:before {
    content: "\e913";
}
.nw-icon-resize:before {
    content: "\e914";
}
.nw-icon-warning:before {
    content: "\e911";
}
.nw-icon-error:before {
    content: "\e904";
}
.nw-icon-info:before {
    content: "\e90f";
}
.nw-icon-add:before {
    content: "\e900";
}
.nw-icon-checkmark:before {
    content: "\e901";
}
.nw-icon-close:before {
    content: "\e902";
}
.nw-icon-profile:before {
    content: "\e903";
}
.nw-icon-help:before {
    content: "\e905";
}
.nw-icon-home:before {
    content: "\e906";
}
.nw-icon-info2:before {
    content: "\e907";
}
.nw-icon-lock:before {
    content: "\e908";
}
.nw-icon-menu:before {
    content: "\e909";
}
.nw-icon-print:before {
    content: "\e90a";
}
.nw-icon-remove:before {
    content: "\e90b";
}
.nw-icon-search:before {
    content: "\e90c";
}
.nw-icon-settings:before {
    content: "\e90d";
}
.nw-icon-star:before {
    content: "\e90e";
}
.nw-icon-warning2:before {
    content: "\e910";
}

.nw-icon-arrow-thin-up:before {
	content: "\e917";
}
.nw-icon-arrow-thin-down:before {
	content: "\e918";
}
.nw-icon-arrow-thin-left:before {
	content: "\e919";
}
.nw-icon-arrow-thin-right:before {
	content: "\e920";
}

.nw-icon-arrow-thick-up:before {
	content: "\e921";
}
.nw-icon-arrow-thick-down:before {
	content: "\e922";
}
.nw-icon-arrow-thick-left:before {
	content: "\e923";
}
.nw-icon-arrow-thick-right:before {
	content: "\e924";
}


