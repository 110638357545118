.claims-track-claim-data-grid__row {
    display: table-row;
    width: 100%;

    @media (max-width: 45em) { // 720px
        display: block;
        margin-bottom: 20px;

        &:first-of-type {
            div {
                margin: 0 0 5px;
            }
        }
    }

    div {
        display: table-cell;
        width: 33%;
        font-weight: bold;
        padding-bottom: 15px;

        @media (max-width: 58.13em) { // 930px
            width: 36%;
        }
        @media (max-width: 56.25em) { // 900px
            width: 40%;
        }
        @media (max-width: 51.25em) { // 820px
            width: 42%;
        }
        @media (max-width: 45em) { // 720px
            display: block;
            width: 100%;
            margin: 27px 0 5px 0;
            padding-bottom: 0;
        }

        &+ div {
            width: 65%;
            padding-left: 2%;
            font-weight: 400;
            small {
                display: inline-block;
                vertical-align: top;
                width: 62%;
                margin: 1px 0 0;

                @media (max-width: 58.13em) { // 930px
                    width: 50%;
                }
                @media (max-width: 51.25em) { // 820px
                    display: block;
                    width: 100%;
                    margin: 0;
                }
                @media (max-width: 28.125em) { // 450px
                    font-size: .9em;
                }
            }

            @media (max-width: 58.13em) { // 930px
                width: 56%;
            }
            @media (max-width: 56.25em) { // 900px
                width: 58%;
            }
            @media (max-width: 51.25em) { // 820px
                width: 56%;
            }
            @media (max-width: 45em) { // 720px
                width: 100%;
                margin: 0;
                padding: 0;
                small {
                    display: block;
                    width: 100%;
                    margin: 0;
                }
            }
        }
    }
}

.content-container-light-blue__inner {
    p.margin-bottom-0{ margin-bottom: 3px; }
}

.padding-top-5 {
    padding-top: 5px; }

  .padding-top-10 {
    padding-top: 10px; }

  .padding-top-15 {
    padding-top: 15px; }

  .padding-top-20 {
    padding-top: 20px; }

  .padding-top-30 {
    padding-top: 30px; }

  .padding-top-40 {
    padding-top: 40px; }

  .padding-top-26 {
    padding-top: 26px; }

  .padding-bottom-5 {
    padding-bottom: 5px; }

  .padding-bottom-10 {
    padding-bottom: 10px; }

  .padding-bottom-15 {
    padding-bottom: 15px; }

  .padding-bottom-20 {
    padding-bottom: 20px; }

  .padding-bottom-30 {
    padding-bottom: 30px; }

  .padding-bottom-40 {
    padding-bottom: 40px; }

  .padding-bottom-26 {
    padding-bottom: 26px; }

  .padding-left-5 {
    padding-left: 5px; }

  .padding-left-10 {
    padding-left: 10px; }

  .padding-left-15 {
    padding-left: 15px; }

  .padding-left-20 {
    padding-left: 20px; }

  .padding-left-30 {
    padding-left: 30px; }

  .padding-left-40 {
    padding-left: 40px; }

  .padding-right-5 {
    padding-right: 5px; }

  .padding-right-10 {
    padding-right: 10px; }

  .padding-right-15 {
    padding-right: 15px; }

  .padding-right-20 {
    padding-right: 20px; }

  .padding-right-30 {
    padding-right: 30px; }

  .padding-right-40 {
    padding-right: 40px; }

  .padding-left-right-10 {
    padding-left: 10px;
    padding-right: 10px; }

  .padding-left-right-20 {
    padding-left: 20px;
    padding-right: 20px; }

  .margin-top-0 {
    margin-top: 0; }

  .margin-top-5 {
    margin-top: 5px; }

  .margin-top-10 {
    margin-top: 10px; }

  .margin-top-15 {
    margin-top: 15px; }

  .margin-top-20 {
    margin-top: 20px; }

  .margin-top-30 {
    margin-top: 30px; }

  .margin-top-40 {
    margin-top: 40px; }

  .margin-top-26 {
    margin-top: 26px; }

  .margin-top-bottom-0 {
    margin: 0; }

  .margin-top-bottom-5 {
    margin: 5px 0; }

  .margin-top-bottom-10 {
    margin: 10px 0; }

  .margin-top-bottom-15 {
    margin: 15px 0; }

  .margin-top-bottom-20 {
    margin: 20px 0; }

  .margin-top-bottom-30 {
    margin: 30px 0; }

  .margin-top-bottom-40 {
    margin: 40px 0; }

  .margin-top-bottom-26 {
    margin: 26px 0; }

  .margin-bottom-0 {
    margin-bottom: 0; }

  .margin-bottom-5 {
    margin-bottom: 5px; }

  .margin-bottom-10 {
    margin-bottom: 10px; }

  .margin-bottom-15 {
    margin-bottom: 15px; }

  .margin-bottom-20 {
    margin-bottom: 20px; }

  .margin-bottom-30 {
    margin-bottom: 30px; }

  .margin-bottom-40 {
    margin-bottom: 40px; }

  .margin-bottom-26 {
    margin-bottom: 26px; }

  .margin-left-0 {
    margin-left: 0; }

  .margin-left-5 {
    margin-left: 5px; }

  .margin-left-10 {
    margin-left: 10px; }

  .margin-left-15 {
    margin-left: 15px; }

  .margin-left-20 {
    margin-left: 20px; }

  .margin-left-30 {
    margin-left: 30px; }

  .margin-left-40 {
    margin-left: 40px; }

  .margin-right-0 {
    margin-right: 0; }

  .margin-right-5 {
    margin-right: 5px; }

  .margin-right-10 {
    margin-right: 10px; }

  .margin-right-15 {
    margin-right: 15px; }

  .margin-right-20 {
    margin-right: 20px; }

  .margin-right-30 {
    margin-right: 30px; }

  .margin-right-40 {
    margin-right: 40px; }

  .margin-left-right-10 {
    margin-left: 10px;
    margin-right: 10px; }

  .margin-left-right-20 {
    margin-left: 20px;
    margin-right: 20px; }
