
.claims-track-claim-details-uploads {
    .field-group.inline-block select {
        margin-right: 10px;

        @media (max-width: 37.5em) { // 600px
            max-width: 100%;
        }
        @media (max-width: 34.38em) { // 550px
            width: 100%;
            margin-bottom: 0;
            margin-right: 0;
        }
    }

    .field-group.inline-block {
        @media (max-width: 34.38em) { // 550px
            display: block;
            width: 100%;
            padding-bottom: 15px;
        }
    }

    .claims-track-claim-details-uploads__wrap {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -moz-flex-flow: row nowrap;
        -webkit-flex-flow: row nowrap;
        flex-flow: row nowrap;
        -webkit-align-content: flex-start;
        align-content: flex-start;

        @media (max-width: 970px) {
            -moz-flex-flow: row wrap;
            -webkit-flex-flow: row wrap;
            flex-flow: row wrap;
        }

        .claims-track-claim-details-uploads__doc-type {
            -moz-flex-grow: 0;
            -webkit-flex-grow: 0;
            flex-grow: 0;

            -moz-flex-basis: 301px;
            -webkit-flex-basis: 301px;
            flex-basis: 301px;

            margin-right: 10px;

            @media (max-width: 550px) {
                -moz-flex-grow: 0;
                -webkit-flex-grow: 0;
                flex-grow: 0;

                -moz-flex-basis: 100%;
                -webkit-flex-basis: 100%;
                flex-basis: 100%;

                margin-right: 0;
            }

            .field-group select {
                width: 100%;
                margin-right: 0;
            }
        }
        .claims-track-claim-details-uploads__upload-btn {
            -moz-flex-grow: 0;
            -webkit-flex-grow: 0;
            flex-grow: 0;
            -moz-flex-basis: 128px;
            -webkit-flex-basis: 128px;
            flex-basis: 128px;
            padding-top: 18px;

            P {
                text-align: center;
                margin-bottom: 0;
            }

            @media (max-width: 650px) {
                -moz-flex-basis: 97px;
                -webkit-flex-basis: 97px;
                flex-basis: 97px;

                p {
                    line-height: 1em;
                }
            }
            @media (max-width: 550px) {
                -moz-flex-grow: 0;
                -webkit-flex-grow: 0;
                flex-grow: 0;
                -moz-flex-basis: 100%;
                -webkit-flex-basis: 100%;
                flex-basis: 100%;
                padding-top: 0;
                margin-bottom: 10px;

                p {
                    text-align: left;
                }
            }
            @media (max-width: 410px) {
                margin-bottom: 5px;

                button {
                    margin: 0 0 5px 0;
                }
                p {
                    text-align: center;
                }
            }
        }
        .claims-track-claim-details-uploads__upload-counter {
            display: -webkit-box;
            display: -moz-box;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;
            -moz-flex: initial;
            -webkit-flex: initial;
            flex: initial;
            max-width: 428px;
            min-width: 100px;

            p {
                color: rgb(153, 152, 158);
                margin: 26px 0 0 12px;
            }
            .alert {
                margin: 0 0 0 8px;
            }

            @media (max-width: 990px) {
                max-width: 400px;
            }
            @media (max-width: 970px) {
                max-width: 100%;

                .alert {
                    margin: 12px 0 0;
                }
            }
            @media (max-width: 715px) {
                width: 100%;

                p {
                    margin: 0;
                }
            }
            @media (max-width: 550px) {
                .alert {
                    margin: 6px 0 0;
                }
            }
            @media (max-width: 410px) {
                width: 100%;
                justify-content: center;
            }
        }
    }

    .claims-track-claim-details-uploads__upload-doc-title,
    .claims-track-claim-details-uploads__upload-type-title {
        float: left;
        width: 30%;
        font-weight: bold;

        @media (max-width: $small) {
            float: none;
            width: 100%;
            text-align: left;
            margin-bottom: 5px;
        }
    }

    .claims-track-claim-details-uploads__upload-type-title {
        width: 45%;
    }

    .claims-track-claim-details-uploads__upload-doc-title:before {
        font-size: 1rem;
        margin-right: 5px;
        color: $green;

        content: '';
        display: inline-block;
        height: 16px;
        width: 16px;
        background: url(/assets/images/claims/icon--checkmark.svg) no-repeat 0 3px transparent;
        background-size: 100%;
    }

    .claims-track-claim-details-uploads__upload-preview-delete {
        float: right;
        text-align: right;

        @media (max-width: $small) {
            float: none;
            width: 100%;
            text-align: left;
            margin-bottom: 20px;
        }
    }

    .claims-track-claim-details-uploads__mail-to {
        display: inline-block;
        vertical-align: top;
        width: 49%;

        @media (max-width: 41.25em) { // 660px
            &:first-of-type {
                width: 60%;
            }
            &:last-of-type {
                width: 39%;
            }
        }
        @media (max-width: 34.38em) { // 550px
            &:first-of-type, &:last-of-type {
                width: 100%;
            }
        }

        dt {
            margin-bottom: 10px;
        }
    }
}

#closePreview{
    position: absolute;
    top: 51%;
    width: 10%;
    display: none;
    z-index: 1;
    background: white;
}
