// h1 {
//     color: #666;
//     font-family: "proxima-nova",Arial,sans-serif;
//     font-size: 2.2em;
//     font-weight: 300;
// }
// h2 {
//     margin: 0;
// }
// h3 {
//     font-weight: normal;
//     margin: 0 0 20px;
// }
// h4 {
//     font-weight: normal;
//     margin: 0 0 10px;
// }
// h5 {
//     margin: 0 0 10px;
// }
.section-header {
    background: #666 none repeat scroll 0 0;
    margin-bottom: 20px;
    padding: 15px 20px;
    position: relative;
}

.section-header--form {
    background: #e7e7e7 none repeat scroll 0 0;
}
.section-header h2 {
    color: #fff;
    display: inline-block;
    margin: 0 5px 0 0;
    position: relative;
}
.section-header--form h2 {
    color: #333;
    text-transform: none;
}
.section-header__action {
    text-decoration: underline;
    margin-left: auto;
    float: right;
    padding-top: 5px;
}
.section-header__nonaction {
    color: #fff;
    display: inline-block;
    float: right;
    margin-left: auto;
    padding-top: 8px;
    vertical-align: middle;
}
.section-header--form__nonaction {
    color: #333;
}

.main-header-unauth {
    max-width: 1200px;
    margin: 0 auto 20px;
    position: relative;
    max-width: 1200px;
    margin: 0 auto;
}

.main-header-unauth:focus{
    outline: none
}

.heading-link{
    font-weight: 500;
}
.heading-link:after{
    position: relative;
    display: inline-block;
    content: '';
    margin-left: 10px;
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    border-left: 6px solid #e65100;
}

@media screen and (max-width: 62.1875em) {
    .main-header-unauth {
        margin-bottom: 0;
    }
    .main-header-unauth--pre {
        border-bottom: medium none;
    }
}

@media screen and (max-width: 37.5em) {
    h1 {
        font-size: 1.7em;
    }
    .section-header {
        padding: 15px 10px 10px;
    }
    .section-header--long-content h2 {
        margin-bottom: 5px;
        width: 100%;
    }
    .section-header--long-content .section-header__nonaction {
        display: block;
        float: none;
        margin-left: 0;
    }
}
@media screen and (max-width: 28.125em) {
    h1 {
        font-size: 1.5em;
        margin-bottom: 10px;
    }
    h2 {
        margin-bottom: 10px;
    }
    .section-header__nonaction {
        padding-top: 2px;
    }
    .section-header--long-content h2 {
        margin-bottom: 5px;
        max-width: none;
        width: 100%;
    }
    .main-header-unauth--pre {
        border-bottom: 1px solid #cccccc;
        margin-bottom: 20px;
    }
}

.requiredFieldNotice{
    float: left;
    margin-bottom: 15px;
    margin-left: 5px;
}
