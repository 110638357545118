@font-face {
    font-family: 'icomoon';
    src:url('/fonts/icomoon.eot?9tqsrw');
    src:url('/fonts/icomoon.eot?9tqsrw#iefix') format('embedded-opentype'),
        url('/fonts/icomoon.woff?9tqsrw') format('woff'),
        url('/fonts/icomoon.ttf?9tqsrw') format('truetype'),
        url('/fonts/icomoon.svg?9tqsrw#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
}

[class^="icon--"]:before, [class*="icon--"]:before {
    font-family: 'icomoon';
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon--OOP:before {
    content: "\e907";
}
.icon--nw-drive-in:before {
    content: "\e905";
}

.icon--OYS:before {
    content: "\e904";
}

.icon--injury-around-vehicle:before {
    content: "\e906";
}

.icon--claim-repair-estimate-report:before {
    content: "\e61d";
}

.icon--business-auto-claim-loss-cause:before {
    content: "\e62a";
}

.icon--estimate-appointment:before {
    content: "\e61c";
}

.icon--find-next-car:before {
    content: "\e61f";
}

.icon--collision-all-other:before {
    content: "\e903";
}

.icon--comprehensive-all-other:before {
    content: "\e902";
}

.icon--bicycle:before {
    content: "\e900";
}

.icon--accident:before {
    content: "\e626";
}

.icon--object-hit-vehicle:before {
    content: "\e627";
}

.icon--contact-object:before {
    content: "\e629";
}

.icon--contact-fixed-object:before {
    content: "\e628";
}

.icon--pedestrian-accident:before {
    content: "\e625";
}

.icon--injury:before {
    content: "\e624";
}

.icon--earth-movement:before {
    content: "\e623";
}

.icon--non-accidental-damage:before {
    content: "\e622";
}

.icon--vandalism:before {
    content: "\e621";
}

.icon--weather:before {
    content: "\e620";
}

.icon--claim-upload-doc:before {
    content: "\e61d";
}

.icon--coverages-snapshot:before {
    content: "\e61e";
}

.icon--claim-update:before {
    content: "\e61d";
}

.icon--claim-check:before {
    content: "\e61a";
}

.icon--calendar:before {
    content: "\e61c";
}

.icon--roadside:before {
    content: "\e61b";
}

.icon--waterbackup:before {
    content: "\e619";
}

.icon--payments:before {
    content: "\e617";
}
.icon--wallet:before {
    content: "\e908";
}
.icon--contactpreferences:before {
    content: "\e618";
}

.icon--dollarsign:before {
    content: "\e615";
}

.icon--retirement:before {
    content: "\e616";
}

.icon--animal:before {
    content: "\e600";
}

.icon--auto:before {
    content: "\e601";
}

.icon--checkmark:before {
    content: "\e602";
}

.icon--claim:before {
    content: "\e603";
}

.icon--contact:before {
    content: "\e604";
}

.icon--exclamationmark:before {
    content: "\e605";
}

.icon--fire:before {
    content: "\e606";
}

.icon--flood:before {
    content: "\e607";
}

.icon--glass:before {
    content: "\e608";
}

.icon--hail:before {
    content: "\e609";
}

.icon--lifeinsurance:before {
    content: "\e60a";
}

.icon--multicarcollision:before {
    content: "\e60b";
}

.icon--photo:before {
    content: "\e60c";
}

.icon--property:before {
    content: "\e60d";
}

.icon--questionmark:before {
    content: "\e60e";
}

.icon--rental:before {
    content: "\e60f";
}

.icon--repair:before {
    content: "\e610";
}

.icon--singlecarcollision:before {
    content: "\e611";
}

.icon--theft:before {
    content: "\e612";
}

.icon--partial-theft:before {
    content: "\e901";
}

.icon--weather-old:before {
    content: "\e613";
}

.icon--wind:before {
    content: "\e614";
}

