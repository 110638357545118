@import '../bolt3';
.nw-col-1, .nw-col-2, .nw-col-3, .nw-col-4, .nw-col-5,
.nw-col-6, .nw-col-7, .nw-col-8, .nw-col-9, .nw-col-10,
.nw-col-fixed-1  {
    margin-right: 0.7em;
}
.nw-col-1 {
    float: left;
    width: 10%;
}
.nw-col-2 {
    float: left;
    width: 20%;
}
.nw-col-3 {
    float: left;
    width: 30%;
}
.nw-col-4 {
    float: left;
    width: 40%;
}
.nw-col-5 {
    float: left;
    width: 50%;
}
.nw-col-6 {
    float: left;
    width: 60%;
}
.nw-col-7 {
    float: left;
    width: 70%;
}
.nw-col-8 {
    float: left;
    width: 80%;
}
.nw-col-9 {
    float: left;
    width: 90%;
}
.nw-col-10 {
    float: left;
    width: 100%;
}

.nw-col-fixed-1 {
    float: left;
    width: 2em;
}
.span-link{
    display: inline-block;
    padding-top: 0.7em;
    cursor: pointer;
}
.claims-container {
    $dims: ("top","right","bottom","left");
    $props: ("pd","mg");
    @each $prop in $props {
        @each $dim in $dims {
             @if $prop == mg {
            .mg {
                    &-#{$dim}-0 {
                        margin-#{$dim}: 0
                   }
                }
            }
             @if $prop == pd {
            .pd {
                    &-#{$dim}-0 {
                        padding-#{$dim}: 0
                   }
                }
            }
        }
    }
}
.dev-error-pop {
    color: red;
    border: 1px solid rgb(255, 0, 0);
    background-color: yellow;
    padding: 3px;
    margin: 3px;
}
