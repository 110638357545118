//  _____ _ _ _ ____  _____
// |   | | | | |    \|   __|
// | | | | | | |  |  |__   |
// |_|___|_____|____/|_____|
//
// NATIONWIDE DESIGN SYSTEM
// --------------------------------------------------
// Overlay Modals
//


// Font styles
.nw-modal-icon {
    font-size: 48px;
}

.modal-text {
    text-align: center;
    font-family: "Proxima-Nova","Helvetica Neue",Helvetica,Roboto,Arial,sans-serif;
    font-size: 1.125em;
    line-height: 1.44;
    color: #333;
}
