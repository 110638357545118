.slim-link-list{
    list-style: none;
    padding:10px 0;
    margin:0;
    font-size:0px;
    text-align: center;
}
.slim-link-list li{
    display: inline-block;
    padding: 0 5px;
    border-right: solid 1px $color-blue-accent;
    font-size:$font-size-small;
}
.slim-link-list li:last-of-type{
    border-right: none;
}
.slim-panel{
    padding: 10px 15px;
}
.short-panel{
    padding: 15px;
}
.short-panel > div{
    margin-bottom: 15px;
}
.short-panel > div:last-of-type{
    margin-bottom:0;
}
.header-icon{
    padding-left: 35px;
    position:relative;
}
.short-panel p, .slim-panel p{
    margin-bottom:0;
}
.short-panel .btn{
    font-size: $font-size-small;
}
.business-accounts-header{
    margin-top: 10px;
    margin-bottom: 0;
}
.panel-container{
    background-color: #fff;
    border-radius: 15px;
    border: 1px solid #ddd;
}
.panel-container .form-separator{
    margin:0;
}
.heading-link{
    font-weight: 500;
}
.heading-link:after{
    position: relative;
    display: inline-block;
    content: '';
    margin-left: 10px;
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    border-left: 6px solid #e65100;
}
.accordion-toggle{
    display:inline-block;
    position:relative;
    margin-right: 15px;
}
.accordion-toggle:after{
    position: absolute;
    right: -15px;
    top:50%;
    transform: translateY(-50%);
    display: inline-block;
    content: '';
    margin-left: 10px;
    border-top: 6px solid #e65100;
    border-right: 6px solid transparent;
    border-left: 6px solid transparent;
}

.accordion-toggle.open:after{
    border-top: none;
    border-bottom: 6px solid #e65100;
}

.sprite_dollar:before, .sprite_autoPolicy:before, .sprite_homePolicy:before{
    content:"";
    display:block;
    float:left;
    left:0;
    position:absolute;
    top:0
}
.sprite_dollar:before{
    background-position:3px -286px;
    height:30px;
    width:25px
}
.sprite_autoPolicy:before{
    background-position:-71px -286px;
    height:30px;
    width:30px
}
.sprite_homePolicy:before{
    background-position:-102px -286px;
    height:30px;
    width:29px
}

@media (max-width: 549px){
    .short-panel .text-right{
        text-align: left;
    }

    .short-panel .mobile-right{
      text-align: right;
    }

    .business-accounts-header{
        margin-bottom: 10px;
    }
    .short-panel .btn{
        margin-bottom:10px;
        display:block;
        width: 100%;
        max-width:100%;
    }
    .short-panel .btn:last-of-type{
        margin-bottom: 0;
    }
    .header-icon{
        padding-left:0;
    }
    .header-icon.before-sprite{
        padding-left: 35px;
    }
}
