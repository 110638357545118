//  _____ _ _ _ ____  _____ 
// |   | | | | |    \|   __|
// | | | | | | |  |  |__   |
// |_|___|_____|____/|_____|
//
// NATIONWIDE DESIGN SYSTEM
// --------------------------------------------------
// Utilities + Helpers
//


//.nw-block { display:block; }
.nw-clean { margin:0; padding:0; }

//
// Padding + Marg ins
//

// Equal padding all around
.nw-inner { padding: $spacing; }
.nw-inner--sm { padding: $spacing-sm; }
.nw-inner--lg { padding: $spacing-lg; }
.nw-inner--xl { padding: $spacing-xl; }


// Padding on bottom
.nw-inner-bottom { padding-bottom: $spacing !important; }
.nw-inner-bottom--sm { padding-bottom: $spacing-sm !important; }
.nw-inner-bottom--lg { padding-bottom: $spacing-lg !important; }
.nw-inner-bottom--xl { padding-bottom: $spacing-xl !important; }


// Padding on top
.nw-inner-top { padding-top: $spacing !important; }
.nw-inner-top--sm { padding-top: $spacing-sm !important; }
.nw-inner-top--lg { padding-top: $spacing-lg !important; }
.nw-inner-top--xl { padding-top: $spacing-xl !important; }


// Padding on top+bottom only. Use on containers and grids.
.nw-inner-bun { 
    padding-top:$spacing;
    padding-bottom:$spacing;
}

.nw-inner-bun--sm { 
    padding-top:$spacing-sm;
    padding-bottom:$spacing-sm;
}

.nw-inner-bun--lg { 
    padding-top:$spacing-lg;
    padding-bottom:$spacing-lg;
}

.nw-inner-bun--xl { 
    padding-top:$spacing-xl;
    padding-bottom:$spacing-xl;
}



// Margin for bottom only
.nw-outer-bottom { margin-bottom: $spacing !important; }
.nw-outer-bottom--sm { margin-bottom: $spacing-sm !important; }
.nw-outer-bottom--lg { margin-bottom: $spacing-lg !important; }
.nw-outer-bottom--xl { margin-bottom: $spacing-xl !important; }

.nw-bottom-none {
    margin-bottom:0 !important;
}