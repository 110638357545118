//// Colors

// Gray
$gray: rgb(102,102,102);

$gray-light-1: rgb(218,221,221);
$gray-light-2: rgb(224,225,221);
$gray-light-3: rgb(239,240,238);
$gray-light-4: rgb(245,245,245);

$gray-dark-1: rgb(51, 51, 51);
$gray-dark-2: rgb(116, 118, 120);
$gray-dark-3: rgb(77, 79, 83);

// Blue
$blue: rgb(28,87,165);
$blue-bright: rgb(91,198,232);
$blue-light-1: rgb(214,241,249);
$blue-light-2: rgb(229,240,247);

$blue-icon: rgb(94, 155, 173);

// Green
$green: rgb(122,184,0);

// Orange
$orange-dark: rgb(230, 81, 0);

$orange-light: rgb(249, 159, 30);

// Red
$red: rgb(183,18,52);
$red-light-1: rgb(237,196,204);


//// Utility

// Breakpoints
$small: 48.13em; // 770px
$mobile-nav: 62.1875em;// 995px

// Colors for progress bar
$text: #333;
$heading: #5e9cae;

$blue: #005691;
$light-blue: rgba(94, 156, 174, 1);
$opaque-light-blue: rgba(94, 156, 174, 0.85);

$white: rgba(255, 255, 255, 1);
$opaque-white: rgba(229, 229, 229, 0.85);

$dark-gray: #4d4f53;
$light-gray: #E5E5E5;

$red: #b71234;

@mixin border-radius($radius) {
    border-top-left-radius: $radius;
    border-top-right-radius: $radius;
    border-bottom-left-radius: $radius;
    border-bottom-right-radius: $radius;
}
