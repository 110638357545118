//  _____ _ _ _ ____  _____
// |   | | | | |    \|   __|
// | | | | | | |  |  |__   |
// |_|___|_____|____/|_____|
//
// NATIONWIDE DESIGN SYSTEM
// --------------------------------------------------
// Breadcrumbs
//
//

.nw-breadcrumbs__current { font-weight:700; }

.nw-breadcrumbs__home {
    position:relative;
    padding-left:20px;
    display:inline-block;

    .nw-icon-home {
        position:absolute;
        left:0;
        top:-15px;
        font-size:18px;
    }
}
.breadcrumb {
  padding-left: 16%;
}
@media (max-width: 1220px){
    .breadcrumb {
     padding-left: 5px;
    }
}
@media (min-width: 2000px){
    .breadcrumb {
     padding-left: 30%;
    }
}
.nw-breadcrumbs {
    border-top: 1px solid #cccccc;
    font-size: 16px;
    margin-bottom: 20px;

    a,i { display:none; }

    a:first-of-type, // Fist link (home)
    i:first-of-type, // First chevron
    a:nth-of-type(2), // second link (collapse)
    i:nth-of-type(2), // second chevron
    a:nth-last-of-type(1), // last anchor before current
    i:last-of-type // Last chevron before current
        { display:inline-block; }
}


@include screensize($screen-sm) {
    .nw-breadcrumbs {
        a,i { display:inline-block; }

        a:nth-of-type(2), // hides collapse link
        i:nth-of-type(2) // hides collapse chevron
            { display:none; }
    }
}

/*
.nw-breadcrumbs {
    font-size: 16px;
    margin-bottom: 20px;
    margin-top:10px;

    a,i { display:none; }

    a:nth-last-of-type(2),
    i:nth-last-of-type(3),
    i:last-of-type
    {
        display:inline-block;
    }

    i:first-of-type,
    a:first-of-type { display:inline-block; }

    i:first-of-type { margin-right:20px; }

    a:first-of-type:after {
        content:"...";
        display:inline-block;
        position:absolute;
        left:45px;
        top:-15px;
    }

    .chevron:before {
        border-color: transparent transparent transparent #666;
    }
}

.nw-breadcrumbs--top { display:none; }

.nw-breadcrumbs__home {
    position:relative;
    padding-left:20px;
    display:inline-block;

    .nw-icon-home {
        position:absolute;
        left:0;
        top:-15px;
        font-size:18px;
    }
}

@include screensize($screen-xs) {
    .nw-breadcrumbs--top { display:block; }
}

@include screensize($screen-sm) {
    .nw-breadcrumbs {
        a,i { display:inline-block; }
        i:first-of-type { margin-right:5px; }
        a:first-of-type:after { display:none; content:""; }
    }
}

*/
