a{
    cursor: pointer;
}

a.underline {
    text-decoration: underline;
}
a.disable{
    pointer-events: none;
    cursor: not-allowed;
    color: unset;
}
.np-top{
    padding-top: 0!important;
    margin-top: 0!important;
}
.pad-bottom-10{
    padding-bottom: 10px;
}
.margin-bottom-20 {
    margin-bottom: 20px;
}
.margin-bottom-10 {
    margin-bottom: 10px;
}
.clearfix {
    clear: both;
    margin-bottom: 20px;
}
@media(max-width: 549px){
    .mobile-left{
        padding-left: 0!important;
    }
}
