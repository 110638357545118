//  _____ _ _ _ ____  _____ 
// |   | | | | |    \|   __|
// | | | | | | |  |  |__   |
// |_|___|_____|____/|_____|
//
// NATIONWIDE DESIGN SYSTEM
// --------------------------------------------------
// Button Groups
//
.btn-group .btn-default.active {
    color: #fff;
    background-color: #404040;
    border-color: #404040;   
}