//  _____ _ _ _ ____  _____ 
// |   | | | | |    \|   __|
// | | | | | | |  |  |__   |
// |_|___|_____|____/|_____|
//
// NATIONWIDE DESIGN SYSTEM
// --------------------------------------------------
// Inline Help
//


.nw-inline-help {
    font-size: 20px;
    color: $color-blue-accent;
    display: inline-block;
    position: relative;
    margin-left:4px; 
    top: 2px;
    
    &:hover {
        color: #333;
        text-decoration: none;
    }
    
    &:focus {
        color: $color-blue-accent;
        text-decoration: none;
    }
    
    // Show close X when content is open
    &:before { 
        display: inline;
        content:"\e916";
        position: relative;
        text-align:center;
    }
}

// Show question mark when content is collapsed
.nw-inline-help.collapsed {
    &:before {
        content:"\e915";
    }
}

// Light on dark
.nw-inline-help--inverse {
    color: #fff;
    
    &:hover {
        color: #d9e7ed;   
    }
    
    &:focus {
        color: #fff; 
    }
}


.nw-inline-help__content {
    display: inline-block;
    font-size: 16px;
    padding: $spacing;
    border-left: solid 1px #ccc;
    margin: 5px 0 10px 0;
}

// Limit width of content for wide screens
@include screensize($screen-md) {
    .nw-inline-help__content {
        max-width: 75%;   
    }
}


.nw-inline-help__content-container {
    max-height: 0px;
    width: 100%;
    overflow: hidden;
    transition-duration: 0.3s;
    transition-timing-function: linear;
    max-width: 700px;
}
    

    
.nw-inline-help__content-container.open {
      max-height:400px;
      max-width: 700px;
}