//
// NATIONWIDE BOOTSTRAP MIXINS
// --------------------------------------------------
// Make sure the mixin you're adding isn't a part of the base Bootstrap.
// DO NOT alter Bootstrap source files.
//


// Shortcut for media query
@mixin screensize($pixels) {
    @media (min-width: $pixels) { @content; }
}

@mixin block() {
    width:100%;
    display:block;
}


// Tints a color
@function tint($color, $percentage) {
  @return mix(white, $color, $percentage);
}


// Generates class names for colors with optional tints
@mixin color_family($base, $name:"", $tints:true) {
    .nw-bg-#{$name} { background:$base; @include text-contrast($base); }
    
    @if $tints {
        .nw-bg-#{$name}-25 { background:tint($base, 75%); @include text-contrast(tint($base, 75%)); }
        .nw-bg-#{$name}-50 { background:tint($base, 50%); @include text-contrast(tint($base, 50%)); }
        .nw-bg-#{$name}-75 { background:tint($base, 25%); @include text-contrast(tint($base, 25%)); }
    }
    
    .nw-fg-#{$name} { color:$base; }
    
    @if $tints {
        .nw-fg-#{$name}-25 { color:tint($base, 75%); }
        .nw-fg-#{$name}-50 { color:tint($base, 50%); }
        .nw-fg-#{$name}-75 { color:tint($base, 25%); }
    }
}

// Sets appropriate white or black foreground based on background color
// http://www.w3.org/TR/AERT#color-contrast
// http://codepen.io/davidhalford/pen/wlDxL
@mixin text-contrast($bg) {
  $color-brightness: round((red($bg) * 299) + (green($bg) * 587) + (blue($bg) * 114) / 1000);
  $light-color: round((red(#ffffff) * 299) + (green(#ffffff) * 587) + (blue(#ffffff) * 114) / 1000);
  
  @if abs($color-brightness) < ($light-color/2){
    color: #fff;
  }

  @else {
    color: #000;
  }
}
