.logo-container--pre, .logo-container--popup {
    border-radius: 8px;
    float: left;
    margin: 10px;
    padding: 5px 10px;
}
.logo-container--pre img, .logo-container--popup img {
    height: 51px;
    width: 229px;
}
.logo-container:hover, .logo-container:focus, .logo-container:active {
    background-color: #cccccc;
}
.logo-container--pre:hover, .logo-container--pre:focus, .logo-container--pre:active {
    background-color: #cccccc;
}

@media screen and (max-width: 32.5em) {
    .logo-container--pre {
        border-radius: 4px;
        height: 61px;
        margin: 5px;
        width: 195px;
    }
    .logo-container--pre img {
        height: 40px;
        width: 181px;
    }
}
