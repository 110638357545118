.mediabox {
  display: table;
  width: 100%;
}

.mediabox__body,
.mediabox__image {
  display: table-cell;
  vertical-align: middle;
  position: relative;
}

.mediabox__body {
  width: 100%;
}

.mediabox__image {
  padding-right: 10px;
}
.mediabox__image > img {
  display: block;
  max-width: 50px;
}
.mediabox--right .mediabox__image {
  padding-right: 0;
  padding-left: 10px;
}

.mediabox.mediabox--top .mediabox__image,
.mediabox.mediabox--top .mediabox__body {
  vertical-align: top;
}

.mediabox.mediabox--bottom .mediabox__image,
.mediabox.mediabox--bottom .mediabox__body {
  vertical-align: bottom;
}

 .document-list {
  margin: 20px;
  border: solid 1px #cccccc;
  border-bottom: none;
}

 .document-list .mediabox {
  padding: 10px;
  border-bottom: solid 1px #cccccc;
}

 .document-list .secondary-link {
  position: absolute;
  right: 0;
}
