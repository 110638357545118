
// Overrides
.accordion-container {
    margin: 0;
    background: rgb(255,255,255);
}
.claims-review-accordion .accordion:last-of-type {
    border-bottom: 3px solid #e1e1e1;
}


// Bootstrap Classes
.panel:last-of-type {
    border-bottom: 3px solid #e1e1e1;
}

.panel-title {
    font-family: 'proxima-nova', Arial, sans-serif;
    margin: 0;

    a {
        display: block;
        position: relative;
        width: 100%;
        padding: 10px 40px 10px 20px;
        margin: 0;
        cursor: pointer;
        background-color: transparent;
        background-image: none;
        border: 1px solid #e1e1e1;
        border-bottom: none;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }
}

.claims-active-accordion-tab {
    .accordion__arrow {
        display: block;
        position: absolute;
        top: 45%;
        right: 20px;
        border: 10px solid transparent;
        border-bottom-color: #1c57a5;
        border-top-color: transparent;
        transform: translateY(-50%);
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);

        @media (max-width: 37.5em) { // 600px
            margin-right: 0;
            border-width: 7px;
            right: 10px
        }
    }
}

.panel-collapse {
    border: 1px solid #e8e8e8;
    border-bottom: none;
    background: rgb(255,255,255);
}

.panel-body {
    position: relative;
    padding: 30px 20px;
    zoom: 1;
    ol {
        font-size: 1.13em;
        font-weight: bold;
        &:last-of-type {
            margin: 0;
        }
        li {
            margin-bottom: 16px;

            > * {
                font-weight: 400;
            }

            ul, ol {
                li {
                    margin-bottom: 5px;
                }
            }
            p {
                font-size: (18/18) + em;
                &:first-of-type {
                    margin: 0 0 20px;
                }
            }
        }
    }

    ul {
        margin: 0 0 20px;
        &:last-of-type {
            margin: 0;
        }
        li {
            list-style-type: disc;
            font-size: (18/18) + em;
            font-weight: 400;
            margin-bottom: 16px;
            &.no-list-style {
                list-style-type: none;
            }

            ul, ol {
                li {
                    margin-bottom: 5px;
                }
            }
            p {
                &:first-of-type {
                    margin: 0 0 20px;
                }
            }
        }
    }

    li span { // helper
        font-weight: 400;
    }
}


// Angular UI Classes
.collapse {
    display: none;
}

