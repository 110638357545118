@use "@nationwide-bolt/css-core/dist/scss/tokens" as Bolt;

@mixin font-variant {
    color: Bolt.$palette-charcoal;
    font-family: "nw-primary", "Gotham", sans-serif;
    font-size: 1.125em !important;
}

@mixin font-variant2 {
    font-size: 1.125em;
    line-height: 25px;
    font-weight: 700;
    font-family: "nw-primary", "Gotham", sans-serif;
}

@mixin font-variant3 {
    font-family: "nw-primary", "Gotham", sans-serif;
    font-weight: 400;
}

@mixin font-variant4 {
    color: Bolt.$palette-charcoal;
    font-family: "nw-primary", "Gotham", sans-serif;
    font-weight: 400;
}

* {
    font-family: "nw-primary", "Gotham", sans-serif;
    bolt-button {
        .bolt-button-wc--size-md{
            font-size: 1.125em !important;
        }
        .bolt-button-wc--primary.bolt-button {
            border: 1px solid Bolt.$palette-vibrantBlue !important;
            font-size: 1.125em !important;
            background-color: Bolt.$palette-vibrantBlue !important;
            color: Bolt.$palette-white;
        }
        .bolt-button-wc--secondary.bolt-button {
            border: 1px solid Bolt.$palette-vibrantBlue !important;
            color: Bolt.$palette-vibrantBlue !important;
            font-size: 1.125em !important;
        }
        .bolt-background-pale-gray-50 .bolt-button.bolt-button-wc--primary {
            border: 1px solid Bolt.$palette-vibrantBlue !important;
            font-size: 1.125em !important;
            background-color: Bolt.$palette-vibrantBlue !important;
            color: Bolt.$palette-white;
        }
    }
}

.other-passenger-detail-container {
    .inputfieldchange {
        .bolt-textfield-wc--label.bolt-textfield {
            @include font-variant2();
        }
    }
    .phone-type.custom-phone {
        .bolt-select-wc--label.bolt-select {
            @include font-variant2();
            margin-top: 0;
            color: Bolt.$palette-white;
        }
        .bolt-select-wc--input.bolt-select {
            @include font-variant4();
            font-size: 1.125em;
            line-height: 25px;
        }
    }
}

.drive-in-inspection-container {
    .search-autocomplete {
        .bolt-textfield-wc--label.bolt-textfield {
            @include font-variant2;
        }
        .bolt-textfield-wc--input__width-full.bolt-textfield {
            @include font-variant3;
            font-size: 1.125em;
            color: Bolt.$palette-darkGray;
        }
    }
    .bolt-tab-group-wc--label {
        &.bolt-tab-group {
            @include font-variant3;
            color: Bolt.$palette-white;
            padding-left: 3rem;
            padding-right: 3rem;
            opacity: 1;
            font-size: 1.125em;
            @media screen and (max-width: 500px) {
                font-size: 16px;
            }
        }
        &.bolt-tab-group[aria-selected="true"] {
            color: Bolt.$palette-vibrantBlue;
            background: Bolt.$palette-white;
        }
    }
    .bolt-tab-group-wc--label-container__block {
        &.bolt-tab-group {
            background: transparent;
            height: 0;
        }
    }
    .bolt-icon-wc--size-md.bolt-icon {
        height: 16px;
        width: 16px;
        color: white;
    }

    .bolt-tab-group-wc--panels {
        &.bolt-tab-group {
            background: transparent;
            bolt-tab {
                border: none;
            }
        }
    }
    .bolt-tab-group {
        .bolt-button-wc {
            &.bolt-button {
                border: 1px solid Bolt.$palette-white;
                span {
                    &.bolt-button {
                        @include font-variant3;
                        color: Bolt.$palette-white;
                        font-size: 22px;
                        @media (max-width: 500px) {
                            font-size: 14px;
                        }
                    }
                }
            }
        }
    }
    .additional-options {
        bolt-accordion[active] {
            box-shadow: none;
        }
        .bolt-accordion-wc--header {
            &.bolt-accordion {
                @include font-variant3;
                font-size: 1.125em;
                color: Bolt.$palette-vibrantBlue;
                padding: 0 20px;
                @media screen and (min-width: 1200px) {
                    padding: 0;
                }
                &:focus,
                &:hover,
                &:active {
                    box-shadow: none;
                    background: transparent;
                }
                @media screen and (min-width: 1200px) {
                    margin-left: auto;
                    margin-right: auto;
                    width: 900px;
                }
            }
        }
        .bolt-tab-group {
            .bolt-button-wc {
                &.bolt-button {
                    border: 1px solid Bolt.$palette-vibrantBlue;
                    span {
                        &.bolt-button {
                            color: Bolt.$palette-vibrantBlue;
                            font-size: 22px;
                        }
                    }
                }
            }
        }
        .bolt-background-white {
            background-color: transparent;
            box-shadow: none;
            margin: 0;
        }
        .bolt-tab-group-wc--label {
            &.bolt-tab-group {
                color: Bolt.$palette-charcoal;
            }
        }
    }
    .bolt-tab-group-wc--header__left-bg,
    .bolt-tab-group-wc--header__right-bg {
        &.bolt-tab-group {
            background-image: none !important;
        }
    }
}

.other-vehicle-detail-container {
   .black-label{
       .bolt-select-wc--label.bolt-select{
        color: Bolt.$palette-charcoal !important;
       }
    }
    .other-vehicle {
        .bolt-radio-group-wc--legend.bolt-radio-group {
            padding-bottom: 1rem;
            font-size: 24px;
            line-height: 32px;
            @include font-variant4();
            @media screen and (min-width: 500px) {
                font-size: 32px;
                line-height: 42px;
            }
        }
        .bolt-radio-wc--label.bolt-radio span.bolt-radio {
            @include font-variant4();
            font-size: 1.125em;
            line-height: 25px;
        }
    }
    .btn-radio-group {
        .bolt-radio-group-wc--legend.bolt-radio-group {
            padding-bottom: 1rem;
            font-size: 16px;
            line-height: 24px;
            @include font-variant();
            font-weight: 700;
            @media screen and (min-width: 500px) {
                font-size: 1.125em;
                line-height: 24px;
                padding-top: 0.5rem;
            }
        }
        .bolt-radio-wc--label.bolt-radio span.bolt-radio {
            @include font-variant4();
            font-size: 1.125em;
            line-height: 25px;
        }
    }
}

.other-vehicle-info-form {
    .bolt-textfield-wc--label {
        &.bolt-textfield {
            @include font-variant;
            font-size: 1.125em;
            font-weight: 700;
        }
    }
}

.accident-location-form {
    bolt-autocomplete,
    bolt-autocomplete *.bolt-autocomplete,
    .bolt-textfield-wc--label.bolt-textfield {
        .bolt-textfield-wc--label.bolt-textfield {
            font-size: 1.125em;
            line-height: 25px;
            font-weight: 700;
            font-family: "nw-primary", "Gotham", sans-serif;
            color: Bolt.$palette-white;
        }
        .bolt-textfield-wc--input__width-full.bolt-textfield {
            font-size: 1.125em;
            line-height: 25px;
            font-weight: 400;
            @include font-variant;
        }

        .bolt-field-error-wc--message.bolt-field-error{
            color: Bolt.$palette-white;
        }
    }

    .address-line-2,
    .city-name,
    .zip-code-number {
        .bolt-field-error-wc--message.bolt-field-error{
            color: Bolt.$palette-white;
        }
        .bolt-textfield-wc--label.bolt-textfield {
            font-size: 1.125em;
            line-height: 25px;
            font-weight: 700;
            font-family: "nw-primary", "Gotham", sans-serif;
            color: Bolt.$palette-white;
        }
        .bolt-textfield-wc--input__width-full.bolt-textfield {
            font-size: 1.125em;
            line-height: 25px;
            font-weight: 400;
            @include font-variant;
        }
    }

    .state-details {
        .bolt-select-wc--label.bolt-select {
            font-size: 1.125em;
            line-height: 25px;
            font-weight: 700;
            font-family: "nw-primary", "Gotham", sans-serif;
            color: Bolt.$palette-white;
        }
    }
}

.oys-shop-card-container {
    .bolt-button-wc--secondary {
        &.bolt-button {
            border: none;
        }
    }
    .bolt-button-wc {
        &.bolt-button {
            span {
                &.bolt-button {
                    font-size: 14px;
                    font-weight: 400;
                    @media screen and (min-width: 1200px) {
                        font-size: 1.125em;
                    }
                }
            }
        }
    }
}

.occupant-detail-auth-container {
    .occupant-class {
        .bolt-radio-group-wc--legend.bolt-radio-group {
            font-family: "nw-primary", "Gotham", sans-serif;
            font-size: 1.125em;
            line-height: 20px;
            font-weight: 700;
            color: Bolt.$palette-charcoal;
            @media screen and (max-width: 500px) {
                line-height: 25px;
                color: Bolt.$palette-charcoal;
            }
        }
        .bolt-radio-wc--label.bolt-radio span.bolt-radio {
            @include font-variant;
            font-size: 1.125em;
            line-height: 25px;
            font-weight: 400;
        }
    }

    .occupant-id {
        .bolt-radio-group-wc--legend.bolt-radio-group {
            color: Bolt.$palette-charcoal;
            font-size: 1.125em;
            line-height: 25px;
            font-weight: 700;
        }
        .bolt-radio-wc--label.bolt-radio span.bolt-radio {
            @include font-variant;
            font-size: 1.125em;
            line-height: 25px;
            font-weight: 400;
        }
    }

    .persons-class {
        .bolt-textfield-wc--label.bolt-textfield {
            color: Bolt.$palette-white;
            font-family: "nw-primary", "Gotham", sans-serif;
            font-size: 1.125em;
            line-height: 25px;
            font-weight: 700;

        }

        .bolt-textfield-wc--input__width-full.bolt-textfield {
            color: Bolt.$palette-charcoal;
            @include font-variant;
            font-size: 1.125em;
            line-height: 25px;
            font-weight: 400;
        }
        .bolt-select-wc--input-container.bolt-select {
            @include font-variant4;
            font-size: 1.125em;
            line-height: 25px;
        }
        .bolt-select-wc--input.bolt-select {
            @include font-variant4;
            font-size: 1.125em;
            line-height: 25px;
        }
    }

    .phone-select {
        width: 100%;
        .bolt-select-wc--label.bolt-select {
            color: Bolt.$palette-white;
        }
    }
}

.cause-details-select {
    bolt-textfield {
        .bolt-textfield-wc--input-container.bolt-textfield {
            width: 50%;
            @media screen and (max-width: 500px) {
                width: 100%;
            }
        }
    }
    bolt-select {
        .bolt-select-wc--label.bolt-select {
            @include font-variant;
            font-size: 1.125em;
            font-weight: 700;
            line-height: 25px;
        }

        .bolt-select-wc--input-container.bolt-select {
            width: 50%;
            @media screen and (max-width: 500px) {
                width: 100%;
            }
        }
        .bolt-select-wc--input {
            &.bolt-select {
                @include font-variant;
                background: Bolt.$palette-paleGray25;
                option {
                    &.bolt-select {
                        @include font-variant;
                        background: Bolt.$palette-paleGray25;
                    }
                }
            }
        }
        .bolt-select-wc--input.bolt-select {
            width: inherit;
        }
    }
}

.avf1-form {
    bolt-select[disabled] {
        display: none;
    }
}

.avm1-form {
    bolt-radio-group {
        .bolt-radio-group-wc--legend {
            &.bolt-radio-group {
                @include font-variant;
            }
        }
    }
}

.other-parties-accident-container {
    .vehicle-que {
        width: 50%;
        @media screen and (max-width: 500px) {
            width: 100%;
        }
        .bolt-select-wc--label.bolt-select {
            @include font-variant;
            font-size: 1.125em;
            font-weight: 700;
            line-height: 25px;
        }
        .bolt-select-wc--input.bolt-select,
        .bolt-select-wc--input.bolt-select option.bolt-select {
            background: Bolt.$palette-paleGray25;
            @include font-variant3;
            @include font-variant;
            font-size: 1.125em;
            font-weight: 400;
            line-height: 25px;
        }
    }

    .anyone-injury {
        .bolt-radio-group-wc--legend.bolt-radio-group,
        .bolt-radio-wc--label.bolt-radio span.bolt-radio {
            @include font-variant;
            font-size: 1.125em;
            line-height: 25px;
            font-weight: 400;
        }
    }
    .anyone-injury.set-background {
        background-color: Bolt.$palette-paleGray25;
        color: Bolt.$palette-charcoal;
    }
    .anyone-injury.set-margin {
        margin-top: 2rem;
    }
    .anyone-injury.color-change {
        margin-top: -1.5em;
        .bolt-radio-group-wc--legend.bolt-radio-group {
            color: Bolt.$palette-white;
            @include font-variant2;
            font-family: "nw-primary", "Gotham", sans-serif;
        }
        .bolt-radio-wc--label.bolt-radio span.bolt-radio {
            color: Bolt.$palette-white;
            font-size: 1.125em;
            line-height: 25px;
            font-weight: 400;
            font-family: "nw-primary", "Gotham", sans-serif;
        }
    }
}

.other-party-injured {
    .remove-icon-class {
        .bolt-icon-wc--size-md.bolt-icon {
            height: 27px;
            width: 15px;
        }
    }
    .person-values {
        .bolt-radio-group-wc--legend.bolt-radio-group {
            @include font-variant;
            @include font-variant2;
        }
        .bolt-radio-wc--label.bolt-radio span.bolt-radio {
            @include font-variant;
            font-size: 1.125em;
            line-height: 25px;
            font-weight: 400;
        }
    }

    .person-form-fields {
        .bolt-textfield-wc--label.bolt-textfield {
            font-size: 1.125em;
            line-height: 25px;
            font-weight: 700;
            font-family: "nw-primary", "Gotham", sans-serif;
            color: Bolt.$palette-white;
        }
        .input {
            font-size: 1.125em;
            line-height: 25px;
            font-weight: 400;
            font-family: "nw-primary", "Gotham", sans-serif;
            width: 50%;
            @media screen and (max-width: 500px) {
                width: 100%;
            }
        }
    }

    .phoneTypeDropDown {
        .bolt-select-wc--label.bolt-select {
            color: Bolt.$palette-white;
            font-family: "nw-primary", "Gotham", sans-serif;
            font-size: 1.125em;
            font-weight: 700;
            line-height: 25px;
        }
        .bolt-select-wc--input.bolt-select,
        .bolt-select-wc--input.bolt-select option.bolt-select {
            background: Bolt.$palette-paleGray25;
            @include font-variant3;
            @include font-variant;
            font-size: 1.125em;
            font-weight: 400;
            line-height: 25px;
            height: 3rem;
        }
    }
}

.areas-of-damage-container {
    .damaged-area-chkbox-grp {
        .bolt-checkbox,
        .bolt-checkbox *.bolt-checkbox {
            font-size: 1.125em;
            line-height: 25px;
            @include font-variant();
            @include font-variant3();
        }
    }

    .damage-que {
        .bolt-radio-group-wc--legend.bolt-radio-group {
            @include font-variant;
            @include font-variant2;
            @media screen and (max-width: 500px) {
                font-size: 16px;
                line-height: 20px;
                color: Bolt.$palette-charcoal;
                font-family: "nw-primary", "Gotham", sans-serif;
                font-weight: 700;
            }
        }
        .bolt-radio-wc--label.bolt-radio span.bolt-radio {
            @include font-variant;
            font-size: 1.125em;
            line-height: 25px;
            font-weight: 400;
        }
    }
}

.claim-list-top-section {
    .claims,
    .submitted-claim-section {
        .bolt-button-wc {
            &.bolt-button {
                span {
                    &.bolt-button {
                        color: Bolt.$palette-vibrantBlue;
                        font-size: 14px;
                    }
                }
            }
        }
    }
    .claim-section {
        .bolt-button-wc {
            &.bolt-button {
                span {
                    &.bolt-button {
                        color: Bolt.$palette-white;
                        font-size: 14px;
                    }
                }
            }
        }
    }
    .submitted-claim-section {
        .bolt-button-wc {
            &.bolt-button {
                span {
                    &.bolt-button {
                        font-size: 1.125em;
                        @media screen and (min-width: 1200px) {
                            font-size: 14px;
                        }
                    }
                }
            }
        }
    }
}

.additional-details-container {
    margin: 1.5rem auto;
    .airbagsDeploy,
    .safeToDriveOption,
    .incidentOccurred-option,
    .policeReport-option,
    .childpassenger-option,
    .childataccident-option,
    .carseatdamagedOption {
        .bolt-radio-group-wc--legend.bolt-radio-group {
            @include font-variant;
            @include font-variant2;
            @media screen and (max-width: 500px) {
                font-size: 16px;
                line-height: 20px;
            }
        }
        .bolt-radio-wc--label.bolt-radio span.bolt-radio {
            @include font-variant;
            font-size: 1.125em;
            line-height: 25px;
            font-weight: 400;
        }
    }

    .dept-police {
        margin-top: 0.75rem;
        .bolt-textfield-wc--label.bolt-textfield {
            font-size: 1.125em;
            line-height: 25px;
            font-weight: 700;
            font-family: "nw-primary", "Gotham", sans-serif;
            color: Bolt.$palette-charcoal !important;
        }
        .bolt-textfield-wc--input-container.bolt-textfield {
            width: 50%;
            @media screen and (max-width: 500px) {
                width: 100%;
            }
        }
        .input {
            font-size: 1.125em;
            line-height: 25px;
            font-weight: 400;
            font-family: "nw-primary", "Gotham", sans-serif;
        }
    }
    .distance-miles {
        margin-top: 0.75rem;
        .bolt-textfield-wc--label.bolt-textfield {
            font-size: 1.125em;
            line-height: 25px;
            font-weight: 700;
            font-family: "nw-primary", "Gotham", sans-serif;
            color: Bolt.$palette-white !important;
        }
        .bolt-textfield-wc--input-container.bolt-textfield {
            width: 50%;
            @media screen and (max-width: 500px) {
                width: 100%;
            }
        }
        .input {
            font-size: 1.125em;
            line-height: 25px;
            font-weight: 400;
            font-family: "nw-primary", "Gotham", sans-serif;
        }
    }
}

.claim-list-page-container {
    .coverage-overview {
        .iconInsideCircle {
            .bolt-icon-wc--size-md.bolt-icon {
                width: 32px;
                height: 24px;
            }
        }
        .policy-details {
            .bolt-button-wc {
                &.bolt-button {
                    span {
                        &.bolt-button {
                            color: Bolt.$palette-vibrantBlue;
                            font-size: 14px;
                        }
                    }
                }
            }
        }
    }
    .more-assistance {
        .bolt-tab-group-wc--label.bolt-tab-group {
            font-size: 1.125em;
            line-height: 24px;
            font-size: 700;
            color: Bolt.$palette-vibrantBlue;
            font-family: "nw-primary", "Gotham", sans-serif;
        }
        .bolt-accordion {
            font-size: 14px;
        }
        .bolt-accordion-wc--header {
            &.bolt-accordion {
                button {
                    &.bolt-accordion {
                        font-size: 1.125em;
                    }
                }
            }
        }
    }
    .bolt-tab-group-wc--header__left-bg,
    .bolt-tab-group-wc--header__right-bg {
        background: transparent;
    }
}

.accident-location-container {
    margin: 1.5rem auto;
    .loc-radio {
        .bolt-radio-wc--label.bolt-radio span.bolt-radio {
            @include font-variant;
            @include font-variant3;
            font-size: 1.125em;
            line-height: 25px;
        }
    }
}

.towing-options-container {
    .bolt-notification-wc--text.bolt-notification,
    .bolt-notification-wc--text.bolt-alert {
        font-weight: 400;
        font-size: 1.125em;
        line-height: 25px;
        @include font-variant();
    }
}

.borrowed-vehicle,
.new-vehicle {
    bolt-textfield {
        .bolt-textfield-wc--input-container.bolt-textfield {
            width: 100%;
        }
    }
    bolt-select {
        .bolt-select-wc--input-container.bolt-select {
            width: 100% !important;
        }
    }
}

.point-of-contact-v2 {
    bolt-select {
        .bolt-select-wc--input-container.bolt-select {
            width: 50%;
            @media screen and (max-width: 500px) {
                width: 100%;
            }
        }
    }
}

.member-no-login {
    bolt-textfield {
        font-size: 1.125em;
        .bolt-textfield-wc--label.bolt-textfield {
            font-size: 1.125em;
            line-height: 25px;
            font-weight: 700;
            font-family: "nw-primary", "Gotham", sans-serif;
            color: Bolt.$palette-white;
        }

        .bolt-textfield-wc--input__width-full.bolt-textfield {
            font-size: 1.125em;
            line-height: 25px;
            font-weight: 400;
            @include font-variant;
        }

    }
    .bolt-textfield-wc--label.bolt-date-picker {
        color: Bolt.$palette-white;
    }
    bolt-date-picker{
        font-size: 1.125em;
    }
}

.nonmember-form {
    .policy-number {
        .bolt-textfield-wc--input-container.bolt-textfield {
            font-size: 1.125em;
            @media screen and (min-width: 1200px) {
                width: 85%;
            }
        }
    }
    bolt-textfield {
        .bolt-textfield-wc--label.bolt-textfield {
            font-size: 1.125em;
            line-height: 25px;
            font-weight: 700;
            font-family: "nw-primary", "Gotham", sans-serif;
            color: Bolt.$palette-white;
        }

        .bolt-textfield-wc--input__width-full.bolt-textfield {
            font-size: 1.125em;
            line-height: 25px;
            font-weight: 400;
            @include font-variant;
        }
    }
}

.identification-wrapper {
    bolt-select {
        .bolt-select-wc--label.bolt-select {
            font-size: 1.125em;
            line-height: 25px;
            font-weight: 700;
            font-family: "nw-primary", "Gotham", sans-serif;
            color: Bolt.$palette-white;
        }

        .bolt-select-wc--input.bolt-select,
        .bolt-select-wc--input.bolt-select option.bolt-select {
            background: Bolt.$palette-paleGray25;
            @include font-variant3;
            @include font-variant;
            font-size: 1.125em;
            font-weight: 400;
            line-height: 25px;
        }
    }
}

.gpp-without-support-container {
    bolt-radio-group {
        .yes-no-radiogroup {
            .bolt-radio-wc--label.bolt-radio span.bolt-radio {
                @include font-variant;
                @include font-variant3;
                font-size: 1.125em;
                line-height: 25px;
            }
        }
    }
}

.customer-feedback-container {
    .text-area-style {
        .bolt-textarea-wc--input__width-full.bolt-textarea {
            width: 80%;
            @media screen and (max-width: 1000px) {
                width: 90%;
            }
            @media screen and (max-width: 560px) {
                width: 100%;
            }
        }
        .bolt-textarea-wc--label.bolt-textarea {
            @include font-variant2();
            color: Bolt.$palette-charcoal;
        }
    }
    .bolt-component-style {
        font-size: .85em;
        .bolt-radio-group-wc--legend.bolt-radio-group {
            @include font-variant2();
            color: Bolt.$palette-charcoal;
        }
        .bolt-radio-wc--label.bolt-radio span.bolt-radio {
            @include font-variant4();
            color: Bolt.$palette-charcoal;
            font-size: 1.125em;
            line-height: 25px;
        }
    }
}
.error-handler-container {
    .bolt-icon.bolt-icon-wc--color-orange g,
    .bolt-icon.bolt-icon-wc--color-orange path,
    .bolt-icon.bolt-icon-wc--color-orange rect,
    .bolt-icon.bolt-icon-wc--color-orange circle {
        fill: Bolt.$palette-sunburstOrange;
    }
}
.other-vehicle-damage-container {
    bolt-select {
        .bolt-select-wc--input-container.bolt-select {
            width: 50%;
            @media screen and (max-width: 500px) {
                width: 100%;
            }
        }
    }
}

.coverage-details-container {
    .car-tyle {
        .bolt-icon-wc.bolt-icon {
            height: 88px;
            width: 88px;
            margin-left: 1rem;
            margin-top: 3.25rem;
            @media screen and (max-width: 1200px) {
                margin-left: 2.5rem;
                margin-top: 3rem;
            }
            @media screen and (max-width: 767px) {
                margin-left: 0.25rem;
                margin-top: auto;
            }
            @media screen and (max-width: 500px) {
                margin-left: 0.25rem;
                margin-top: auto;
                height: 40px;
                width: 40px;
            }
        }
    }
}

.other-vehicle-information-container {
    .textfield-style {
        .bolt-textfield-wc--label.bolt-textfield {
            @include font-variant2();
        }
    }
    .select-style {
        .bolt-select-wc--label.bolt-select {
            @include font-variant2();
            color: Bolt.$palette-charcoal !important;
        }
    }
}

.other-damages-parent-container {
    margin: 1.5rem auto 0;
    .any-other-damages {
        .bolt-radio-wc--label.bolt-radio span.bolt-radio {
            font-size: 1.125em;
            line-height: 25px;
        }
    }
}
