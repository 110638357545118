//  _____ _ _ _ ____  _____ 
// |   | | | | |    \|   __|
// | | | | | | |  |  |__   |
// |_|___|_____|____/|_____|
//
// NATIONWIDE DESIGN SYSTEM
// --------------------------------------------------
// Tables
//



.nw-table-subheader {
    background-color: #b4d0db !important;
    font-weight: 700;
    
    a {
        color:#333; 
        text-decoration: underline;
    
        &:hover {
            color: darken(#333, 15%);   
        }
    }
}
