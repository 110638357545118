//  _____ _ _ _ ____  _____
// |   | | | | |    \|   __|
// | | | | | | |  |  |__   |
// |_|___|_____|____/|_____|
//
// NATIONWIDE DESIGN SYSTEM
// --------------------------------------------------
/*
    Bolt - Nationwide Design System
    See changelog.md for current version and history
*/
//
// NEVER alter any Bootstrap code or files.
// Use nw-variables.scss to set new values.
// See nw-theme.scss for files that override vanilla Bootstrap rules.
// Prefix any new style rules with nw-*
//


// Bootstrap v3.3.5 (http://getbootstrap.com)
// Copyright 2011-2015 Twitter, Inc.
// Licensed under MIT (https://github.com/twbs/./bootstrap/blob/master/LICENSE)

// Core variables and mixins
@import "nw-variables"; // Our variable overrides
@import "../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/variables";
@import "../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/mixins";
@import "nw-mixins"; // Our custom mixins

// Reset and dependencies
@import "../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/normalize";
@import "../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/print";

// Core CSS
@import "../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/scaffolding";
@import "../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/type";
@import "../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/code";
@import "../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/grid";
@import "../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/tables";
@import "../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/forms";
@import "../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/buttons";

// Components
@import "../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/component-animations";
@import "../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/dropdowns";
@import "../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/button-groups";
@import "../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/input-groups";
@import "../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/navs";
@import "../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/breadcrumbs";
@import "../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/pagination";
@import "../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/pager";
@import "../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/labels";
@import "../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/badges";
@import "../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/alerts";
@import "../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/progress-bars";
@import "../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/media";
@import "../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/list-group";
@import "../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/panels";
@import "../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/wells";
@import "../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/close";

// Components w/ JavaScript
@import "../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/modals";

// Utility classes
@import "../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/utilities";
@import "../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/responsive-utilities";


// Theme overrides
@import "nw-theme";

// Custom
@import "nw-buttons";
@import "nw-colors";
@import "nw-grid";
@import "nw-forms";
@import "nw-icons";
@import "nw-inline-help";
@import "nw-type";
@import "nw-utilities";
@import "nw-responsive-utilities";
@import "nw-accordion";
@import "nw-breadcrumbs";
@import "nw-tables";
@import "nw-navs";
@import "nw-modals";
@import "nw-toggle-button";
@import "nw-slider-input";
@import "nw-timepicker";
@import "nw-datepicker";

// Only include the following if you're using local font files
@import "nw-fonts";
