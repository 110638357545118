footer.claims-tcr-footer {
  max-width: 75em;
  margin: 0 auto;
  transition: .1s all ease;
  -webkit-transition: .1s all ease;
  -moz-transition: .1s all ease;
  background: rgb(218, 218, 218);
  clear: both;
  text-align: left;
  position: relative;
  padding: 20px;

  @media (max-width: 995px) and (min-width: 0) {
    text-align: center;
  }
  @media (max-width: 50em) {
    // 800px
    padding: 10px;
  }
  @media (max-width: 33em) {
    // 528px
    padding: 5px;
  }

  .icons-logo {
    background-image: url(//static.nationwide.com/static/icons.png?r=50);
    background-repeat: no-repeat;
    background-position: 0 -2308px;
    height: 61px;
    width: 191px;
    text-indent: -9999px;
    overflow: hidden;
    &:hover {
      background-color: rgb(204, 204, 204);
      border-radius: 5px;
      text-decoration: underline;
    }
  }

  hr {
    clear: both;
    box-sizing: content-box;
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #CCC !important;
    margin: 1em 0;
    padding: 0;
    font-weight: inherit;
    font-style: inherit;
    font-size: 100%;
    font-family: inherit;
    vertical-align: baseline;
  }

  .footer__contact {
    position: relative;
    float: left;
    display: inline-block;
    margin-bottom: 10px;
    font-size: 1.125em;
    @media (max-width: 995px) and (min-width: 0) {
      float: none;
    }

    .contact {
      display: inline-block;
      position: relative;
      top: 4px;
      margin: 0 20px 8px;
    }

    .contact-small {
      font-size: .75em;
      white-space: nowrap;
    }

    a {
      position: relative;
      padding: 10px 0;
      vertical-align: middle;
      display: inline-block;
    }
  }

  // Social Media
  .footer__socialMedia {
    position: absolute;
    display: inline-block;
    margin-top: 20px;
    right: 20px;
    @media (max-width: 75em) {
      right: 10px;
    }
    @media (max-width: 995px) and (min-width: 0) {
      position: relative;
      display: block;
      margin-top: 0;
      right: 0;
    }

    a:first-child {
      margin-left: 0;
    }
    a {
      margin-left: 10px;
    }
  }
  .icons-facebook,
  .icons-twitter,
  .icons-gplus,
  .icons-youtube,
  .icons-flickr {
    background-image: url(//static.nationwide.com/static/icons.png?r=50);
    background-repeat: no-repeat;
    text-indent: -9999px;
    display: inline-block;
    overflow: hidden;
  }

  .icons-facebook {
    background-position: 0 -1326px;
    height: 28px;
    width: 28px;
    &:hover {
      background-position: 0 -1374px;
    }
  }
  .icons-twitter {
    background-position: 0 -4149px;
    height: 28px;
    width: 28px;
    &:hover {
      background-position: 0 -4198px;
    }
  }
  .icons-gplus {
    background-position: 0 -1742px;
    height: 28px;
    width: 28px;
    &:hover {
      background-position: 0 -1790px;
    }
  }
  .icons-youtube {
    background-position: 0 -4280px;
    height: 28px;
    width: 28px;
    &:hover {
      background-position: 0 -4329px;
    }
  }
  .icons-flickr {
    background-position: 0 -1606px;
    height: 28px;
    width: 28px;

    &:hover {
      background-position: 0 -1654px;
    }
  }

  .footer__links {
    display: inline-block;
    margin: 30px 0;
    padding: 0;
    float: left;
    @media (max-width: 995px) and (min-width: 0) {
      display: block;
      float: none;
    }

    li:first-child {
      border-left: none;
      padding-left: 0;
    }
    li {
      display: inline-block;
      padding: 4px 12px;
      border-left: 1px dotted #ACACAC;
    }
  }

  .footer__imageLinks {
    float: right;
    margin: 10px 0 10px 10px;
    @media (max-width: 995px) and (min-width: 0) {
      float: none;
    }

    a:hover {
      background-color: rgb(204, 204, 204);
      border-radius: 5px;
      text-decoration: underline;
    }
  }

  .icons-equalHousing,
  .icons-fdic {
    background-image: url(//static.nationwide.com/static/icons.png?r=50);
    background-repeat: no-repeat;
    text-indent: -9999px;
    display: inline-block;
    overflow: hidden;
  }
  .icons-equalHousing {
    background-position: 0 -1144px;
    height: 44px;
    width: 76px;
  }
  .icons-fdic {
    background-position: 0 -1422px;
    height: 44px;
    width: 110px;
  }

  .footer__disclaimer {
    margin-bottom: 10px;
    font-size: .8em;

    p {
      margin-bottom: 20px;
    }
  }

  .footer__secondaryLinks {
    min-height: 100px;
    margin-bottom: 0;
    padding: 0;

    li:first-child {
      border-left: none;
      padding-left: 0;
    }
    li {
      display: inline-block;
      padding: 0 0 0 12px;
      border-left: 1px solid #666;
      margin-right: 12px;
      font-size: .875em;
    }
  }
}
