//  _____ _ _ _ ____  _____ 
// |   | | | | |    \|   __|
// | | | | | | |  |  |__   |
// |_|___|_____|____/|_____|
//
// NATIONWIDE DESIGN SYSTEM
// --------------------------------------------------
// Bootstrap Navs
//




//
// Tabs
//
.nav-tabs { padding-left:8px; }

.nav-tabs > li { 
    margin-right:5px;
}

.nav-tabs > li a {
    background-color:$nav-tabs-bg-color; 
    border-bottom:solid 1px $nav-tabs-border-color;
    color: $color-blue;
    font-size: $nav-tabs-text;
    
    &:hover {
        border:solid 1px $nav-tabs-border-color;
        background-color:darken($nav-tabs-bg-color, 5%);
    }
}

@include screensize($screen-md) {
    .nav-tabs > li a { font-size: $nav-tabs-text-lg; }
    
    .nav-tabs > li { 
        margin-right:10px;
    }
}


.tab-content {
    padding: $spacing;
    border: solid 1px $nav-tabs-border-color;
    border-top: none;
}


.nav-tabs--center > li, .nav-pills--center > li {
    float:none;
    display:inline-block;
    *display:inline; /* ie7 fix */
     zoom:1; /* hasLayout ie7 trigger */
}

.nav-tabs--center {
    text-align:center;
}