//  _____ _ _ _ ____  _____
// |   | | | | |    \|   __|
// | | | | | | |  |  |__   |
// |_|___|_____|____/|_____|
//
// NATIONWIDE DESIGN SYSTEM
// --------------------------------------------------
// Bootstrap Breadcrumbs
//

.breadcrumb {
    padding: 10px 0;
    background: none;
    padding-left: 5px;
}
