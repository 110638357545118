
.claims-conditional-content {
    display: none;
    font-size: 1.125em;
    line-height: 1.25em;
    margin: 10px 0 0;
    em {
        font-weight: bold;
        font-style: normal;
    }
}
