
.page-indicator {
    position: relative;
    padding: 20px 50px 0;
    background: rgb(255,255,255);
    border-top: #F2F2F2;

    @media (max-width: $small) {
        padding: 20px 20px 0;
    }
    @media (max-width: 26.25em) { // 420px
        padding: 20px 10px 0;
    }

    &.blue-bg {
        background: $blue-light-2;
    }

    h1 {
        font-family: 'proxima-nova', Arial, sans-serif;
        font-size: 2em;
        font-weight: 200;
        text-transform: none;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        margin: 0;
        padding: 0;
    }
}

.claims-track-page-title {
    position: relative;
    max-width: 900px;
    margin: 0 auto;
    padding: 10px 220px 40px 0;
    @media (max-width: $small) {
        padding: 10px 0 40px;
    }

    h1 {
        color: rgb(94, 156, 174);
    }

    small {
        font-size: 0.88em;
        color: rgb(102, 102, 102);
    }
}

.claims-track-page-title__claim-number {
    position: absolute;
    right: 0;
    top: 26px;
    font-family: 'proxima-nova', Arial, sans-serif;
    font-size: 1.125em;
    color: rgb(51,51,51);

    @media (max-width: $small) {
        position: relative;
        top: 10px;
        display: block;
    }

    em {
        font-weight: bold;
        font-style: normal;
    }
    span {
        display: block;
        text-align: right;

        @media (max-width: $small) {
            text-align: left;
        }
    }
}

.claims-file-page-title {
    position: relative;
    max-width: 900px;
    margin: 0 auto;
    padding: 0 0 24px;

    h1 {
        color: rgb(94, 156, 174);
    }
    small {
        font-size: 0.88em;
        color: rgb(102, 102, 102);
    }
    pre {
        font-family: 'proxima-nova', Arial, sans-serif;
        font-size: 17px;
        font-weight: 300;
        text-transform: uppercase;
        display: inline-block;
        vertical-align: top;
        margin: 0 0 2px;
        padding: 0;
        word-wrap: normal;
        white-space: normal;
    }
    pre:last-of-type {
        position: absolute;
        top: -4px;
        right: 0;
        font-size: 15px;
        text-transform: none;
    }
    pre .desktop-only {
        @media (max-width: 48.13em) { // 770px
            display: inline;
        }
        @media (max-width: 37.5em) { // 600px
            display: none;
        }
    }
    .nw-icon-lock:before {
        content: '';
        display: inline-block;
        height: 18px;
        width: 18px;
        background: url(/assets/images/claims/lock.svg) no-repeat 5px 2px transparent;
        background-size: 60%;
    }
}

.claims-tcr-page-title {
    position: relative;
    max-width: 900px;
    margin: 0 auto;
    padding: 11px 0 13px;

    h1 {
        color: rgb(94, 156, 174);
    }
    small {
        font-size: 0.88em;
        color: rgb(102, 102, 102);
    }
}

