//  _____ _ _ _ ____  _____ 
// |   | | | | |    \|   __|
// | | | | | | |  |  |__   |
// |_|___|_____|____/|_____|
//
// NATIONWIDE DESIGN SYSTEM
// --------------------------------------------------
// Grid and Containers
//


// Containers
.nw-container {
    max-width: $screen-max;
}

.nw-container-narrow {
    max-width: 1024px;
}


.nw-container-article {
    max-width: 829px;
}

.nw-container,
.nw-container-narrow,
.nw-container-article
    {
        margin:0 auto;
        padding-left: $container-edge-padding;
        padding-right: $container-edge-padding;        
    }


.nw-container--justify {
    margin:0;
}

.nw-container--clean {
    padding: 0;
}




// Add flex grid for grid wrapping. Reliable on IE10+
.nw-row--flex { 
    display: flex; 
    flex-wrap: wrap; 
}

.nw-row--flex [class*="col-"] { 
    vertical-align: top; 
}

@media (max-width: $screen-sm) {
    .nw-row--flex [class*="col-"] { 
        width:100%; 
    } 
}
