//  _____ _ _ _ ____  _____
// |   | | | | |    \|   __|
// | | | | | | |  |  |__   |
// |_|___|_____|____/|_____|
//
// NATIONWIDE DESIGN SYSTEM
// --------------------------------------------------
// Bootstrap Modals and Overlays
//

.modal-dialog {
    padding:20px;
    max-width:600px;
    width:auto;
}

.modal-header {
    padding: 10px 20px 10px 20px;

    .close {
        @include opacity(.4);
        font-weight: 400;
        font-size:36px;
    }
}


.modal-footer .btn + .btn { margin-left:0; } // This removes left margin given by modal-footer



.modal-title {
    font-size:22px;
}

.modal-content {
    position: relative;
    background-color: #fff;
    border: 1px solid #999;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
    background-clip: padding-box;
    outline: 0
}
.modal-backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1040;
    background-color: #000
}
.modal-backdrop.fade {
    opacity: 0;
    filter: alpha(opacity=0)
}
.modal-backdrop.in {
    opacity: .5;
    filter: alpha(opacity=50)
}
.modal-header {
    padding: 20px;
    border-bottom: 1px solid #e5e5e5
}
.modal-header:before,
.modal-header:after {
    content: " ";
    display: table
}
.modal-header:after {
    clear: both
}
.modal-header .close {
    margin-top: -2px
}
.modal-title {
    margin: 0;
    line-height: 1.44
}
.modal-body {
    position: relative;
    padding: 20px
}
.modal-footer {
    padding: 20px;
    text-align: right;
    border-top: 1px solid #e5e5e5
}
.modal-footer:before,
.modal-footer:after {
    content: " ";
    display: table
}
.modal-footer:after {
    clear: both
}
.modal-footer .btn+.btn {
    margin-left: 5px;
    margin-bottom: 0
}
.modal-footer .btn-group .btn+.btn {
    margin-left: -1px
}
.modal-footer .btn-block+.btn-block {
    margin-left: 0
}
.modal-scrollbar-measure {
    position: absolute;
    top: -9999px;
    width: 50px;
    height: 50px;
    overflow: scroll
}
.modal-text {
    text-align: center;
    font-family: "Proxima-Nova","Helvetica Neue",Helvetica,Roboto,Arial,sans-serif;
    font-size: 1.125em;
    line-height: 1.44;
    color: #333;
}

@include screensize($screen-sm) {
    .modal-dialog {
        max-width:600px;
        width:auto;
    }
}

@include screensize($screen-md) {
   .modal-title {
        font-size:28px;
    }

    .modal-header .close {
        font-size:36px;
    }

    .modal-dialog {
        max-width:600px;
        margin: 8% auto;
    }
}
