.content {
    width: 80%;
}

.main-content {
    background: #fff none repeat scroll 0 0;
    margin: 0;
    padding: 20px;
    width: 100%;
}
.page-content {
    background: #fff none repeat scroll 0 0;
    margin: 0;
    padding: 0 20px 20px;
    width: 100%;
}
.form-content {
    background: #fff none repeat scroll 0 0;
    margin: 0;
    padding: 20px 120px;
    width: 100%;
}

@media screen and (max-width: 62.1875em) {
    .form-content {
        padding: 20px 60px;
    }
    .nw-button-container {
        padding: 20px 60px;
    }
}
@media screen and (max-width: 52.5em) {
    .form-content {
        padding: 20px;
    }
}
@media screen and (max-width: 48.75em) {
    .content {
        width: 100%;
    }
}
@media screen and (max-width: 37.5em) {
    .main-content {
        padding: 10px;
    }
    .page-content {
        padding: 10px;
    }
    .form-content {
        padding: 10px;
    }
}
