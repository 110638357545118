
.claims-visual-selects {

    .radio-check-group-vert {
        position: relative;
        height: 75px;
        margin-bottom: 0;
    }
    .radio-check-group-vert label {
        font-size: 1.13em;
        position: absolute;
        top: 0;
        left: 0;
        height: 75px;
        width: 100%;
        margin: 0;
        padding: 16px 10px 0 92px;
        cursor: pointer;
        border-top: 1px solid rgb(224,225,221);
    }
    .radio-check-group-vert label:hover {
        background: rgb(238,239,237);
    }
    .radio-check-group-vert label:before {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        height: 75px;
        width: 82px;
        box-sizing: border-box;
    }
    .radio-check-group-vert label.no-top-border {
        border-top: none;
    }
    .radio-check-group-vert label em {
        display: block;
        font-family: 'proxima-nova', Arial, sans-serif;
        font-weight: 400;
        font-style: normal;
        color: rgb(28,87,165);
    }
    .radio-check-group-vert input[type="radio"] {
        margin-top: 20px;
        margin-left: 20px;
        opacity: 0;
    }
    .radio-check-group-vert input[type="radio"]:focus + label,
    .radio-check-group-vert label:focus { /* for focus state */
        background: rgb(238,239,237);
        outline: thin dotted;
        outline: 5px auto -webkit-focus-ring-color;
        outline-offset: -2px;
    }

    &.claims-visual-selects__file-claim {
        position: relative;
        max-width: 425px;
        margin-top: 10px;
        background: whitesmoke;
        border: 1px solid #e0e1dd;
        border-bottom: 3px solid #e0e1dd;
        border-radius: 8px;
        overflow: hidden;

        .claims-visual-selects__btn {
            position: relative;
            display: block;
            height: 75px;
            font-size: 1.13em;
            line-height: 75px;
            padding-left: 20px;
            text-align: left;
            text-decoration: none;
            &:focus {
                outline: thin dotted;
                outline: 5px auto -webkit-focus-ring-color;
                outline-offset: -2px;
            }

            &[class^="icon--"],
            &[class*="icon--"] {
                line-height: inherit;
                padding: 16px 40px 0 92px;
                &:before {
                    position: absolute;
                    top: 0;
                    left: 0;
                    font-family: 'icomoon';
                    height: 75px;
                    width: 82px;
                    text-align: center;
                }
            }

            .nw-arrow {
                position: absolute;
                right: 15px;
                top: 0;
                line-height: 75px;
                &:before {
                    border-top: 7px solid;
                    border-bottom-width: 0;
                    border-bottom-style: none;
                    border-left: 7px solid transparent;
                    border-right: 7px solid transparent;
                }
                &.nw-arrow--down:before {
                    border-bottom-width: 7px;
                    border-bottom-style: solid;
                    border-left: 7px solid transparent;
                    border-right: 7px solid transparent;
                    border-top: 7px solid transparent;
                }
            }

            + .collapse {
                -webkit-transition: height 600ms linear;
                transition: height 600ms linear;
            }
            + .collapsing {
                -webkit-transition: height 600ms linear;
                transition: height 600ms linear;
            }
        }

        //// Auto Damage Type
        .icon--multicarcollision:before {
            content: '';
            background: url(/assets/images/claims/icon--multicarcollision.svg) no-repeat center center rgb(28,87,165);
            background-size: 71%;
        }

        .icon--contact-fixed-object:before {
            content: '';
            background: url(/assets/images/claims/icon--contact-fixed-object.svg) no-repeat 11px 13px rgb(28,87,165);
            background-size: 71%;
        }

        .icon--animal:before {
            content: '';
            background: url(/assets/images/claims/icon--animal.svg) no-repeat center center rgb(28,87,165);
            background-size: 66%;
        }

        .icon--hail:before {
            content: '';
            background: url(/assets/images/claims/icon--hail.svg) no-repeat center center rgb(28,87,165);
            background-size: 54%;
        }

        .icon--flood:before {
            content: '';
            background: url(/assets/images/claims/icon--flood.svg) no-repeat center center rgb(28,87,165);
            background-size: 65%;
        }

        .icon--weather:before {
            content: '';
            background: url(/assets/images/claims/icon--weather.svg) no-repeat 15px center rgb(28,87,165);
            background-size: 61%;
        }

        .icon--vandalism:before {
            content: '';
            background: url(/assets/images/claims/icon--vandalism.svg) no-repeat 13px center rgb(28,87,165);
            background-size: 68%;
        }

        .icon--object-hit-vehicle:before {
            content: '';
            background: url(/assets/images/claims/icon--object-hit-vehicle.svg) no-repeat center center rgb(28,87,165);
            background-size: 59%;
        }

        .icon--accident:before {
            content: '';
            background: url(/assets/images/claims/icon--accident.svg) no-repeat center center rgb(28,87,165);
            background-size: 60%;
        }

        .icon--earth-movement:before {
            content: '';
            background: url(/assets/images/claims/icon--earth-movement.svg) no-repeat center center rgb(28,87,165);
            background-size: 45%;
        }

        .icon--contact-object:before {
            content: '';
            background: url(/assets/images/claims/icon--contact-object.svg) no-repeat center center rgb(28,87,165);
            background-size: 59%;
        }

        .icon--fire:before {
            content: '';
            background: url(/assets/images/claims/icon--fire.svg) no-repeat center center rgb(28,87,165);
            background-size: 59%;
        }

        .icon--partial-theft:before {
            content: '';
            background: url(/assets/images/claims/icon--partial-theft.svg) no-repeat center center rgb(28,87,165);
            background-size: 56%;
        }

        .icon--theft:before {
            content: '';
            background: url(/assets/images/claims/icon--theft.svg) no-repeat center center rgb(28,87,165);
            background-size: 58%;
        }

        .icon--roadside:before {
            content: '';
            background: url(/assets/images/claims/icon--roadside.svg) no-repeat center center rgb(28,87,165);
            background-size: 87%;
        }

        .icon--bicycle:before {
            content: '';
            background: url(/assets/images/claims/icon--bicycle.svg) no-repeat center center rgb(28,87,165);
            background-size: 78%;
        }

        .icon--collision-all-other:before {
            content: '';
            background: url(/assets/images/claims/icon--collision-all-other.svg) no-repeat center center rgb(28,87,165);
            background-size: 79%;
        }

        .icon--comprehensive-all-other:before {
            content: '';
            background: url(/assets/images/claims/icon--comprehensive-all-other.svg) no-repeat center center rgb(28,87,165);
            background-size: 70%;
        }

        .icon--pedestrian-accident:before {
            content: '';
            background: url(/assets/images/claims/icon--pedestrian-accident.svg) no-repeat center center rgb(28,87,165);
            background-size: 58%;
        }

        .icon--injury-around-vehicle:before {
            content: '';
            background: url(/assets/images/claims/icon--injury-around-vehicle.svg) no-repeat center center rgb(28,87,165);
            background-size: 69%;
        }

        //// Property Damage Type
        .icon--hail-property:before {
            content: '';
            background: url(/assets/images/claims/icon--hail-property.svg) no-repeat center center rgb(28,87,165);
            background-size: 54%;
        }

        .icon--wind-damage:before {
            content: '';
            background: url(/assets/images/claims/icon--wind-damage.svg) no-repeat center center rgb(28,87,165);
            background-size: 70%;
        }

        .icon--water-notweather:before {
            content: '';
            background: url(/assets/images/claims/icon--water-notweather.svg) no-repeat center center rgb(28,87,165);
            background-size: 50%;
        }

        .icon--water-weather:before {
            content: '';
            background: url(/assets/images/claims/icon--water-weather.svg) no-repeat center center rgb(28,87,165);
            background-size: 58%;
        }

        .icon--snow-weight:before {
            content: '';
            background: url(/assets/images/claims/icon--snow-weight.svg) no-repeat center center rgb(28,87,165);
            background-size: 56%;
        }

        .icon--freezing:before {
            content: '';
            background: url(/assets/images/claims/icon--freezing.svg) no-repeat center center rgb(28,87,165);
            background-size: 62%;
        }

        .icon--theft-home:before {
            content: '';
            background: url(/assets/images/claims/icon--theft-items-home.svg) no-repeat center center rgb(28,87,165);
            background-size: 72%;
        }

        .icon--theft-vehicle:before {
            content: '';
            background: url(/assets/images/claims/icon--theft-items-vehicle.svg) no-repeat center center rgb(28,87,165);
            background-size: 72%;
        }

        .icon--lost-item:before {
            content: '';
            background: url(/assets/images/claims/icon--lost-item.svg) no-repeat center center rgb(28,87,165);
            background-size: 58%;
        }

        .icon--id-theft:before {
            content: '';
            background: url(/assets/images/claims/icon--id-theft.svg) no-repeat center center rgb(28,87,165);
            background-size: 60%;
        }

        .icon--vandalism-mischief:before {
            content: '';
            background: url(/assets/images/claims/icon--vandalism-mischief.svg) no-repeat center center rgb(28,87,165);
            background-size: 55%;
        }

        .icon--lightning:before {
            content: '';
            background: url(/assets/images/claims/icon--lightning.svg) no-repeat center center rgb(28,87,165);
            background-size: 61%;
        }

        .icon--fire-property:before {
            content: '';
            background: url(/assets/images/claims/icon--fire-property.svg) no-repeat center center rgb(28,87,165);
            background-size: 64%;
        }

        .icon--smoke:before {
            content: '';
            background: url(/assets/images/claims/icon--smoke.svg) no-repeat center center rgb(28,87,165);
            background-size: 60%;
        }

        .icon--damage-vwa:before {
            content: '';
            background: url(/assets/images/claims/icon--damage-vwa.svg) no-repeat center center rgb(28,87,165);
            background-size: 60%;
        }

        .icon--equipment:before {
            content: '';
            background: url(/assets/images/claims/icon--equipment.svg) no-repeat center center rgb(28,87,165);
            background-size: 50%;
        }

        .icon--earthquake:before {
            content: '';
            background: url(/assets/images/claims/icon--earthquake.svg) no-repeat center center rgb(28,87,165);
            background-size: 50%;
        }

        .icon--other-phys-damage:before {
            content: '';
            background: url(/assets/images/claims/icon--other-phys-damage.svg) no-repeat center center rgb(28,87,165);
            background-size: 64%;
        }
    }
}

.has-error {
    .claims-visual-selects {
        &.claims-visual-selects__file-claim {
            border-color: rgb(169, 68, 66);
        }
    }
}
