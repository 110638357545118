.main-footer {
    background: #e7e7e7 none repeat scroll 0 0;
    color: #333333;
    font-size: 0.85em;
    padding: 20px;
}
.main-footer__contact {
    float: left;
    margin-bottom: 10px;
    width: 65%;
}
.main-footer__logo-link {
    border-radius: 8px;
    display: inline-block;
    margin: 0 10px 0 0;
    padding: 10px;
}
.main-footer__logo-link:hover {
    background-color: #cccccc;
}
.main-footer__logo {
    height: 41px;
    width: 184px;
}
.main-footer__disclaimer {
    color: #666;
    font-size: 0.85em;
    padding: 0 20px 20px;
}
hr.main-footer__separator {
    border-top: 1px solid #ccc;
    clear: both;
}
.main-footer__nav-container {
    float: left;
    font-size: 1.2em;
    margin: 20px 20px 0 0;
}
.main-footer__nav-links {
    list-style: outside none none;
    margin: 0;
    padding: 0;
}
.main-footer__nav-links li {
    border-left: 1px dotted #acacac;
    display: inline-block;
    margin-right: 12px;
    padding-left: 12px;
}
.main-footer__nav-links li:first-child {
    border-left: medium none;
    padding-left: 0;
}
.main-footer__logos {
    float: right;
    margin-bottom: 20px;
}
.main-footer__logos a {
    border-radius: 8px;
    display: inline-block;
    margin-right: 10px;
    padding: 10px;
}
.main-footer__logos a:last-child {
    margin-right: 0;
}
.icon-footer {
    background-repeat: no-repeat;
    display: block;
    text-indent: -9999em;
}
.main-footer__logos a:hover {
    background-color: #cccccc;
}
.main-footer__list {
    list-style: outside none none;
    margin: 0;
    padding: 0;
}
.main-footer__list li {
    border-left: 1px solid #666666;
    float: left;
    margin-right: 12px;
    padding-left: 12px;
}
.main-footer__list li:first-child {
    border-left: medium none;
    padding-left: 0;
}
.main-footer__links {
    margin-top: 20px;
}
.main-footer__phone {
    display: inline-block;
    margin-top: 24px;
    font-size: 1.2em;
    vertical-align: top;
}

@media screen and (max-width: 62.1875em) {
    .main-footer__logo-link {
        padding-right: 10px;
    }
    .main-footer__contact {
        text-align: center;
        width: 100%;
    }
    .main-footer__nav-container {
        margin: 0 auto 20px;
        width: 100%;
    }
    .main-footer__nav-links {
        text-align: center;
    }
    .main-footer__nav-links li {
        float: none;
    }
    .main-footer__logos {
        text-align: center;
        width: 100%;
    }
    .main-footer__legal {
        text-align: justify;
    }
    .main-footer__list {
        text-align: center;
    }
    .main-footer__list li {
        display: inline-block;
        float: none;
    }
}
@media screen and (max-width: 35em) {
    .main-footer {
        padding: 10px;
    }
    .main-footer__links {
        margin-top: 10px;
    }
    .main-footer__phone {
        display: block;
        width:100%;
    }
}
@media screen and (max-width: 25em) {
    .main-footer {
        padding: 10px 0;
    }
    .main-footer__logos a {
        margin-right: 0;
    }
    .main-footer__legal {
        padding: 0 10px;
    }
}
