
.claims-track-claim-repair-shop-title {
    font-size: 1.25em;
    font-weight: bold;
    line-height: 1.25em;
    display: block;
    margin-bottom: 2px;
    &:before {
        position: absolute;
        top: 15px;
        left: 15px;
        display: block;
        height: 50px;
        width: 50px;
        font-family: 'icomoon';
        font-size: 2.1em;
        line-height: 50px;
        color: rgb(255,255,255);
        background: #5e9bad;
        border-radius: 999px;
        padding-top: 1px;
        box-sizing: border-box;
        -moz-box-sizing: border-box;

        @media (max-width: $small) {
            width: 35px;
            height: 35px;
            font-size: 1.4em;
            line-height: 35px;
        }
    }
}

// Override
.claims-track-claim-content__text-box {
    &.icon--repair, &.icon--estimate-appointment{
        dl dd {
            padding-bottom: 0;
            &:last-of-type {
                margin-top: 15px;
            }
        }
    }
}

// Override - for property dashboard
.claims-track-claim-content__text-box {
    &.icon--property-repair {
        dl dt {
            padding-bottom: 0;
            ul {
                margin: 0 0;
                padding-left:0;
                li{
                    margin-bottom:5px;
                    padding:0;
                }
            }
        }
        dl dd {
            padding-bottom: 0;
        }
    }
}