//  _____ _ _ _ ____  _____ 
// |   | | | | |    \|   __|
// | | | | | | |  |  |__   |
// |_|___|_____|____/|_____|
//
// NATIONWIDE DESIGN SYSTEM
// --------------------------------------------------
// Bootstrap Forms and Inputs
//




//
// Groups and Containers
// ---------------
//

.form-group {
    margin-bottom:32px;
}

// When group is a row, maintain the spacing when stacked vs columns
.row.form-group { margin-bottom:0; }
.row.form-group > div { margin-bottom:32px; }



//
// Labels and Text
// ---------------
//


// Help text
.help-block { 
    color: #757575;
    font-size: $font-size-small;
    margin-bottom:0;
}

.form-group label + .help-block { // Scoot help text up when next to a label
    margin-top:-3px;      
}

// Legend gets normalized to look like a label. Use headers to label fieldsets as setions.
legend { 
	font-weight:700;
	font-size:$font-size-base;
	border:none;
	margin:0;
}





//
// Inputs
// ---------------
//


// Input place holder styles. 
// Each prefix needs its own rule otherwise it won't work. Can't group.
.form-control::-webkit-input-placeholder {
    font-style:italic;
}

.form-control::-moz-placeholder {
    font-style:italic;
}

.form-control:-ms-input-placeholder {  
    font-style:italic;
}



.form-control[disabled], .form-control[readonly], fieldset[disabled] .form-control {
    background: #fff;
    border-color: #d8d8d8;
}
