
.progress {
    height: 25px;
    margin-bottom: 30px;
}
.progress-bar {
    background: rgb(94, 156, 174);
    -webkit-transition: width 1s ease;
    -o-transition: width 1s ease;
    transition: width 1s ease;
}
.bolt-progress-bar{
    padding-bottom: 25px;
}
