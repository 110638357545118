//  _____ _ _ _ ____  _____ 
// |   | | | | |    \|   __|
// | | | | | | |  |  |__   |
// |_|___|_____|____/|_____|
//
// NATIONWIDE DESIGN SYSTEM
// --------------------------------------------------
// Bootstrap Buttons

.btn-lg {
  @include button-size(8px, $padding-large-horizontal, 22px, $line-height-large, $btn-border-radius-large);
}

.btn.disabled, 
.btn[disabled], 
fieldset[disabled] .btn
    {
        @include opacity(.35);
    }