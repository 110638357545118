.before-sprite:before {
    background-image: url("../images/icon_sprite.png");
    background-repeat: no-repeat;
}
.social-sprite {
    background-image: url("../images/social-sprite.png");
    background-repeat: no-repeat;
}
.social-sprite {
    display: inline-block;
    height: 34px;
    margin-right: 10px;
    overflow: hidden;
    text-indent: 9999em;
    width: 34px;
}
.social-sprite:last-child {
    margin-right: 0;
}
.sprite_dollar:before, .sprite_autoPolicy:before, .sprite_homePolicy:before{
    content:"";
    display:block;
    float:left;
    left:0;
    position:absolute;
    top:0
}
.sprite_dollar:before{
    background-position:3px -286px;
    height:30px;
    width:25px
}