
[class^='claims-track-claim-uploaded-file-link--'],
[class*='claims-track-claim-uploaded-file-link--'] {
    position: relative;
    display: block;
    min-height: 82px;
    width: 100%;
    max-width: 900px;
    margin-bottom: 5px;
    font-family: 'proxima-nova', Arial, sans-serif;
    font-size: 1.125em;
    line-height: 18px;
    color: rgb(51, 51, 51);
    text-decoration: none;
    background: rgb(255, 255, 255);
    border: 1px solid #e1e1e1;
    border-bottom: 2px solid #e1e1e1;
    &.link-arrow-orange:after {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 10px;
        height: 0;
        margin: auto 0;
    }

    em {
        font-weight: bold;
        font-style: normal;
    }
}

.claims-track-claim-uploaded-file-link--photo  {
    padding: 15px 26px 15px 82px;
//    img {
//        position: absolute;
//        top: 0;
//        left: 0;
//        outline: none;
//        border: none;
//        margin: 0;
//    }

    @media (max-width: $small) {
//        padding: 10px 26px 15px 82px;
        padding: 10px 26px 15px 59px;
    }
}

.claims-track-claim-uploaded-file-link--photo:before {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 16px;
    content: '';
    height: 50px;
    width: 50px;
    margin: auto 0;
    display: block;
    background: url(/assets/images/claims/icon--claims-upload-photo.svg) no-repeat center center $blue-icon;
    background-size: 56%;
    border-radius: 999px;

    @media (max-width: $small) {
        left: 12px;
        height: 35px;
        width: 35px;
    }
}

.claims-track-claim-uploaded-file-link--doc {
    padding: 15px 26px 15px 82px;

    @media (max-width: $small) {
        padding: 10px 26px 15px 59px;
    }
}

.claims-track-claim-uploaded-file-link--doc:before {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 16px;
    content: '';
    height: 50px;
    width: 50px;
    margin: auto 0;
    display: block;
    background: url(/assets/images/claims/icon--claims-upload-doc.svg) no-repeat center center $blue-icon;
    background-size: 44%;
    border-radius: 999px;

    @media (max-width: $small) {
        left: 12px;
        height: 35px;
        width: 35px;
    }
}

