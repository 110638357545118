
.claims-file-car-damage {
    margin-top: 10px;
    .radio-check-group-vert {
        position: absolute;
        margin: 0;
        padding: 0;
    }
    .radio-check-group-vert label {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        margin: 0;
        padding: 0;
        cursor: pointer;
        overflow: hidden;
        text-indent: -9999px;
    }
    .radio-check-group-vert input[type="checkbox"] {
        margin: 0;
        opacity: 0;
    }

    // Passenger Side
    .claims-file-car-damage__passenger-wrap {
        position: relative;
        display: inline-block;
        max-width: 420px;
        width: 100%;
        margin-right: 3%;

        @media (max-width: 60.63em) {
            max-width: 48%;
        }
        @media (max-width: 48.13em) {
            display: block;
            max-width: 500px;
            margin: 0 auto 40px;
        }

        #car-passenger-canvas {
            display: none;
        }

        #car-passenger {
            position: relative;
            z-index: 1;
            max-width: 500px;
            width: 100%;
        }

        #car-passenger-check { // fieldset
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;

            .claims-file-car-damage__passenger {
                position: relative;
                max-width: 500px;
                width: 100%;
                background-size: 100%;

                .claims-file-car-damage__passenger--rear {
                    bottom: 0;
                    left: 1px;
                    height: 100%;
                    width: 12.7%;

                    input[type="checkbox"]:focus + label {
                        background: url(/assets/images/claims/pass-back-selected.png) 0 59% no-repeat transparent;
                        background-size: 100%;
                        opacity: 0.5;
                    }
                    &.selected {
                        label,
                        input[type="checkbox"]:focus + label {
                            background: url(/assets/images/claims/pass-back-selected.png) 0 59% no-repeat transparent;
                            background-size: 100%;
                        }
                        input[type="checkbox"]:focus + label {
                            opacity: 0.75;
                        }
                    }
                }

                .claims-file-car-damage__passenger--rear-door {
                    top: 0;
                    left: 6%;
                    height: 100%;
                    width: 15.3%;

                    input[type="checkbox"]:focus + label {
                        background: url(/assets/images/claims/pass-bdoor-selected.png) 0 17% no-repeat transparent;
                        background-size: 100%;
                        opacity: 0.5;
                    }
                    &.selected {
                        label,
                        input[type="checkbox"]:focus + label {
                            background: url(/assets/images/claims/pass-bdoor-selected.png) 0 17% no-repeat transparent;
                            background-size: 100%;
                        }
                        input[type="checkbox"]:focus + label {
                            opacity: 0.75;
                        }
                    }
                }

                .claims-file-car-damage__passenger--front-door {
                    top: 1px;
                    left: 15%;
                    height: 100%;
                    width: 16%;

                    input[type="checkbox"]:focus + label {
                        background: url(/assets/images/claims/pass-fdoor-selected.png) 0 18% no-repeat transparent;
                        background-size: 100%;
                        opacity: 0.5;
                    }
                    &.selected {
                        label,
                        input[type="checkbox"]:focus + label {
                            background: url(/assets/images/claims/pass-fdoor-selected.png) 0 18% no-repeat transparent;
                            background-size: 100%;
                        }
                        input[type="checkbox"]:focus + label {
                            opacity: 0.75;
                        }
                    }
                }

                .claims-file-car-damage__passenger--front {
                    top: 0;
                    left: 28.4%;
                    height: 100%;
                    width: 34%;

                    input[type="checkbox"]:focus + label {
                        background: url(/assets/images/claims/pass-front-selected.png) 0 100% no-repeat transparent;
                        background-size: 100%;
                        opacity: 0.5;
                    }
                    &.selected {
                        label,
                        input[type="checkbox"]:focus + label {
                            background: url(/assets/images/claims/pass-front-selected.png) 0 100% no-repeat transparent;
                            background-size: 100%;
                        }
                        input[type="checkbox"]:focus + label {
                            opacity: 0.75;
                        }
                    }
                }

                .claims-file-car-damage__passenger--front-bumper-pass {
                    top: 0;
                    left: 40.5%;
                    height: 100%;
                    width: 26%;

                    input[type="checkbox"]:focus + label {
                        background: url(/assets/images/claims/front-bump-pass-selected.png) 0 83.5% no-repeat transparent;
                        background-size: 100%;
                        opacity: 0.5;
                    }
                    &.selected {
                        label,
                        input[type="checkbox"]:focus + label {
                            background: url(/assets/images/claims/front-bump-pass-selected.png) 0 83.5% no-repeat transparent;
                            background-size: 100%;
                        }
                        input[type="checkbox"]:focus + label {
                            opacity: 0.75;
                        }
                    }
                }

                .claims-file-car-damage__passenger--front-bumper-mid {
                    top: 0;
                    left: 62%;
                    height: 100%;
                    width: 31%;

                    input[type="checkbox"]:focus + label {
                        background: url(/assets/images/claims/front-bump-mid-selected.png) 0 80% no-repeat transparent;
                        background-size: 100%;
                        opacity: 0.5;
                    }
                    &.selected {
                        label,
                        input[type="checkbox"]:focus + label {
                            background: url(/assets/images/claims/front-bump-mid-selected.png) 0 80% no-repeat transparent;
                            background-size: 100%;
                        }
                        input[type="checkbox"]:focus + label {
                            opacity: 0.75;
                        }
                    }
                }

                .claims-file-car-damage__passenger--front-bumper-driver {
                    top: 0;
                    right: 0;
                    height: 100%;
                    width: 12.6%;

                    input[type="checkbox"]:focus + label {
                        background: url(/assets/images/claims/front-bump-driver-selected.png) 0 73% no-repeat transparent;
                        background-size: 100%;
                        opacity: 0.5;
                    }
                    &.selected {
                        label,
                        input[type="checkbox"]:focus + label {
                            background: url(/assets/images/claims/front-bump-driver-selected.png) 0 73% no-repeat transparent;
                            background-size: 100%;
                        }
                        input[type="checkbox"]:focus + label {
                            opacity: 0.75;
                        }
                    }
                }

                .claims-file-car-damage__passenger--hood {
                    top: 0;
                    right: 9.5%;
                    height: 100%;
                    width: 56%;

                    input[type="checkbox"]:focus + label {
                        background: url(/assets/images/claims/hood-selected.png) 0 33% no-repeat transparent;
                        background-size: 100%;
                        opacity: 0.5;
                    }
                    &.selected {
                        label,
                        input[type="checkbox"]:focus + label {
                            background: url(/assets/images/claims/hood-selected.png) 0 33% no-repeat transparent;
                            background-size: 100%;
                        }
                        input[type="checkbox"]:focus + label {
                            opacity: 0.75;
                        }
                    }
                }

                .claims-file-car-damage__passenger--roof {
                    top: 0;
                    left: 21%;
                    height: 100%;
                    width: 56%;

                    input[type="checkbox"]:focus + label {
                        background: url(/assets/images/claims/roof-selected.png) 0 0.5% no-repeat transparent;
                        background-size: 100%;
                        opacity: 0.5;
                    }
                    &.selected {
                        label,
                        input[type="checkbox"]:focus + label {
                            background: url(/assets/images/claims/roof-selected.png) 0 0.5% no-repeat transparent;
                            background-size: 100%;
                        }
                        input[type="checkbox"]:focus + label {
                            opacity: 0.75;
                        }
                    }
                }

            }
        }
    }

    // Drivers Side
    .claims-file-car-damage__driver-wrap {
        position: relative;
        display: inline-block;
        max-width: 420px;
        width: 100%;

        @media (max-width: 60.63em) {
            max-width: 48%;
        }
        @media (max-width: 48.13em) {
            display: block;
            max-width: 500px;
            margin: 0 auto;
        }

        #car-driver-canvas {
            display: none;
        }

        #car-driver {
            position: relative;
            z-index: 1;
            max-width: 500px;
            width: 100%;
        }

        #car-driver-check { // fieldset
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;

            .claims-file-car-damage__driver {
                position: relative;
                max-width: 500px;
                width: 100%;
                background-size: 100%;

                .claims-file-car-damage__driver--front {
                    bottom: 0;
                    left: 0px;
                    height: 100%;
                    width: 11.5%;

                    input[type="checkbox"]:focus + label {
                        background: url(/assets/images/claims/driver-front-selected.png) 0 65% no-repeat transparent;
                        background-size: 100%;
                        opacity: 0.5;
                    }
                    &.selected {
                        label,
                        input[type="checkbox"]:focus + label {
                            background: url(/assets/images/claims/driver-front-selected.png) 0 65% no-repeat transparent;
                            background-size: 100%;
                        }
                        input[type="checkbox"]:focus + label {
                            opacity: 0.75;
                        }
                    }
                }

                .claims-file-car-damage__driver--front-door {
                    top: 0;
                    left: 8.5%;
                    height: 100%;
                    width: 20.4%;

                    input[type="checkbox"]:focus + label {
                        background: url(/assets/images/claims/driver-fdoor-selected.png) 0 18% no-repeat transparent;
                        background-size: 100%;
                        opacity: 0.5;
                    }
                    &.selected {
                        label,
                        input[type="checkbox"]:focus + label {
                            background: url(/assets/images/claims/driver-fdoor-selected.png) 0 18% no-repeat transparent;
                            background-size: 100%;
                        }
                        input[type="checkbox"]:focus + label {
                            opacity: 0.75;
                        }
                    }
                }

                .claims-file-car-damage__driver--rear-door {
                    top: 0;
                    left: 20.3%;
                    height: 100%;
                    width: 19.7%;

                    input[type="checkbox"]:focus + label {
                        background: url(/assets/images/claims/driver-bdoor-selected.png) 0 22% no-repeat transparent;
                        background-size: 100%;
                        opacity: 0.5;
                    }
                    &.selected {
                        label,
                        input[type="checkbox"]:focus + label {
                            background: url(/assets/images/claims/driver-bdoor-selected.png) 0 22% no-repeat transparent;
                            background-size: 100%;
                        }
                        input[type="checkbox"]:focus + label {
                            opacity: 0.75;
                        }
                    }
                }

                .claims-file-car-damage__driver--rear {
                    bottom: 0;
                    left: 29.1%;
                    height: 100%;
                    width: 26.4%;

                    input[type="checkbox"]:focus + label {
                        background: url(/assets/images/claims/driver-back-selected.png) 0 100% no-repeat transparent;
                        background-size: 100%;
                        opacity: 0.5;
                    }
                    &.selected {
                        label,
                        input[type="checkbox"]:focus + label {
                            background: url(/assets/images/claims/driver-back-selected.png) 0 100% no-repeat transparent;
                            background-size: 100%;
                        }
                        input[type="checkbox"]:focus + label {
                            opacity: 0.75;
                        }
                    }
                }

                .claims-file-car-damage__driver--rear-bumper-driver {
                    bottom: 0;
                    right: 34.8%;
                    height: 100%;
                    width: 25%;

                    input[type="checkbox"]:focus + label {
                        background: url(/assets/images/claims/rear-bump-driver-selected.png) 0 74% no-repeat transparent;
                        background-size: 100%;
                        opacity: 0.5;
                    }
                    &.selected {
                        label,
                        input[type="checkbox"]:focus + label {
                            background: url(/assets/images/claims/rear-bump-driver-selected.png) 0 74% no-repeat transparent;
                            background-size: 100%;
                        }
                        input[type="checkbox"]:focus + label {
                            opacity: 0.75;
                        }
                    }
                }

                .claims-file-car-damage__driver--rear-bumper-mid {
                    bottom: 0;
                    left: 60.7%;
                    height: 100%;
                    width: 35.7%;

                    input[type="checkbox"]:focus + label {
                        background: url(/assets/images/claims/rear-bump-mid-selected.png) 0 72.7% no-repeat transparent;
                        background-size: 100%;
                        opacity: 0.5;
                    }
                    &.selected {
                        label,
                        input[type="checkbox"]:focus + label {
                            background: url(/assets/images/claims/rear-bump-mid-selected.png) 0 72.7% no-repeat transparent;
                            background-size: 100%;
                        }
                        input[type="checkbox"]:focus + label {
                            opacity: 0.75;
                        }
                    }
                }

                .claims-file-car-damage__driver--rear-bumper-pass {
                    bottom: 0;
                    right: 0;
                    height: 100%;
                    width: 4.8%;

                    input[type="checkbox"]:focus + label {
                        background: url(/assets/images/claims/rear-bump-pass-selected.png) 0 65% no-repeat transparent;
                        background-size: 100%;
                        opacity: 0.5;
                    }
                    &.selected {
                        label,
                        input[type="checkbox"]:focus + label {
                            background: url(/assets/images/claims/rear-bump-pass-selected.png) 0 65% no-repeat transparent;
                            background-size: 100%;
                        }
                        input[type="checkbox"]:focus + label {
                            opacity: 0.75;
                        }
                    }
                }

                .claims-file-car-damage__driver--trunk {
                    top: 0;
                    right: 3%;
                    height: 100%;
                    width: 46%;

                    input[type="checkbox"]:focus + label {
                        background: url(/assets/images/claims/trunk-selected.png) 0 23.6% no-repeat transparent;
                        background-size: 100%;
                        opacity: 0.5;
                    }
                    &.selected {
                        label,
                        input[type="checkbox"]:focus + label {
                            background: url(/assets/images/claims/trunk-selected.png) 0 23.6% no-repeat transparent;
                            background-size: 100%;
                        }
                        input[type="checkbox"]:focus + label {
                            opacity: 0.75;
                        }
                    }
                }

                .claims-file-car-damage__driver--roof {
                    top: 0;
                    right: 11.2%;
                    height: 100%;
                    width: 60.5%;

                    input[type="checkbox"]:focus + label {
                        background: url(/assets/images/claims/driver-roof-selected.png) 0 0.5% no-repeat transparent;
                        background-size: 100%;
                        opacity: 0.5;
                    }
                    &.selected {
                        label,
                        input[type="checkbox"]:focus + label {
                            background: url(/assets/images/claims/driver-roof-selected.png) 0 0.5% no-repeat transparent;
                            background-size: 100%;
                        }
                        input[type="checkbox"]:focus + label {
                            opacity: 0.75;
                        }
                    }
                }

            }
        }
    }
}
